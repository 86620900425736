import React, { Fragment } from "react";
import { Container, Col, Row } from 'reactstrap';
import { Image } from '../../AbstractElements';

const InsightChart = (props) => {

    return (
        <Fragment>
            <Container fluid={true}>
                <Row className="pt-3">
                    <Col sm="6">
                        <h3>{props.title}</h3>
                    </Col>
                    <Col sm="6">
                        {
                            props.period && <div className="text-right">Period: {props.period}</div>
                        }
                        
                    </Col>
                    <Col sm="12 mt-2 mb-3">
                        <Image attrImage={{ style: { width: '100%' }, className: 'img-fluid for-light', src: `${require(`../../assets/images/dashboards/${props.filename}`)}` }} />
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};
export default InsightChart;