import React, { Fragment, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { H3 } from "../../AbstractElements";
import { X } from "react-feather";
import {
    Row,
    Card,
    Col,
    Form,
    FormGroup,
    Label,
    CardBody,
    Button
} from "reactstrap";
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const StoreLocation = ({ value, setOpenModal, data }) => {
    const [open, setOpen] = useState(value);
    const action = 'Add';
    const onClose = () => {
        setOpen(false);
        setOpenModal(false)
    }

    const defaultProps = {
        center: {
            lat: 39.876019,
            lng: -117.224121
        },
        zoom: 11
    };


    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size='lg'>
                <ModalHeader style={{ display: 'flex' }}>
                    <H3 attrH3={{ className: 'modal-title-color' }}>Store Location<X className="x-button" onClick={onClose} /></H3>
                </ModalHeader>
                <ModalBody>
                    <Fragment>
                        <Row>
                            <Col lg='12'>
                                <Card className={`${action === 'Add' && 'mb-0'} ${action === 'Edit' && 'border-bottom-light '}`}>
                                    <CardBody className={action === 'Add' && 'p-0 mb-0'}>
                                    {`${data.address}, ${data?.city}, ${data?.state}, ${data?.country}`}

                                        <div style={{ height: '60vh', width: '100%', marginTop: '1rem' }}>
                                            <GoogleMapReact
                                                bootstrapURLKeys={{ key: "" }}
                                                defaultCenter={defaultProps.center}
                                                defaultZoom={defaultProps.zoom}
                                            >
                                                <AnyReactComponent
                                                    lat={59.955413}
                                                    lng={30.337844}
                                                    text="My Marker"
                                                />
                                            </GoogleMapReact>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Fragment>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default StoreLocation;