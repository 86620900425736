import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import { Edit, Trash } from 'react-feather';
import AddAsoUser from './AddUser';
import AgGridTable from '../../Common/Component/AgGridTable';
import { useRef } from 'react';
import { API_URL } from '../../Common/Config/Config';
import fetchWrapper from '../../Common/api/fetchWrapper';
import DeleteUser from './DeleteUser';
import { toast } from 'react-toastify';

const Userlist = () => {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false)
    const [orgData, setOrgData] = useState();
    const gridRef = useRef(null);
    const [action, setAction] = useState('Add');
    const [deleteOpen, setDeleteopen] = useState(false)
    const [dataId, setDataId] = useState();
    const [isDeleted, setIsDeleted] = useState(false)

    const loadData = async () => {
        gridRef?.current?.api?.showLoadingOverlay();
        const options = {
            method: 'GET'
        }
        await fetchWrapper(`${API_URL}/user`, options)
            .then((res) => {
                console.log(res)
                setData(res.json)
                gridRef?.current?.api?.hideOverlay();
            })
            .catch((err) => {
                setLoading(false)
                gridRef?.current?.api?.hideOverlay();
                console.error(err)
            })
    }

    const loadOrgData = async (item) => {
        setOrgData(item)
        setAction('Edit')
        setOpen(true);
    }

    const handleStatus = async (data) => {
        let updateStatus = data?.active === 'TRUE' ? 'FALSE' : 'TRUE';
        const options = {
            method: 'PUT'
        }
        await fetchWrapper(`${API_URL}/user/status/${data?.userID}/${updateStatus}`, options)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    if (res?.json?.active == 'FALSE') {
                        toast.error('User has been disabled!')
                    } else {
                        toast.success('User has been enabled!')
                    }
                    loadData()
                }
            })
            .catch(err => console.log(err))
    }

    const handleDelete = (value) => {
        setDeleteopen(true)
        setDataId(value)
    }

    const tableColumns = [
        {
            headerName: 'Full Name',
            filter: true,
            sortable: true,
            suppressMovable: true,
            valueGetter: (params) => {
                return params.data.firstName + ' ' + params.data.lastName
            }, 
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }, 
            flex: 2
        },
        {
            headerName: 'User Name',
            field: 'userName',
            filter: true,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            flex: 2
        },
        {
            headerName: 'Email Id',
            field: 'email',
            filter: true,
            sortable: true,
            flex: 2,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Phone',
            field: 'mobileNumber',
            filter: true,
            sortable: true,
            flex: 2,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Status',
            filter: false,
            width: 100,
            sortable: true,
            suppressMovable: true,
            cellRenderer: (params) => {
                return (
                    <div className='mr-21'>
                    <label className="switch">
                        <input type="checkbox" id="togBtn" defaultChecked={params.data.active === 'TRUE'? true : false}
                            onChange={() => handleStatus(params.data)} />
                        <div className="slider round">
                            <span className="on"></span>
                            <span className="off"></span>
                        </div>
                    </label>
                </div>
                )
            }
        },
        {
            headerName: 'Edit',
            width: 90,
            cellRenderer: params => {
                return (
                    <Edit className={`edit-icon`} onClick={() => loadOrgData(params.data)} />
                )
            },
            suppressMovable: true,
        },
        {
            headerName: 'Delete',
            width: 90,
            cellRenderer: params => {
                return (
                    <Trash className={`edit-icon`} onClick={()=>handleDelete(params.data.userID)}/>
                )
            },
            suppressMovable: true,
        },
    ]

    const handleClick = () => {
        setOrgData(undefined);
        setAction('Add')
        setOpen(true);
    }

    const onGridReady = useCallback(async (params) => {
        loadData();
    })

    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <div className='d-flex'>
                            <Breadcrumbs mainTitle="Users" parent="User Management" title="List" />
                            <div className="text-right  action_button">
                                <div className="link action_button" onClick={handleClick}>
                                    <i style={{ fontSize: '25px', color: "#2b7704" }} className="icofont  icofont-plus-circle" ></i>
                                    &nbsp;&nbsp;{"Add User"}</div>
                            </div>
                        </div>
                        <Card className="border-bottom-light  newtable">
                            <CardBody className='data-tables'>
                            <Row>
                                    <Col sm='12'>
                                        <div className="">
                                            <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                                <AgGridTable
                                                    columnData={tableColumns}
                                                    tableData={data}
                                                    agRef={gridRef}
                                                    onGridReady={onGridReady}
                                                />
                                            </div>
                                        </div>
                                    </Col>

                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    {open && <AddAsoUser value={open} setOpenModal={(e) => e && loadData()} orgData={orgData} action={action}/>}
                    {deleteOpen && <DeleteUser value={deleteOpen} setOpenModal={setDeleteopen} dataId={dataId} setDelete={setIsDeleted}/>}
                </Row>
            </Container>
        </Fragment>
    );

};

export default Userlist;