import React, { Fragment, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Col, Container, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, Image } from '../../../AbstractElements';
import { API_URL } from '../../Config/Config';
import fetchWrapper from '../../api/fetchWrapper';
import { useForm } from 'react-hook-form';
import { Eye, EyeOff } from 'react-feather';
import { useNavigate } from 'react-router';
import ButtonLoader from '../ButtonLoader';

const ChangePwd = () => {
    const [loading, setLoading] = useState(false)
    const { register, formState: { errors }, getValues, trigger, handleSubmit } = useForm()
    const [toggleOldPassword, setToggleOldPassword] = useState(false);
    const [toggleConfirmPassword, setToggleConfirmPassword] = useState(false);
    const [toggleNewPassword, setToggleNewPassword] = useState(false);
    const navigate = useNavigate();

    const onSubmit = async (data) => {
        setLoading(true)
        const bodyData = {
            oldPassword: data.oldPassword,
            email: sessionStorage.getItem('email'),
            newPassword: data.newPassword
        }
        const options = {
            method: 'POST',
            body: bodyData,
        }
        await fetchWrapper(`https://wave-connect-api-new.azurewebsites.net/changePassword`, options)
            .then((res) => {
                const data = res.json;
                toast.success(data);
                setLoading(false);
            }, (error) => {
                console.log(error);
                if (error.status === 403) {
                    toast.error(error.json.ErrorMessage);
                } else if (error.status === 200) {
                    toast.success('Password reset succesfully.');
                    setTimeout(() => {
                    navigate(`${process.env.PUBLIC_URL}/login`)
                    }, 1000)
                }
                setLoading(false);
            });
    }

    return (
        <Fragment>
            <ToastContainer />
            <section>
                <Container className="p-0" fluid={true}>
                    <Row>
                        <Col xl='12'>
                            <div className="login-card">
                                <div className='login-main'>
                                    <div className='logo'>
                                        <Image attrImage={{ className: 'img-fluid for-light', src: `${require('../../../assets/images/logo/app-logo@3x.png')}` }} />
                                        {/* <H6>Enter your email address and we'll send you a link to reset your password</H6> */}
                                    </div>
                                    <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                                        <FormGroup className='form-group'>
                                            <Label className="col-form-label">Old Password <span className='required'>*</span></Label>
                                            <input
                                                className="form-control"
                                                // type="text"
                                                name="oldPassword"
                                                type={toggleOldPassword ? 'text' : 'password'}
                                                required={true}
                                                {...register('oldPassword', { required: true })}
                                            />
                                            <div className="show-hide reset-password" onClick={() => setToggleOldPassword(!toggleOldPassword)}>
                                                <span>{toggleOldPassword ? <Eye className='show-icon' /> : <EyeOff className='show-icon' />}</span>
                                            </div>
                                            <span className='text-danger'>{errors.oldPassword && errors.oldPassword.type === 'required' && 'Please Enter Old Password'}</span>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>New Password <span className='required'>*</span></Label>
                                            <input
                                                className="form-control mb-1"
                                                name="newPassword"
                                                onCopy={(e) => e.preventDefault()}
                                                onPaste={(e) => e.preventDefault()}
                                                type={toggleNewPassword ? 'text' : 'password'}
                                                {...register('newPassword', { required: true, pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/g })}
                                            />
                                            <div className="show-hide reset-password" onClick={() => setToggleNewPassword(!toggleNewPassword)}>
                                                <span>{toggleNewPassword ? <Eye className='show-icon' /> : <EyeOff className='show-icon' />}</span>
                                            </div>
                                            <span className="text-danger">{errors.newPassword && errors.newPassword.type === 'required' && 'Enter New Password'}</span>
                                            <span className="text-danger">{errors.newPassword && errors.newPassword.type === 'pattern' && 'Your password contains 8 characters, One Upper case, One Special characters, One Numeric Characters'}</span>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Confirm New Password <span className='required'>*</span></Label>
                                            <input
                                                className="form-control mb-1"
                                                name="confirmPassword"
                                                onCopy={(e) => e.preventDefault()}
                                                onPaste={(e) => e.preventDefault()}
                                                type={toggleConfirmPassword ? 'text' : 'password'}
                                                {...register('confirmPassword', { required: true, validate: (value) => getValues('newPassword') === value })}
                                            />
                                            <div className="show-hide reset-password" onClick={() => setToggleConfirmPassword(!toggleConfirmPassword)}>
                                                <span>{toggleConfirmPassword ? <Eye className='show-icon' /> : <EyeOff className='show-icon' />}</span>
                                            </div>
                                            <span className="text-danger">{errors.confirmPassword && errors.confirmPassword.type === 'required' && 'Enter Confirm Password'}</span>
                                            <span className="text-danger">{errors.confirmPassword && errors.confirmPassword.type === 'validate' && 'New password and Confirm password must be same'}</span>
                                        </FormGroup>
                                        <FormGroup className="form-group mb-0">
                                            <div className='text-center mt-3'>
                                                {
                                                    loading ?
                                                        <ButtonLoader color='primary' className={'btn-block'} loaderText={'Reseting...'} /> :
                                                        <Btn attrBtn={{ color: 'primary', className: 'btn-block', type: 'submit' }}>{'Reset Password'}</Btn>
                                                }
                                                <Btn attrBtn={{ color: 'secondary ms-3', className: 'btn-block', type: 'button', onClick: () => navigate(`${process.env.PUBLIC_URL}/login`) }} >Login</Btn>
                                            </div>
                                        </FormGroup>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    );
}

export default ChangePwd;