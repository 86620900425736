import React, { Fragment, useEffect, useState, useRef } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { H3 } from "../../AbstractElements";
import AgGridTable from '../../Common/Component/AgGridTable';
import { X } from "react-feather";
import fetchWrapper from "../../Common/api/fetchWrapper";
import { API_URL } from "../../Common/Config/Config";

const DeviceDetailsModal = (props) => {
    const [open, setOpen] = useState(props.value);
    const [data, setData] = useState([]);

    const deviceRef = useRef(null)
    console.log(props.data)
    const getDeviceDetails = async () => {
        deviceRef?.current?.api?.showLoadingOverlay();
        await fetchWrapper(`${API_URL}/store/device/store/${props.data}`)
            .then((res) => {
                console.log(res.json)
                deviceRef?.current?.api?.hideOverlay();
                setData(res?.json)
            }, (err) => {
                deviceRef?.current?.api?.hideOverlay();
                console.log(err)
            })
    }

    useEffect(() => {
        if(props.type === 'store'){
            getDeviceDetails();
        } else {
            setData(props.data)
        }
    }, [])

    const tableColumns = [
        {
            headerName: 'Device Id',
            field: 'deviceID',
            filter: true,
            sortable: true,
            suppressMovable: true,
            flex: 2,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Device Name',
            field: 'deviceName',
            filter: true,
            sortable: true,
            flex: 2,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        }
    ];

    const onClose = () => {
        setOpen(false);
        props.setOpenModal(false)
    }


    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size='lg'>
                <ModalHeader style={{ display: 'flex' }}>
                    <H3 attrH3={{ className: 'modal-title-color' }}>Device Details</H3>
                    <X className="x-button" onClick={onClose} />
                </ModalHeader>
                <ModalBody>
                    <div className="device-table">
                        <div className='data-tables responsive-view '>
                            <AgGridTable
                                columnData={tableColumns}
                                tableData={data}
                                agRef={deviceRef}
                            />
                        </div>
                    </div>

                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default DeviceDetailsModal;