import React, { Fragment, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { H3 } from "../../AbstractElements";
import { X } from "react-feather";
import {
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Button
} from "reactstrap";
import fetchWrapper from "../../Common/api/fetchWrapper";
import { API_URL } from "../../Common/Config/Config";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const AddThreshold = ({ value, setOpenModal, data, setAdd, storeData, categoryData, action}) => {
    const [open, setOpen] = useState(value);
    const [category, setCategory] = useState([]);
    const { register, formState: { errors }, handleSubmit, reset, setValue } = useForm({ defaultValues: data });

    useEffect(()=>{
        if(data) {
            // getCategory(data?.storeId);
            // getAllCategory();
            // setCategory(data?.productCategoryId)
            setValue('productCategoryId', data?.productCategoryId);
            reset(data)
        }
    }, [data]);

    const getCategory = (storeId) => {
        const findCategoryType = [...storeData].find(item => item.id === storeId);
        if(findCategoryType && categoryData) {
            const categoryList = [...categoryData].filter(item => item.type === findCategoryType.categoryType);
            setCategory(categoryList);
        }
    }

    useEffect(() => {
        // getStores();
        reset(data)
    }, [reset])

    const onClose = () => {
        setOpen(false);
        setOpenModal(false)
    }

    const onSubmit = async (item) => {
        let url;
        url = action === 'Add' ? `/threshold` : `/threshold/${item?.storeId}`;
        if(action !== 'Add') {
            delete item?.categoryTypeName;
            delete item?.storeName;
            delete item?.thresholdId;
        }
        const options = {
            method: action === 'Add' ? 'POST' : 'PUT',
            body: item
        }
        await fetchWrapper(`${API_URL}${url}`, options)
            .then((res) => {
                if (res.status === 200) {
                    setAdd(true)
                    onClose()
                    toast.success('Threshold Added Succussfully')
                }
            }, (err) => {
                console.log(err)
                onClose()
                toast.error('Something went wrong!')
            })

    }

    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size='lg'>
                <ModalHeader style={{ display: 'flex' }}>
                    <H3 attrH3={{ className: 'modal-title-color' }}>{action === 'Add' ? 'Add' : 'Update'} Threshold <X className="x-button" onClick={onClose} /></H3>
                </ModalHeader>
                <ModalBody>
                    <Fragment>
                        <Row>
                            <Col lg='12'>
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                        <Col lg='6'>
                                            <div style={{ marginBottom: "35px" }}>
                                                <FormGroup style={{ marginBottom: "8px" }}>
                                                    <Label for="storeName">Store Name<span className='required'>*</span></Label>
                                                    <select className="form-select" name="storeName"
                                                        value={data?.storeId}
                                                        disabled={action !== 'Add'}
                                                        {...register('storeId', { required: true })}
                                                        onChange={(e) => getCategory(e.target.value)}>
                                                        <option value=''>- Select Store -</option>
                                                        {
                                                            storeData?.map((item) => <option value={item.id} key={item.id}>{item.name}</option>)
                                                        }
                                                    </select>
                                                    <span className="text-danger">{errors?.storeId && 'Store is required'}</span>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col lg="6">
                                            <div style={{ marginBottom: "35px" }}>
                                                <FormGroup >
                                                    <Label for="product_category">  Product Category <span className='required'>*</span></Label>
                                                    {action === 'Add' ? <>
                                                    <select className="form-select"
                                                        name="categoryTypeId"
                                                        {...register('categoryTypeId', { required: true })}
                                                    >
                                                        <option value=''>- Select Product Category -</option>
                                                        {
                                                            category?.map((item) => <option value={item.id} key={item.id}>{item.name}</option>)
                                                        }
                                                    </select>
                                                    <span className="text-danger">{errors?.categoryTypeId && 'Category is required'}</span>
                                                    </> : <input
                                                        className="form-control"
                                                        name="categoryTypeName"
                                                        placeholder=""
                                                        type="text"
                                                        disabled
                                                        defaultValue={data?.categoryTypeName}
                                                        {...register('categoryTypeName', { required: true })}
                                                    />}
                                                </FormGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg='6'>
                                            <div style={{ marginBottom: "35px" }}>
                                                <FormGroup style={{ marginBottom: "8px " }}>
                                                    <Label for="exampleSelect">Re-Order Threshold % <span className='required'>*</span></Label>
                                                    <input
                                                        className="form-control"
                                                        name="thresholdPercentage"
                                                        placeholder=""
                                                        type="text"
                                                        defaultValue={data?.thresholdPercentage}
                                                        {...register('thresholdPercentage', { required: true })}
                                                    />
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col lg="6">
                                            <div style={{ marginBottom: "35px" }}>
                                                <FormGroup >
                                                    <Label for="exampleEmail">  Nomination Email <span className='required'>*</span></Label>
                                                    <input
                                                        className="form-control"
                                                        name="email"
                                                        placeholder=""
                                                        type="text"
                                                        required
                                                        defaultValue={data?.email}
                                                        {...register('email', { required: true })}
                                                    />
                                                </FormGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className="d-flex" >
                                            <Button color="primary" >{action === 'Add' ? 'Add' : 'Update'}</Button>
                                        </div>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Fragment>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default AddThreshold;