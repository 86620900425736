import React, { Fragment } from 'react';
import { FormGroup } from 'react-bootstrap';
import { H3, P,UL,LI } from '../../AbstractElements';
import { CreateAccount, Donthaveaccount, orSigninwith } from '../Constant';
import { Link } from 'react-router-dom';
import { Amplify, Auth, Hub } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import awsmobile from '../../aws-exports';

Amplify.configure(awsmobile);

const SocialAuth = ({btntext,logtext}) => {
    return (
        <Fragment>
            <div className='login-social-title'>
                <H3>{orSigninwith}</H3>
            </div>
            <div >
                <FormGroup className='form-group'>
                    <UL attrUL={{className:'login-social d-flex flex-row simple-list'}}>
                        <LI><a target="_blank"
                            // href='https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?
                            // client_id=1022860281199-4hahc3jffa1ftima1461bsm4b6n91gel.apps.googleusercontent.com&redirect_uri
                            // =https%3A%2F%2Fgcdev.auth.us-east-2.amazoncognito.com%2Foauth2%2Fidpresponse&scope=profile%20email
                            // %20openid&response_type=code&state=H4sIAAAAAAAAAE2QT2-jMBDFv4vPMQFshz-3qKQ02QZyoImUahUZYwMFbAh21VDtd1
                            // _vZdXbvJmf3ryZb0BBDMwMOZ019G_56eOI5iZfwAqUdpIqVffcCmYFarTSU9e5WE7thOq2u7vtRyVNZIHKAo3W4xyv13QcnfrOuYSM
                            // 3kslHdquLcItolXHpa3FT_MaxO-AD7TtrVAjl21li7FRkoPfK9D9273MXxlX-y2CGc8vslEkiTr_9kgystlcf-EUefBRn3tkjk-Fbh
                            // _4aAgRW5YxE4TPZte_hUYe-MBw2XttuePROTiYPB07pJ-W8DoW08vrMHz642CqbL8n23MWzgdRiCnAX0WO6uCSXcrUDaiXivMluf
                            // nLM3zRpL7KN0QJfUXFKTkdk52N3tvENav4p0ONbpz__3XoQBclmaplq5XD1GDhAcTeJsTERZFH7NkgFrSf-QrcrQsXVPBq40IsO
                            // IKYcQYjhkoYMGbbOCDCx-DPX9sKtcfHAQAA.H4sIAAAAAAAAAAEgAN__ksvOj5RLLcecs6aKg69EZgYEhLJM9gBGmyR-_Zp1KqY
                            // TX9XbIAAAAA.3&service=lso&o2v=2&flowName=GeneralOAuthFlow'
                        ><i className="fa fa-google me-0 social-icons" style={{fontSize: 'xx-large'}}> </i></a></LI>
                        {/* <LI><a href='https://microsoft.com/'><i className="fa fa-windows me-0 social-icons"></i></a></LI> */}
                    </UL>                
                </FormGroup>
            </div>
            {/* <P attrPara={{ className: 'mt-4 mb-0 text-center'}} >
                {logtext?logtext:Donthaveaccount}{(!logtext && !btntext)?<Link to={`${process.env.PUBLIC_URL}/pages/auth/register-simple`} className="ms-2">{CreateAccount}</Link>:
                <Link to={`${process.env.PUBLIC_URL}/login`} className="ms-2">{btntext}</Link>}
            </P> */}
        </Fragment>
    );
};

export default SocialAuth;
