import React, { Fragment, useState, useRef, useCallback } from 'react';
import { Container, Row, Col, Card, CardBody, Button } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import { Edit, Trash, Eye } from 'react-feather';
import AddInventory from './AddInventory';
import ViewJewellery from './ViewJewellery';
import BulkUpload from '../StoreMgmt/BulkUpload';
import { ShoppingBag } from "react-feather";
import Multiselect from 'multiselect-react-dropdown';
import AgGridTable from '../../Common/Component/AgGridTable';
import fetchWrapper from '../../Common/api/fetchWrapper';
import { currencyFormatter } from '../../Common/api/helper';
import { API_URL } from '../../Common/Config/Config';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import DeleteInventory from '../InventoryMgmt-Apparel/DeleteInventory';
import moment from 'moment';

const JewelleryInventoryMgmtlist = () => {
    const [open, setOpen] = useState(false);
    const [openBulk, setOpenBulk] = useState(false);
    const [viewProduct, setViewProduct] = useState(false);
    const [selectedProduct, setSelectProduct] = useState();
    const [inventoryData, setInventory] = useState();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false)
    const [orgData, setOrgData] = useState();
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [deleteOpen, setDeleteopen] = useState(false)
    const [dataId, setDataId] = useState();
    const [isDeleted, setIsDeleted] = useState(false)
    const [chartData, setChartData] = useState([]);
    const [isUpdate, setUpdate] = useState(false);
    const currency = sessionStorage.getItem('userCurrency');
    const [storeData, setStoreData] = useState([]);
    const [widgetList, setWidgetList] = useState([]);
    const [selectedWidget, setSelectedWidget] = useState(0);
    const [scannedInStock, setScannedStock] = useState(0);

    const inventRef = useRef(null);

    const getCategoryData = async () => {
        await fetchWrapper(`${API_URL}/productcategory/type/JEWELLERY/details`)
            .then((res) => {
                let catData = res.json?.map((item) => {
                    return {
                        key: item?.id,
                        name: item?.name
                    }
                })

                setCategoryData(catData)

            }, (err) => {
                console.log(err)
            })
    }

    const getCategoryList = (data) => {
        let chart_data = [{
            name: 'Total Number of Stocks',
            count: data.length
        }];
        let soldQty = 0;
        let handledQty = 0;
        data.forEach((item) => {
            soldQty = soldQty + item.inventorySold;
            handledQty = handledQty + item.inventoryAdded;
        });
        let scannedStock = data.filter(ele => ((ele?.inventoryAdded === 1 && ele?.inventorySold === 0) || (ele?.tagId != null && ele?.inventorySold === 0)))
        setScannedStock(scannedStock)
        let missedStock = data.filter(ele => ((ele?.inventoryAdded === 0 && ele?.inventorySold === 0 && ele?.tagId === null)))
        console.log(missedStock?.length, scannedStock?.length)
        setWidgetList([{
            name: 'Total No. of Stocks',
            count: data.length
        }, {
            name: 'Scanned Stock on Hand',
            count: scannedStock?.length
        }, {
            name: 'Total No. of Missed Stock',
            count: (missedStock?.length) > 0 ? (missedStock?.length) : (scannedStock?.length === 0) ? 0 : 0
        }, {
            name: 'Total No. of Sold Stock',
            count: soldQty
        }])
        setChartData(chart_data);
    }

    const loadData = async (data) => {
        inventRef?.current?.api?.showLoadingOverlay();
        let formData = new FormData();
        if (data) {
            data?.categories && formData.append('categories', `"` + data?.categories + `"`)
            formData.append('startDate', `"` + data?.startDate + `"`)
            formData.append('endDate', `"` + data?.endDate + `"`)
        }
        const options = {
            method: 'POST',
            body: formData,
            headers: {
                "Authorization": `Bearer ${sessionStorage.getItem('accessToken')}`
            }
        }
        await fetchWrapper(`${API_URL}/invjewellery/getByFilters`, options)
            .then((res) => {
                console.log(res)
                setData(res.json)
                setInventory(res.json)
                getCategoryList(res.json)
                inventRef?.current?.api?.hideOverlay()
            })
            .catch((err) => {
                inventRef?.current?.api?.hideOverlay()
                console.error(err)
            })

    }

    const loadOrgData = async (item) => {
        setOrgData(item);
        setOpen(true)
    }



    const onViewClick = async (item) => {
        setSelectProduct(item);
        setViewProduct(true)
    }

    const onSelectCard = (index) => {
        let filterItem = [];
        if (index === 0) {
            filterItem = JSON.parse(JSON.stringify(inventoryData));
        } else if (index === 1) {
            filterItem = JSON.parse(JSON.stringify(inventoryData)).filter(subItem => ((subItem?.inventoryAdded === 1 && subItem?.inventorySold === 0) ||
                subItem?.tagId != null));
        } else if (index === 2) {
            let missedStock = JSON.parse(JSON.stringify(inventoryData)).filter(subItem => (subItem?.inventoryAdded === 0 && subItem?.inventorySold === 0 && subItem?.tagId === null));
            filterItem = missedStock?.length > 0 ? JSON.parse(JSON.stringify(inventoryData)).filter(subItem =>
                (subItem?.inventoryAdded === 0 && subItem?.inventorySold === 0 && subItem?.tagId === null)) : (scannedInStock?.length === 0) ?? [];
        } else if (index === 3) {
            filterItem = JSON.parse(JSON.stringify(inventoryData)).filter(subItem => (subItem?.inventorySold === 1));
        }
        setSelectedWidget(index);
        setData(filterItem);
    }



    const columnData = [
        {
            headerName: 'Store',
            field: 'storeName',
            filter: true,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            flex: 1,
        },
        {
            headerName: 'Category',
            field: 'categoryName',
            filter: true,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            flex: 1,
        },
        // {
        //     headerName: 'SKU ID',
        //     field: 'skuId',
        //     filter: true,
        //     sortable: true,
        //     suppressMovable: true,
        //     filterParams: {
        //         filterOptions: ['contains'],
        //         maxNumConditions: 0,
        //         buttons: ['reset']
        //     },
        //     flex: 2,
        // },
        {
            headerName: 'Product Name',
            field: 'productName',
            filter: true,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            flex: 1.75,
        },
        {
            headerName: 'RFID Number',
            field: 'rfid',
            filter: true,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            flex: 2,
        },
        {
            headerName: 'Price',
            valueGetter: (params) => {
                return currencyFormatter(params.data.productCost, currency)
            },
            width: 100,
            cellClass: 'text-right'
        },
        {
            headerName: 'SOH Qty',
            field: 'sohQuantity',
            filter: true,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            width: 100
        },
        {
            headerName: 'View',
            width: 70,
            cellRenderer: (params) => {
                return (
                    <Eye className={`edit-icon`} onClick={() => onViewClick(params.data)} />
                )
            },
            suppressMovable: true,
        },
        {
            headerName: 'Edit',
            width: 80,
            cellRenderer: params => {
                return (
                    <Edit className={`edit-icon`} onClick={() => loadOrgData(params.data)} />
                )
            },
            suppressMovable: true,
        },
        {
            headerName: 'Delete',
            width: 80,
            cellRenderer: params => {
                return (
                    <Trash className={`edit-icon`} onClick={() => handleDelete(params.data.id)} />
                )
            },
            suppressMovable: true,
        },
    ]
    const handleClick = () => {
        setOrgData(undefined);
        setOpen(true);
    }
    const handleBuldClick = () => setOpenBulk(true);

    const handleSelect = (e) => {
        chartData.length = 0;
        let selected = [], getItems = [];
        e?.forEach(item => {
            selected = inventoryData?.filter((i) => i.categoryId === item?.key)
            getItems = [...getItems, ...selected]
        })
        getCategoryList(getItems)
        setSelectedCategory(e)
        setData(getItems)
    }

    const handleRemoved = (selectedItems, remove) => {
        chartData.length = 0;
        setSelectedCategory(selectedItems)
        if (selectedItems?.length > 0) {
            let selected = [], getItems = [];
            selectedItems?.forEach(item => {
                selected = inventoryData?.filter((i) => i.categoryId === item?.key)
                getItems = [...getItems, ...selected]
            })
            getCategoryList(getItems)
            setData(getItems)
        } else {
            getCategoryList(inventoryData)
            setData(inventoryData)
        }

    }

    const handleExport = useCallback(() => {
        inventRef?.current.api.exportDataAsCsv({ columnKeys: ['storeName', 'categoryName','skuId', 'rfid', 'productName', 
            
        ] });
    })


    const handleDelete = (value) => {
        setDeleteopen(true)
        setDataId(value)
    }

    const getStoreData = async () => {
        await fetchWrapper(`${API_URL}/store/type/JEWELLERY`)
            .then((res) => {
                setStoreData(res.json);
            }, (err) => {
                console.log(err)
            })
    }
    const handleDateRangeApply = (event, picker) => {
        // Access the selected date range using the picker object
        const startDate = picker.startDate.format('YYYY-MM-DD');
        const endDate = picker.endDate.format('YYYY-MM-DD');

        // Do something with the selected date range
        console.log(`Selected date range: ${startDate} to ${endDate}`);

        // Update state or perform any other actions
        loadData({
            categories: selectedCategory.length ? selectedCategory.map(item => item.key).join() : '',
            startDate: startDate,
            endDate: endDate,
        });
    };


    const onGridReady = useCallback(async () => {
        getStoreData()
        getCategoryData()
        loadData()
    })


    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col sm="12" className='inventory-container'>
                        <div className='d-flex mt-3'>
                            <h2 className='col-md-4'>Inventory Summary</h2>
                            <div className='col-md-2'>
                                {categoryData.length ?
                                    <Multiselect
                                        displayValue='name'
                                        placeholder="Select Category"
                                        options={categoryData}
                                        selectedValues={selectedCategory}
                                        onSelect={(e) => handleSelect(e)}
                                        onRemove={handleRemoved}
                                    /> : <></>}
                            </div>
                            <div className='col-md-3 ms-2'>
                                <DateRangePicker onApply={handleDateRangeApply}>
                                    <input type="text" className="form-control col-4" />
                                </DateRangePicker>
                            </div>
                            <div className='col-md-3 ms-2'>
                                <div className="action_button">
                                    <div className="link action_button" onClick={handleClick}>
                                        <i style={{ fontSize: '25px', color: "#2b7704" }} className="icofont  icofont-plus-circle" ></i>
                                        &nbsp;&nbsp;{"Add Inventory"}</div>
                                </div>
                                {(categoryData.length && (sessionStorage.getItem('userType') === 'STORE_USER' || storeData.length)) ?
                                    <div className="action_button ms-2">
                                        <div className="link action_button" onClick={handleBuldClick}>Bulk Upload</div>
                                    </div> : <></>
                                }
                            </div>
                        </div>
                        <Breadcrumbs mainTitle="Users" parent="Inventory Mgmt" title="List" />
                        <div className='row'>
                            {widgetList.length ? widgetList.map((item, index) =>
                                <div className="col-md-3" key={index} style={{ pointer: 'cursor' }}
                                    onClick={() => onSelectCard(index)}>
                                    <div className={selectedWidget === index ? "o-hidden card active-widget" : "o-hidden card"}>
                                        <div className="card-body">
                                            <div className="d-flex static-widget">
                                                <div className="flex-grow-1">
                                                    <h6>{item.name}</h6>
                                                    <h2 className="mb-0 counter"><span>{item.count}</span></h2>
                                                </div>
                                            </div>
                                            <div className="progress-widget">
                                                <div className="progress sm-progress-bar progress-animate">
                                                    <div className="progress-gradient-secondary" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: '100%' }}>
                                                        <span className="animate-circle"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>) : <></>}
                        </div>
                        <Card className="border-bottom-light newtable">
                            <CardBody className='data-tables'>
                                <div className='mb-1rem d-flex justify-end'>
                                    <Button color='secondary' onClick={handleExport} >Export</Button>
                                </div>
                                <div className="">
                                    <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                        <AgGridTable
                                            columnData={columnData}
                                            tableData={data}
                                            agRef={inventRef}
                                            onGridReady={onGridReady}
                                        />
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    {open && <AddInventory value={open} closeModal={(e) => { e && loadData(); setOpen(false) }} data={orgData} action={orgData ? 'Edit' : 'Add'} setUpdate={setUpdate} />}
                    {openBulk && <BulkUpload value={openBulk} categoryList={categoryData} storeList={storeData} closeModal={(e) => { e && loadData(); setOpenBulk(false) }} type={'inventoryJewellery'} />}
                    {viewProduct && <ViewJewellery value={viewProduct} setOpenModal={setViewProduct} data={selectedProduct} />}
                    {deleteOpen && <DeleteInventory value={deleteOpen} setOpenModal={setDeleteopen} dataId={dataId} setDelete={setIsDeleted} />}
                </Row>
            </Container>
        </Fragment>
    );

};

export default JewelleryInventoryMgmtlist;