import React, { Fragment, useState } from 'react';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { Btn, H2, P, Image } from '../../../AbstractElements';
import { EmailPassword, ForgotPassword, Password, RememberPassword, USER_DETAILS, signinaccount } from '../../Constant';
import { Eye, EyeOff } from 'react-feather';
import { useForm } from 'react-hook-form';
import fetchWrapper from '../../api/fetchWrapper';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import ButtonLoader from '../ButtonLoader';
import { toast, ToastContainer } from 'react-toastify';
import SocialAuth from '../../Auth/SocialAuth';

const LoginForm = () => {
    const [togglePassword, setTogglePassword] = useState(false);
    const [password, setPassword] = useState();
    const [checked, setChecked] = useState(false);
    const { register, formState: { errors }, handleSubmit } = useForm()
    const navigate = useNavigate();

    const defaulPage = {
        STORE_USER: '/profile',
        STORE_ADMIN: '/store-admin/user-list',
        SYS_ADMIN: '/sys-admin/customer-list',
        SYS_USER: '/sys-user/customer-list'
    }
    const [loading, setLoading] = useState(false);

   

    const onSubmit = async (userData) => {
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: ({
                email: userData.email,
                password: userData.password
            })
        };
        await fetchWrapper(`https://wave-connect-api-new.azurewebsites.net/authentication`, requestOptions)
            .then((res) => {
                const data = res.json;
                if (data.isactive.charAt(0) === 'T') {
                    sessionStorage.setItem('email', userData.email)
                    sessionStorage.setItem('accessToken', data.jwt);
                    sessionStorage.setItem('profileName', data.firstName + ' ' + (data.lastName ? data.lastName : '') );
                    sessionStorage.setItem('userType', data.userType);
                    sessionStorage.setItem('userId', data.id);
                    sessionStorage.setItem('userCountry', data.country);
                    sessionStorage.setItem('userCurrency', data.currency);
                    if(data?.changePassword === 'TRUE') {
                        navigate(`${process.env.PUBLIC_URL}/change-password`);
                    } else {
                        navigate(`${process.env.PUBLIC_URL}${defaulPage[data.userType]}`);
                    }
                } else {
                    toast.warning("Customer not active. Kindly get in touch with the administrator for renewal.");
                    setLoading(false);
                }
            }, (error) => {
                setLoading(false);
                console.error(error);
                if(Object.keys(error?.json)[0] === 'Bad Credentials') {
                    toast.error('Incorrect username or password')
                } else {
                    toast.error("Someting went wrong")
                }
                
            })
    };

    const handleCheckbox = () => {
        var value = checked ? false : true;
        setChecked(value);
    }

    return (
        (<Fragment>
            <ToastContainer />
            <div>
                <div className='login-main'>
                    <div className='logo'>
                        <Image attrImage={{ style: { width: '13rem' }, className: 'for-light', src: `${require('../../../assets/images/wave-connect-logo/logo.png')}` }} />
                    </div>
                    <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                        <H2 attrH2={{ className: "text-center" }}>{signinaccount}</H2>
                        <P attrPara={{ className: 'text-center' }}>{EmailPassword}</P>
                        <FormGroup className='form-group'>
                            <Label className="col-form-label">{'Email ID / User ID'} <span className='required'>*</span></Label>
                            <input
                                className="form-control"
                                name='email'
                                type="text" onChange={e => setEmail(e.target.value)}
                                {...register('email', { required: true })}
                            />
                            <span className='text-danger'>{errors.email && errors.email.type === 'required' && 'Email ID / User ID is required'}</span>
                        </FormGroup>
                        <FormGroup className='form-group'>
                            <Label className="col-form-label">{Password} <span className='required'>*</span></Label>
                            <div className='form-input position-relative'>
                                <input className="form-control"
                                    onChange={e => setPassword(e.target.value)}
                                    type={togglePassword ? 'text' : 'password'}
                                    name='password'
                                    {...register('password', {
                                        required: true,
                                        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/g
                                    })}
                                />
                                <div className="show-hide new_show_hide" onClick={() => setTogglePassword(!togglePassword)}>
                                    <span>{togglePassword ? <Eye className='show-icon' /> : <EyeOff className='show-icon' />}</span>
                                </div>
                                {console.log(errors)}
                                <span className='text-danger'>{errors.password && errors.password.type === 'required' && 'Password is required'}</span>
                                <span className='text-danger'>{errors.password && errors.password.type === 'pattern' && 'Password should be contain 8 characters, One Special character, One Uppercase, One Number'}</span>
                            </div>
                        </FormGroup>
                        <FormGroup className="form-group mb-0">
                            <div className="checkbox p-0">
                                <Input id="checkRememberPassword" type="checkbox" checked={checked} onChange={handleCheckbox} />
                                <Label className="text-muted" for="checkbox1">{RememberPassword}</Label>
                            </div>
                            <Link className="link" to={`${process.env.PUBLIC_URL}/forgot-password`}>{ForgotPassword}</Link>
                            <div className='text-end mt-3' >
                                {
                                    loading ?
                                        <ButtonLoader loaderText={'Submitting'} className="btn-block" color="primary w-100" /> :
                                        <Btn attrBtn={{ color: 'primary w-100', className: 'btn-block', type: 'submit' }}>{'Log In'}</Btn>
                                }
                            </div>
                        </FormGroup>
                        <SocialAuth />
                    </Form>
                </div>
            </div>
        </Fragment>)
    );
};

export default LoginForm;