import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import AgGridTable from '../../Common/Component/AgGridTable';
import { useRef } from 'react';
import { Trash, Edit } from 'react-feather';
import fetchWrapper from '../../Common/api/fetchWrapper';
import { API_URL } from '../../Common/Config/Config';
import { toast } from 'react-toastify';
import AddCategory from './AddCategory';
import DeleteCategory from './DeleteCategory';
import avatarLogo from '../../assets/images/avtar/gray-avatar.png';


const ProductCategoryList = () => {
    const [data, setData] = useState();
    const categoryRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [categoryData, setCategoryData] = useState();
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [dataId, setDataId] = useState();
    const [isDeleted, setIsDeleted] = useState(false);
    const [addStatus, setAdd] = useState(false)

    const loadData = async () => {
        categoryRef?.current?.api?.showLoadingOverlay();
        await fetchWrapper(`${API_URL}/productcategory`)
            .then((res) => {
                setData(res.json)
                categoryRef?.current?.api?.hideOverlay();
            })
            .catch((err) => {
                categoryRef?.current?.api?.hideOverlay();
                console.error(err)
            })
    }

    useEffect(()=>{
        if(addStatus || isDeleted) {
            loadData()
        }
    }, [addStatus, isDeleted])

    const loadUpdateData = (data) => {
        setOpen(true);
        setCategoryData(data);
    }

    const handleDelete = (value) => {
        setDeleteOpen(true)
        setDataId(value)
    }

    const handleStatus = async (data) => {
        let updateStatus = data?.active === 'TRUE' ? 'FALSE' : 'TRUE';
        const options = {
            method: 'PUT'
        }
        await fetchWrapper(`${API_URL}/productcategory/status/${data?.id}/${updateStatus}`, options)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    console.log(res.json)
                    if (res?.json?.active === 'FALSE') {
                        toast.error('Category has been disabled!')
                    } else {
                        toast.success('Category has been enabled!')
                    }
                    loadData();
                }
            })
            .catch(err => console.log(err))
    }

    const columnData = [
        {
            headerName: 'Logo',
            field: 'logo',
            suppressMovable: true,
            cellRenderer: (param) => {
                return (
                    <div>
                        <img className='customer-logo' src={`${param.data?.logo ? `data:image/jpeg;base64,${param.data?.logo}` : avatarLogo}`} alt='customer-logo' />
                    </div>
                )
            },
            width: 140
        },
        {
            headerName: 'Name',
            field: 'name',
            filter: true,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            flex: 2,
        },
        {
            headerName: 'Description',
            field: 'description',
            filter: true,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            flex: 2,
        },
        {
            headerName: 'Type',
            field: 'type',
            filter: true,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            width: 200,
        },
        {
            headerName: 'Status',
            filter: false,
            width: 90,
            sortable: true,
            suppressMovable: true,
            cellRenderer: (params) => {
                return (
                    <div className='mr-21'>
                        <label className="switch">
                            <input type="checkbox" id="togBtn" defaultChecked={params.data?.active === 'TRUE' ? true : false}
                            onChange={() => handleStatus(params.data)}
                            />
                            <div className="slider round">
                                <span className="on"></span>
                                <span className="off"></span>
                            </div>
                        </label>
                    </div>
                )
            }
        },
        {
            headerName: 'Edit',
            width: 90,
            cellRenderer: params => {
                return (
                    <Edit className={`edit-icon`}
                        onClick={() => loadUpdateData(params.data)} 
                    />
                )
            },
            suppressMovable: true,
        },
        {
            headerName: 'Delete',
            width: 90,
            cellRenderer: params => {
                return (
                    <Trash className={`edit-icon`}
                    onClick={() => handleDelete(params.data.id)} 
                    />
                )
            },
            suppressMovable: true,
        },
    ]

    const handleClick = () => {
        setCategoryData()
        setOpen(true)
    }

    const onGridReady = useCallback(async() => {
        loadData()
    })

    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col lg='12'>
                        <div className="d-flex">
                            <Breadcrumbs parent="Product Category Management" title="List" />
                            <div className="text-right  action_button">
                                <div className="link action_button" onClick={handleClick}>
                                    <i style={{ fontSize: '25px', color: "#2b7704" }} className="icofont  icofont-plus-circle" ></i>
                                    &nbsp;&nbsp;{"Add Category"}</div>
                            </div>
                        </div>
                        <Card className="border-bottom-light newtable">
                            <CardBody className='data-tables'>
                                <div>
                                    <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                        <AgGridTable
                                            columnData={columnData}
                                            tableData={data}
                                            agRef={categoryRef}
                                            onGridReady={onGridReady}
                                        />
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                { open && <AddCategory value={open} setOpenModal={setOpen} data={categoryData} action={categoryData ? 'Edit' : 'Add'} setAdd={setAdd}/> }
                { deleteOpen && <DeleteCategory value={deleteOpen} setOpenModal={setDeleteOpen} dataId={dataId} setDelete={setIsDeleted} /> }
            </Container>
        </Fragment>
    )
}

export default ProductCategoryList;