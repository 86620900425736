import React, { useState, useEffect } from "react";
import { Spinner } from "reactstrap";

const LayoutLoader = ({value}) => {
    const [loading, setLoading] = useState(true)

    useEffect(()=>{
        console.log(value)
        if(value)
            setLoading(value)
    }, [value])

    useEffect(() => {
        setTimeout(() => {
           document.body.style.overflow = 'auto';
           setLoading(false);
       }, 2000);

       document.body.style.overflow = 'hidden';
   }, []);
    return (
        <div>
            <div className={loading ? "new_loader" : "d-none"}>
                <Spinner color="primary"></Spinner>{" "}
            </div>
        </div>
    )
}

export default LayoutLoader

