// dashbaord
// import UserDashboard from '../User/Dashboard/UserDashboard';
// import PowerBiReport from '../User/Dashboard/PowerBiReport';

//Green-Carbon Pages
import CustomersList from '../Admin/Customer';
import Dashboard from '../Admin/Dashboard/dashboard';
import Userlist from '../Admin/UserOnboard/Userlist';
import EditCustomer from '../Admin/Customer/Edit Customer';
import StoreUserlist from '../StoreAdmin/StoreUserOnboard/StoreUserlist';
import ViewCustomer from '../Admin/Customer/ViewCustomer';
import AmrList from '../Admin/AmrMgmt/AmrList';
import StoreAmrList from '../StoreAdmin/AmrMgmt/AmrList';
import ModuleSubscribe from '../Common/Component/ModuleSubscription/moduleSubscribe';
import SubscriptionModules from '../Admin/SubscriptionModules/SubscriptionModules';
import NewPage from '../Common/Component/Error/underwork';
import StoreMgmtlist from '../StoreAdmin/StoreMgmt/store-mgmt-list';
import ThresholdMgmtlist from '../StoreAdmin/ThresholdMgmt/threshold-mgmt-list';
import SummaryChart from '../StoreAdmin/Chart/SummaryChart';
import ApparelInventoryMgmtlist from '../StoreAdmin/InventoryMgmt-Apparel/inventory-mgmt-list';
import JewelleryInventoryMgmtlist from '../StoreAdmin/InventoryMgmt-Jewellery/inventory-mgmt-list';
import InsightChart from '../StoreAdmin/Chart/DummyChart';
import PowerBiReport from '../User/Dashboard/PowerBiReport';
import ProductCategoryList from '../StoreAdmin/ProductCategory/ProductCategoryList';
import TagManagement from '../StoreAdmin/TagMgmt';
import Profile from '../Common/Component/Profile/Profile';

export const routes = [
        //common
        { path: `${process.env.PUBLIC_URL}/profile`, Component: <Profile /> },

        //Admin pages
        { path: `${process.env.PUBLIC_URL}/sys-admin/customer-list`, Component: <CustomersList /> },
        { path: `${process.env.PUBLIC_URL}/sys-admin/user-management`, Component: <Userlist /> },
        { path: `${process.env.PUBLIC_URL}/sys-admin/edit-customer/:id`, Component: <EditCustomer /> },
        { path: `${process.env.PUBLIC_URL}/sys-admin/view-customer/:id`, Component: <ViewCustomer /> },
        { path: `${process.env.PUBLIC_URL}/sys-admin/dashboard`, Component: <Dashboard /> },
        { path: `${process.env.PUBLIC_URL}/sys-admin/module-origanization`, Component: <ModuleSubscribe /> },
        { path: `${process.env.PUBLIC_URL}/sys-admin/iot-management`, Component: <NewPage /> },
        { path: `${process.env.PUBLIC_URL}/sys-admin/subscription-modules`, Component: <SubscriptionModules /> },
        { path: `${process.env.PUBLIC_URL}/sys-admin/amr-modules`, Component: <AmrList /> },
        

        //Admin User pages
        { path: `${process.env.PUBLIC_URL}/sys-user/customer-list`, Component: <CustomersList /> },
        { path: `${process.env.PUBLIC_URL}/sys-user/user-management`, Component: <Userlist /> },
        { path: `${process.env.PUBLIC_URL}/sys-user/edit-customer/:id`, Component: <EditCustomer /> },
        { path: `${process.env.PUBLIC_URL}/sys-user/view-customer/:id`, Component: <ViewCustomer /> },
        { path: `${process.env.PUBLIC_URL}/sys-user/dashboard`, Component: <Dashboard /> },
        { path: `${process.env.PUBLIC_URL}/sys-user/module-origanization`, Component: <ModuleSubscribe /> },
        { path: `${process.env.PUBLIC_URL}/sys-user/iot-management`, Component: <NewPage /> },
        { path: `${process.env.PUBLIC_URL}/sys-user/subscription-modules`, Component: <SubscriptionModules /> },
        { path: `${process.env.PUBLIC_URL}/sys-user/amr-modules`, Component: <AmrList /> },
        

        //Store Admin
        { path: `${process.env.PUBLIC_URL}/store-admin/user-list`, Component: <StoreUserlist /> },
        { path: `${process.env.PUBLIC_URL}/store-admin/store-mgmt/:type`, Component: <StoreMgmtlist /> },
        { path: `${process.env.PUBLIC_URL}/store-admin/inventory-mgmt-apparel`, Component: <ApparelInventoryMgmtlist /> },
        { path: `${process.env.PUBLIC_URL}/store-admin/inventory-mgmt-jewellery`, Component: <JewelleryInventoryMgmtlist /> },
        { path: `${process.env.PUBLIC_URL}/store-admin/dashboard`, Component: <NewPage /> },
        { path: `${process.env.PUBLIC_URL}/store-admin/threshold-mgmt`, Component: <ThresholdMgmtlist /> },
        { path: `${process.env.PUBLIC_URL}/store-admin/summary-report`, Component: <SummaryChart /> },
        { path: `${process.env.PUBLIC_URL}/store-admin/sales-trends`, Component: <PowerBiReport request_param={{reportCode: 'SALESTRENDSJEWELRY'}} /> },
        { path: `${process.env.PUBLIC_URL}/store-admin/top-selling`, Component: <PowerBiReport request_param={{reportCode: 'TOPSELLINGPRODUCTS(TESTING)'}} /> },
        { path: `${process.env.PUBLIC_URL}/store-admin/performance`, Component: <PowerBiReport request_param={{reportCode: 'PRODUCTCATEGORYJEWELRY'}} /> },
        { path: `${process.env.PUBLIC_URL}/store-admin/shelf-placement`, Component: <PowerBiReport request_param={{reportCode: 'SHELFPLACEMENTJEWELRY'}} /> },
        { path: `${process.env.PUBLIC_URL}/store-admin/inventory-trunover`, Component: <PowerBiReport request_param={{reportCode: 'INVENTORYSTATUSJEWELERY'}} /> },
        { path: `${process.env.PUBLIC_URL}/store-admin/planogram-compliance`, Component: <InsightChart filename="planogram.png" title="Planogram Metrics" period="Oct 2023" /> },
        { path: `${process.env.PUBLIC_URL}/store-admin/assortment`, Component: <InsightChart filename="assortment.png" title="Assortment Recomendations" /> },
        { path: `${process.env.PUBLIC_URL}/store-admin/amr-mgmt`, Component: <StoreAmrList /> },
        { path: `${process.env.PUBLIC_URL}/store-admin/product-category-management`, Component: <ProductCategoryList /> },
        { path: `${process.env.PUBLIC_URL}/store-admin/tag-management`, Component: <TagManagement /> },

        // { path: `${process.env.PUBLIC_URL}/store-admin/store-mgmt`, Component: <StoreMgmtlist /> },
        { path: `${process.env.PUBLIC_URL}/store-admin/apparel/summary-report`, Component: <SummaryChart /> },
        { path: `${process.env.PUBLIC_URL}/store-admin/apparel/sales-trends`, Component: <PowerBiReport request_param={{reportCode: 'SALESTRENDSAPPAREL'}} /> },
        { path: `${process.env.PUBLIC_URL}/store-admin/apparel/top-selling`, Component: <PowerBiReport request_param={{reportCode: 'TOPSELLINGAPPAREL'}} /> },
        { path: `${process.env.PUBLIC_URL}/store-admin/apparel/performance`, Component: <PowerBiReport request_param={{reportCode: 'PRODUCTCATEGORYAPPAREL'}} /> },
        { path: `${process.env.PUBLIC_URL}/store-admin/apparel/shelf-placement`, Component: <PowerBiReport request_param={{reportCode: 'SHELFPLACEMENTAPPAREL'}} /> },
        { path: `${process.env.PUBLIC_URL}/store-admin/apparel/inventory-trunover`, Component: <PowerBiReport request_param={{reportCode: 'INVENTORYSTATUSAPPAREL'}} /> },
        { path: `${process.env.PUBLIC_URL}/store-admin/apparel/planogram-compliance`, Component: <InsightChart filename="planogram.png" title="Planogram Metrics" period="Oct 2023" /> },
        { path: `${process.env.PUBLIC_URL}/store-admin/apparel/assortment`, Component: <InsightChart filename="assortment.png" title="Assortment Recomendations" /> },

        //Store user
        { path: `${process.env.PUBLIC_URL}/store-user/user-list`, Component: <StoreUserlist /> },
        { path: `${process.env.PUBLIC_URL}/store-user/store-mgmt/:type`, Component: <StoreMgmtlist /> },
        { path: `${process.env.PUBLIC_URL}/store-user/inventory-mgmt-apparel`, Component: <ApparelInventoryMgmtlist /> },
        { path: `${process.env.PUBLIC_URL}/store-user/inventory-mgmt-jewellery`, Component: <JewelleryInventoryMgmtlist /> },
        { path: `${process.env.PUBLIC_URL}/store-user/sales-trends`, Component: <PowerBiReport request_param={{reportCode: 'SALESTRENDSJEWELRY'}} /> },
        { path: `${process.env.PUBLIC_URL}/store-user/top-selling`, Component: <PowerBiReport request_param={{reportCode: 'TOPSELLINGPRODUCTS(TESTING)'}} /> },
        { path: `${process.env.PUBLIC_URL}/store-user/performance`, Component: <PowerBiReport request_param={{reportCode: 'PRODUCTCATEGORYJEWELRY'}} /> },
        { path: `${process.env.PUBLIC_URL}/store-user/shelf-placement`, Component: <PowerBiReport request_param={{reportCode: 'SHELFPLACEMENTJEWELRY'}} /> },
        { path: `${process.env.PUBLIC_URL}/store-user/inventory-trunover`, Component: <PowerBiReport request_param={{reportCode: 'INVENTORYSTATUSJEWELERY'}} /> },
        { path: `${process.env.PUBLIC_URL}/store-user/apparel/summary-report`, Component: <SummaryChart /> },
        { path: `${process.env.PUBLIC_URL}/store-user/apparel/sales-trends`, Component: <PowerBiReport request_param={{reportCode: 'SALESTRENDSAPPAREL'}} /> },
        { path: `${process.env.PUBLIC_URL}/store-user/apparel/top-selling`, Component: <PowerBiReport request_param={{reportCode: 'TOPSELLINGAPPAREL'}} /> },
        { path: `${process.env.PUBLIC_URL}/store-user/apparel/performance`, Component: <PowerBiReport request_param={{reportCode: 'PRODUCTCATEGORYAPPAREL'}} /> },
        { path: `${process.env.PUBLIC_URL}/store-user/apparel/shelf-placement`, Component: <PowerBiReport request_param={{reportCode: 'SHELFPLACEMENTAPPAREL'}} /> },
        { path: `${process.env.PUBLIC_URL}/store-user/apparel/inventory-trunover`, Component: <PowerBiReport request_param={{reportCode: 'INVENTORYSTATUSAPPAREL'}} /> },
        { path: `${process.env.PUBLIC_URL}/store-user/apparel/planogram-compliance`, Component: <InsightChart filename="planogram.png" title="Planogram Metrics" period="Oct 2023" /> },
        { path: `${process.env.PUBLIC_URL}/store-user/apparel/assortment`, Component: <InsightChart filename="assortment.png" title="Assortment Recomendations" /> },
        
];


