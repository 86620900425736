import React, { Fragment, useState, useEffect } from "react";
import { CardBody, Modal, ModalBody, ModalHeader } from "reactstrap";
import {
    Container,
    Row,
    Card,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    InputGroup,
    InputGroupText
} from "reactstrap";
import { H3 } from "../../../AbstractElements";
import { X } from "react-feather";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { logoutAction } from "../../api/helper";
import { useNavigate } from "react-router";
import { Eye, EyeOff } from "react-feather";
import ButtonLoader from "../ButtonLoader";
import { countries } from "../../Data/CountryList/Defaultdata";

const EditProfileModal = ({ value, onCloseModal, preData }) => {
    const [open, setOpen] = useState(value);
    const [loading, setLoading] = useState(false)
    const [stateList, setStateList] = useState([]);
    const [country, setCountry] = useState();
    const [data, setData] = useState(preData)
    const { register, handleSubmit, formState: { errors }, reset, setValue, getValues, resetField } = useForm({ defaultValues: data });
    const [state, setState] = useState(preData?.state);
    const [currency, setCurrency] = useState();
    const [dialCode, setDialCode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState()

    const countryChangeEvent = (item, countryList) => {
        const selectedItem = countryList.find((countryItem) => countryItem.country === item);
        setCountry(item);
        setCurrency(selectedItem?.currency_code);
        setDialCode(selectedItem?.dial_code)
        setValue('currency', selectedItem?.currency_code);
        setStateList(selectedItem.states);
        setValue('state', '')
        setState();
    }

    useEffect(() => {
        setData(prev => ({ ...prev, ...preData }))
        reset(preData)
        if (preData) {
            let statesList = countries.find((item) => item.country === preData?.country)
            setDialCode(statesList?.dial_code)
            setStateList(statesList?.states)
            let phone = preData?.contactNumber.split(' ')[1];
            setPhoneNumber(phone)
            setValue('contactNumber', phone)
        }
    }, [preData])
    
    const onClose = (update) => {
        setOpen(false);
        onCloseModal(update);
    }

    const onSubmit = async(data) => {
        console.log(data)
        const options = {
            method: 'PUT',
            body: data
        }
        await fetchWrapper(`${API_URL}//customer/${data.customerId}`, options)
            .then((res) => {
                if (res.ok) {
                    toast.success('Profile Updated Successfully!')
                    onClose(true)
                }
            })
            .catch((err) => {
                setLoading(false)
                if (err.status == 409) {
                    toast.error('Customer Already Exist..')
                    console.log(err)
                } else if (err.status === 500) {
                    toast.error('Something went wrong')
                }
            })
    } 
    
    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size='lg'>
            <ModalHeader className="d-flex">
                    <H3 attrH3={{ className: 'modal-title-color' }}>
                        Edit Profile
                    </H3>
                    <X className="x-button" onClick={() => onClose(false)} />
                </ModalHeader>
                <ModalBody>
                <Form
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <Row>
                                    <Col lg="6">
                                        <div>
                                            <FormGroup >
                                                <Label for="exampleEmail">  Customer Name <span className='required'>*</span> </Label>
                                                <input
                                                    className="form-control"
                                                    name="name"
                                                    placeholder=""
                                                    type="text"
                                                    defaultValue={data?.name || ''}
                                                    {...register('name', { required: true })}
                                                />
                                                <span className="text-danger">{errors.name && 'Customer Name is Required'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                    <Col lg='6'>
                                        <div>
                                            <FormGroup style={{ marginBottom: "8px " }}>
                                                <Label for="exampleSelect"> Address <span className='required'>*</span></Label>
                                                <input
                                                    className="form-control"
                                                    id="exampleEmail"
                                                    name="address"
                                                    placeholder=""
                                                    type="text"
                                                    defaultValue={data?.address || ''}
                                                    {...register('address', { required: true })}
                                                />
                                                <span className="text-danger">{errors.address && 'Address is Required'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <div>
                                            <FormGroup >
                                                <Label for="exampleSelect"> City <span className='required'>*</span></Label>
                                                <input
                                                    className="form-control"
                                                    id="city"
                                                    name="city"
                                                    placeholder=""
                                                    type="text"
                                                    defaultValue={data?.city || ''}
                                                    {...register('city', { required: true })}
                                                />
                                                <span className="text-danger">{errors.city && 'city is Required'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div>
                                            <FormGroup >
                                                <Label for="exampleSelect"> Country <span className='required'>*</span></Label>
                                                <select className="form-select" name="country"
                                                    defaultValue={data?.country || ''}
                                                    {...register('country', { required: true })}
                                                    onChange={e => countryChangeEvent(e.target.value, countries)}
                                                >
                                                    <option value=''>-Select Country-</option>
                                                    {countries.map((item, i) => {
                                                        return (
                                                            <option value={item.country}>{item.country}</option>
                                                        )
                                                    }
                                                    )}
                                                </select>
                                                <span className="text-danger">{errors.country && 'Country is Required'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <div>
                                            <FormGroup >
                                                <Label for="exampleSelect"> State <span className='required'>*</span></Label>
                                                <select className="form-select" name="state"
                                                    value={state}
                                                    {...register('state', { required: true })}
                                                    onChange={(e) => setState(e.target.value)}
                                                >
                                                    <option>-Select State-</option>
                                                    {
                                                        stateList && stateList.length > 0 && stateList.map(i => {
                                                            return (<option value={i}>{i}</option>)
                                                        })
                                                    }
                                                </select>
                                                <span className="text-danger">{errors.state && 'state is Required'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div>
                                            <FormGroup >
                                                <Label for="zipcode"> Zip Code <span className='required'>*</span></Label>
                                                <input
                                                    className="form-control"
                                                    id="zipcode"
                                                    name="zipcode"
                                                    type="text"
                                                    defaultValue={data?.zipcode || ''}
                                                    {...register('zipcode', { required: true, pattern: /^[0-9]{1,7}$/ })}
                                                />
                                                <span className="text-danger">{errors.zipcode?.type === 'required' && 'zipcode is Required'}</span>
                                                <span className="text-danger">{errors?.zipcode?.type === 'pattern' && 'Invalid Zipcode'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>

                                    <Col lg='6'>
                                        <div>
                                            <FormGroup style={{ marginBottom: "8px " }}>
                                                <Label for="websiteURL"> Website <span className='required'>*</span> </Label>
                                                <input
                                                    className="form-control"
                                                    id="websiteURL"
                                                    name="websiteURL"
                                                    placeholder=""
                                                    type="text"
                                                    defaultValue={data?.websiteURL || ''}
                                                    {...register('websiteURL', { required: true, pattern: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ })}
                                                />
                                                <span className="text-danger">{errors?.websiteURL?.type === 'required' && 'Website is Required'}</span>
                                                <span className="text-danger">{errors?.websiteURL?.type === 'pattern' && 'Invalid Website URL'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div>
                                            <FormGroup >
                                                <Label for="zipcode">Currency </Label>
                                                <input
                                                    className="form-control"
                                                    id="currency"
                                                    name="currency"
                                                    type="text"
                                                    value={currency}
                                                    disabled={true}
                                                    {...register('currency')}
                                                />
                                                <span className="text-danger">{errors.currency?.type === 'required' && 'Currency is Required'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg='6'>
                                        <div>
                                            <FormGroup style={{ marginBottom: "8px " }}>
                                                <Label for="exampleSelect">Contact Name <span className='required'>*</span></Label>
                                                <input
                                                    className="form-control"
                                                    id="contactName"
                                                    name="contactName"
                                                    placeholder=""
                                                    type="text"
                                                    defaultValue={data?.contactName || ''}
                                                    {...register('contactName', { required: true })}
                                                />
                                                <span className="text-danger">{errors.contactName && 'Contact Name is Required'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div>
                                            <FormGroup style={{ marginBottom: "8px " }}>
                                                <Label for="contactNumber"> Phone <span className='required'>*</span></Label>
                                                <InputGroup>
                                                    <InputGroupText>{dialCode}</InputGroupText>
                                                    <input
                                                    className="form-control"
                                                    id="contactNumber"
                                                    name="contactNumber"
                                                    placeholder=""
                                                    type="text"
                                                    prefix={dialCode}
                                                    value={phoneNumber}
                                                    {...register('contactNumber', { required: true, pattern: /^\d{7,15}$/g })}
                                                    onChange={(e)=>setPhoneNumber(e.target.value)}
                                                />
                                                </InputGroup>
                                                
                                                <span className="text-danger">{errors.contactNumber?.type === 'required' && 'Phone Number is Required'}</span>
                                                <span className="text-danger">{errors.contactNumber?.type === 'pattern' && 'Invalid Phone Number'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                </Row>
                               <Row>
                                    <div className="d-flex " style={{ marginTop: "25px" }}>
                                        {
                                            loading ? <ButtonLoader loaderText={`Updating`} color="primary" /> : <Button color="primary" >{'Update'}</Button>

                                        }
                                        <Button color="secondary" className="ml-1" onClick={() => onClose(false)}> Cancel</Button>
                                       
                                    </div>
                                </Row>
                            </Form>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default EditProfileModal;