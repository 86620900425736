import React, { Fragment, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, Row, Col, Card, CardBody, Form, FormGroup, Label, Button } from "reactstrap";
import { H3 } from "../../AbstractElements";
import { X } from "react-feather";
import { useForm } from "react-hook-form";
import fetchWrapper from "../../Common/api/fetchWrapper";
import { API_URL } from "../../Common/Config/Config";
import { toast } from "react-toastify";

const AddAMRdevice = ({ value, closeModal, data, storeList }) => {
    const [open, setOpen] = useState(value);
    const { register, formState: { errors }, reset, getValues, handleSubmit } = useForm({ defaultValues: data });
    const [storeId, setStoreId] = useState(data?.storeID);

    const onClose = (update) => {
        setOpen(false);
        closeModal(update)
    }

    const onSubmit = async(item) => {
        console.log(item);
        const options = {
            method: 'PUT', 
            body: {
                deviceID: item?.deviceID,
                storeID: item?.storeID
            }
        }
        await fetchWrapper(`${API_URL}/store/device/mapping`, options)
        .then((res) => {
            console.log(res.status, res.statusCode)
            if(res.ok){
                toast.success('Store is mapped to Device Successfully!')
                onClose(true)
            }
        }, (err) => {
            console.log(err)
            if(err.ok){
                toast.success('Store is mapped to Device Successfully!')
                onClose(true)
            }
        })
    }

    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size='lg'>
                <ModalHeader style={{ display: 'flex' }}>
                    <H3 attrH3={{ className: 'modal-title-color' }}>Device Mapping <X className="x-button" onClick={() => onClose(false)} /></H3>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg='12'>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Col lg='6'>
                                        <div style={{ marginBottom: "35px" }}>
                                            <FormGroup style={{ marginBottom: "8px" }}>
                                                <Label for="exampleSelect">Device Id </Label>
                                                <input
                                                    className="form-control"
                                                    name="device_id"
                                                    type="text"
                                                    disabled
                                                    defaultValue={data?.deviceKey}
                                                />
                                            </FormGroup>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div style={{ marginBottom: "35px" }}>
                                            <FormGroup >
                                                <Label for="exampleEmail">  Device Name </Label>
                                                <input
                                                    className="form-control"
                                                    name="device_name"
                                                    placeholder=""
                                                    type="text"
                                                    disabled
                                                    defaultValue={data?.deviceName}
                                                />
                                            </FormGroup>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                <Col lg="6">
                                        <FormGroup >
                                            <Label for="storeId"> Map Store <span className='required'>*</span></Label>
                                            <select className="form-select" name="storeId" value={storeId}
                                                {...register('storeID', { required: true })}
                                                onChange={(e)=>setStoreId(e.target.value)}
                                            >
                                                <option value=''>- Select Store -</option>
                                                {
                                                    storeList?.map((item) => <option value={item.id}>{item.name}</option>)
                                                }
                                            </select>
                                            <span className="text-danger">{errors.storeID && 'Store is Required'}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <div className="d-flex" >
                                        <Button color="primary">Update</Button>
                                        <Button color="secondary" className="ml-1" onClick={() => onClose(false)}>Cancel</Button>
                                    </div>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default AddAMRdevice;