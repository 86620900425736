import React from 'react'
import Routers from './Route';
import AnimationThemeProvider from './Common/_helper/AnimationTheme/AnimationThemeProvider';
import CustomizerProvider from './Common/_helper/Customizer/CustomizerProvider';
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';
const App = () => {


  return (
    <AnimationThemeProvider>
      <CustomizerProvider>
        <Routers />
      </CustomizerProvider>
    </AnimationThemeProvider >
  )
}
  ;

export default App;
