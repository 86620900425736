import React, { Fragment, useEffect } from "react";
import { service, factories, models } from "powerbi-client";
import fetchWrapper from '../../Common/api/fetchWrapper';
import { API_URL } from '../../Common/Config/Config';

const PowerBiReport = (props) => {
    let reportContainer;
    let reportRef = React.createRef();
    const powerbi = new service.Service(factories.hpmFactory, factories.wpmpFactory, factories.routerFactory);
    useEffect(() => {
        if (reportRef !== null) {
            reportContainer = reportRef['current'];
        }
        getEmbedReportDetails();
    }, []);

    const renderPowerBiReport = (embedToken, embedURL, reportId) => {
        const embedConfiguration = {
            type: "report",
            tokenType: models.TokenType.Embed,
            accessToken: embedToken,
            embedUrl: embedURL,
            id: reportId,
            permissions: models.Permissions.All,
            // settings: {
            //     background: models.BackgroundType.Transparent
            // }
        };
        const report = powerbi.embed(reportContainer, embedConfiguration);
    }

    const getEmbedReportDetails = async () => {
        console.log(props.request_param)
        const options = {
            method: 'POST',
            body: props.request_param
        }
        await fetchWrapper(`${API_URL}/reports/metadata`, options)
            .then((res) => {
                if (res && res.json) {
                    renderPowerBiReport(res.json.embedId, res.json.embedUrl, res.json.reportId);
                } else {
                    // console.log(res);
                }
            })
            .catch((err) => console.log(err));
    }

    return (
        <Fragment>
            <div
                id="reportContainer"
                ref={reportRef} >
                Loading the report...
            </div>
        </Fragment>
    );
};

export default PowerBiReport;