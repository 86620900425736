import React from "react";
import { Spinner } from "react-bootstrap";
import { Button } from "reactstrap";

const ButtonLoader = ({loaderText, className, color}) => {
    return (
        <Button color={color} className={className} disabled>
            <div className="d-flex">
            <i className="fa fa-spinner fa-spin mr-1"></i>
            {loaderText}...
            </div>
            
        </Button>
    )
}

export default ButtonLoader;