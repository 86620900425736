import React, { Fragment, useState, useEffect } from "react";
import { CardBody, Modal, ModalBody, ModalHeader } from "reactstrap";
import {
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Button
} from "reactstrap";
import ButtonLoader from "../../Common/Component/ButtonLoader";
import fetchWrapper from '../../Common/api/fetchWrapper';
import { API_URL } from '../../Common/Config/Config';
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";
import { X } from "react-feather";

const AddModule = (props) => {
    const [moduleData, setModuleData] = useState(props?.data)
    const [open, setOpen] = useState(false);
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [loading, setLoading] = useState(false);
    const onClose = () => {
        setOpen(false);
        props.setOpenModal(false)
    }

    useEffect(() => {
        setOpen(props.isOpen)
    }, [props.isOpen]);



    const onSubmit = async (data) => {
        setLoading(true);
        const requestOptions = {
            method: props.data?.name ? 'PUT' : 'POST',
            body: ({
                name: data.name,
            })
        };
        await fetchWrapper((props.data?.name ? `${API_URL}/module/${moduleData?.moduleId}` : `${API_URL}/module`), requestOptions)
            .then((res) => {
                const data = res.json;
                console.log(data);
                toast.success(!moduleData?.moduleId ? 'Module Added Successfully!' : 'Module Updated Successfully!')
                props.loadModule();
                onClose();
            }, (error) => {
                setLoading(false);
                console.error(error);
                toast.error("Someting went wrong")
            })
    }

    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size='md'>
                <ModalHeader>
                    {!moduleData?.name ? 'Add Module' : 'Edit Module'}
                    <X className="x-button" onClick={onClose}/>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg='12'>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Col lg='12'>
                                        <FormGroup>
                                            <Label>Module Name</Label>
                                            <input
                                                className="form-control mb-1"
                                                name="name"
                                                type='text'
                                                defaultValue={moduleData?.name || ''}
                                                {...register('name', { required: true })}
                                            />
                                            <span className="text-danger">{errors.name && errors.name.type === 'required' && 'Module Name is Required'}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <div className="d-flex" style={{ marginTop: "25px" }}>
                                        {
                                            loading ? <ButtonLoader loaderText={!moduleData?.name ? 'Add' : 'Update'} color="primary" /> : <Button color="primary" >{!moduleData?.name ? 'Add' : 'Update'}</Button>

                                        }
                                        <Button color="secondary" onClick={onClose} style={{ marginLeft: "20px" }}> Cancel</Button>
                                    </div>
                                </Row>
                            </Form>

                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default AddModule;