import React, { Fragment, useEffect, useRef, useState, useCallback } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs, H3 } from '../../AbstractElements';
import AddAMRdevice from './AddAmr';
import DeviceChart from '../../Common/Component/Chart/DeviceChart'
import AgGridTable from '../../Common/Component/AgGridTable';
import fetchWrapper from '../../Common/api/fetchWrapper';
import { API_URL } from '../../Common/Config/Config';
import { toast } from 'react-toastify';
import { FiLink } from "react-icons/fi";

let allocated = 0, totalDevice = 0, unallocated = 0;
let allocatePercent = 0, unallocatePercent = 0;

const AmrList = () => {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState();
    const [orgData, setOrgData] = useState();
    const deviceRef = useRef(null)
    const [totalData, setTotalData] = useState()
    const [allocatedData, setAllocatedData] = useState();
    const [unallocatedData, setUnallocatedData] = useState();
    const [chartData, setChartData] = useState(false)
    const [title, setTitle] = useState('Total Device List');
    const [storeData, setStoreData] = useState([]);

    const setChartSummary = () => {
        setChartData([
            {
                id: 1, name: 'Total', value: (unallocated + allocated), chart: {
                    
                    series: [100],
                    options: {
                        chart: {
                            type: 'radialBar',
                        },
                        plotOptions: {
                            radialBar: {
                                dataLabels: {
                                    name: {
                                        fontSize: '22px',
                                        color: '#000',
                                        offsetY: -10,
                                    },
                                    value: {
                                        fontSize: '25px',
                                        offsetY: 0,
                                    },
                                    total: {
                                        show: true,
                                        label: 'Total',
                                        formatter: function (w) {
                                            // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                                            return unallocated + allocated
                                        }
                                    }
                                }
                            }
                        },
                        fill: {
                            colors: '#132b5e'
                        },
                        labels: ['Total'],
                    },
                }
            },
            {
                id: 2, name: 'Allocated', value: allocated, chart: {
                    series: [allocatePercent],
                    options: {
                        chart: {
                            type: 'radialBar',
                        },
                        plotOptions: {
                            radialBar: {
                                dataLabels: {
                                    name: {
                                        fontSize: '22px',
                                        color: '#000',
                                        offsetY: -10,
                                    },
                                    value: {
                                        fontSize: '25px',
                                        offsetY: 0,
                                    },
                                    total: {
                                        show: true,
                                        label: 'Allocated',
                                        formatter: function (w) {
                                            // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                                            return allocated
                                        }
                                    }
                                }
                            }
                        },
                        fill: {
                            colors: '#c8228f'
                        },
                        labels: ['Allocated'],
                    },
                }
            },
            {
                id: 3, name: 'Un-Allocated', value: unallocated, chart: {
                    series: [unallocatePercent],
                    options: {
                        chart: {
                            type: 'radialBar',
                        },
                        plotOptions: {
                            radialBar: {
                                dataLabels: {
                                    name: {
                                        fontSize: '22px',
                                        color: '#000',
                                        offsetY: -10,
                                    },
                                    value: {
                                        fontSize: '25px',
                                        offsetY: 0,
                                    },
                                    total: {
                                        show: true,
                                        label: 'Un-Allocated',
                                        formatter: function (w) {
                                            // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                                            return unallocated
                                        }
                                    }
                                }
                            }
                        },
                        fill: {
                            colors: '#db600e'
                        },
                        labels: ['Un-Allocated'],
                    },
                }
            }
        ])
    }

    const loadData = async () => {
        deviceRef?.current?.api?.showLoadingOverlay();
        await fetchWrapper(`${API_URL}/store/device/details`)
            .then((res) => {
                setData(res.json)
                let deviceData = res.json
                totalDevice = res.json?.length
                let noOfAllocated = deviceData?.filter((i) => i.storeName !== null)
                let noOfUnallocated = deviceData?.filter((i) => i.storeName === null)
                allocated = noOfAllocated?.length
                unallocated = noOfUnallocated?.length
                allocatePercent = ((allocated * 100) / totalDevice);
                unallocatePercent = ((unallocated * 100) / totalDevice);
                setTotalData(res.json)
                setAllocatedData(noOfAllocated)
                setUnallocatedData(noOfUnallocated)
                setChartSummary();
                deviceRef?.current?.api?.hideOverlay(); 
            })
            .catch((err) => {
                console.error(err)
                deviceRef?.current?.api?.hideOverlay();
            })
    }

    const getAllStore = async() => {
        await fetchWrapper(`${API_URL}/store`)
        .then((res) => {
            setStoreData(res.json)
        }, (err) => {console.log(err)})
    }

    const loadOrgData = async (item) => {
        setOrgData(item)
        setOpen(true);
    }

    const handleStatus = async (data) => {
        let updateStatus = data.active === 'TRUE' ? 'FALSE' : 'TRUE';
        const options = {
            method: 'PUT'
        }
        console.log(data)
        await fetchWrapper(`${API_URL}/store/device/status/${data?.id}/${updateStatus}`, options)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    if (res?.json?.active === 'FALSE') {
                        toast.error('Device has been disabled!')
                    } else {
                        toast.success('Device has been enabled!')
                    }
                }
            })
            .catch(err => console.log(err))
    }
    const columnData = [
        {
            headerName: 'Device ID',
            field: 'deviceKey',
            filter: true,
            sortable: true,
            flex: 2,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Device Name',
            field: 'deviceName',
            filter: true,
            sortable: true,
            flex: 2,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Device Type',
            field: 'deviceTypeName',
            filter: true,
            sortable: true,
            flex: 2,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Store Name',
            field: 'storeName',
            filter: true,
            sortable: true,
            flex: 2,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Status',
            filter: false,
            width: 100,
            sortable: true,
            suppressMovable: true,
            cellRenderer: (params) => {
                return (
                    <div className='mr-21'>
                        <label className="switch">
                            <input type="checkbox" id="togBtn" 
                            defaultChecked={params.data.active === 'TRUE' ? true : false} 
                            disabled={!params.data?.id}
                            onChange={()=>handleStatus(params.data)}/>
                            <div className="slider round">
                                <span className="on"></span>
                                <span className="off"></span>
                            </div>
                        </label>
                    </div>
                )
            }
        },
        {
            headerName: 'Map',
            width: 90,
            cellRenderer: params => {
                return (
                    <FiLink className={`pin-icon`} onClick={() => loadOrgData(params.data)} width={30} height={30} />
                )
            },
            suppressMovable: true,
        },
        
    ]
    
    const onSelectCard = (item) => {
        if (item.id === 1) {
            setData(totalData);
            setTitle('Total Device List')
        } else if(item.id === 2) {
            setData(allocatedData);
            setTitle('Allocated Device List')
        } else if(item.id === 3) {
            setData(unallocatedData)
            setTitle('Un-Allocated Device List')
        }
    }

    const onGridReady = useCallback(async (params) => {
        loadData();
        getAllStore();
    })

    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <div className='d-flex'>
                            <Breadcrumbs mainTitle="Device" parent="Device Management" title="List" />
                        </div>
                        {chartData && <DeviceChart className="mb-2" selectedCard={onSelectCard} deviceSummary={chartData} default={{ id: 1, name: 'Total', value: '100' }} /> }
                        <Card className="border-bottom-light  newtable">
                            <CardBody className='data-tables'>
                                <H3>{title}</H3>
                                <div className="">
                                    <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                        <AgGridTable
                                            columnData={columnData}
                                            tableData={data}
                                            agRef={deviceRef}
                                            onGridReady={onGridReady}
                                        />
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    {open && <AddAMRdevice value={open} closeModal={(e) => { e && loadData(); setOpen(false) }} storeList={storeData} data={orgData} />}
                </Row>
            </Container>
        </Fragment>
    );

};

export default AmrList;