import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, CardFooter, Button } from 'reactstrap';
import { Breadcrumbs, H3 } from '../../../AbstractElements';
import { Nav } from "react-bootstrap";
import Select from 'react-select';
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import ButtonLoader from '../ButtonLoader';
import LayoutLoader from '../LayoutLoader/LayoutLoader';
import { toast } from 'react-toastify';

let selectedOrgId;
let moduleList;
const ModuleSubscribe = () => {
  const [tableData, setTableData] = useState([]);
  const [organization, setOrganizations] = useState();
  const [isYearlyChecked, setIsYearlyChecked] = useState(true);
  const [loading, setLoading] = useState(false);

  const loadCustomerData = async () => {
    await fetchWrapper(`${API_URL}/customer`)
      .then((res) => {
        console.log(res);
        const customerOption = res.json.map(item => {
          return {
            label: item.name,
            value: item.customerId
          }
        })
        setOrganizations(customerOption);
      }, (err) => {
        console.log(err);
      })
  }

  const onChangeOrganization = async (event) => {
    setTableData([]);
    selectedOrgId = event.value;
    getSubscription();
  }

  const getSubscription = async () => {
    setLoading(true);
    await fetchWrapper(`${API_URL}/licence/customer/${selectedOrgId}`)
      .then((res) => {
        console.log(res);
        if (res.json.length) {
          moduleList = res.json;
          setTableData(res.json);
          setLoading(false)
        }
      }, (err) => {
        setLoading(false);
        console.log(err);
      })
  }

  const submitSubscription = async () => {
    setLoading(true);
    const request = JSON.parse(JSON.stringify(tableData)).reduce((newArr, item) => {
      const findExisting = moduleList.find(mod => mod.moduleID === item.moduleID);
      if (findExisting?.isEnabled !== item?.isEnabledfin || findExisting?.subscriptionType !== item?.subscriptionType) {
        newArr.push({ moduleID: item.moduleID, subscriptionType: item.subscriptionType, customerID: selectedOrgId, isEnabled: item.isEnabled === 'TRUE' ? item.isEnabled : 'FALSE' });
      }
      return newArr;
    }, []);
    console.log(request);
    const options = {
      method: 'POST',
      body: request
    }
    await fetchWrapper(`${API_URL}/licence`, options)
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          getSubscription();
          toast.success('Module subscription updated Successfully!')
        }
      }).catch((err) => {
        setLoading(false)
        if (err.status === 200) {
          getSubscription();
          toast.success('Module subscription updated Successfully!')
        }
        console.log(err)
      });
  }


  const handleOnChangeSubType = (event, moduleId, initial) => {
    const tempData = JSON.parse(JSON.stringify(tableData));
    const udpatedList = tempData.map((item) => {
      if (item.moduleID === moduleId) {
        item.subscriptionType = event;
        item.isEnabled = initial === true ? 'TRUE' : item.isEnabled;
      }
      return item;
    })
    setTableData([...udpatedList]);
  }

  const handleOnChange = (event, moduleID) => {
    const tempData = JSON.parse(JSON.stringify(tableData));
    setTableData([]);
    const udpatedList = tempData.map((item) => {
      if (item.moduleID === moduleID) {
        item.isEnabled = event.target.checked ? "TRUE" : "FALSE";
      }
      return item;
    })
    setTableData([...udpatedList]);
    if (event.target.checked) {
      handleOnChangeSubType('MONTHLY', moduleID, true)
    }
  }


  useEffect(() => {
    loadCustomerData();
  }, [])

  return (
    <Fragment>
      <LayoutLoader />
      <div className="page-title pb-0">
        <Row>
          <Col md='8'>
            <div className="d-flex">
              <Breadcrumbs mainTitle="" parent="Licence Management" title="" />
            </div>
          </Col>
          <Col md="4" className="mb-hfrm">
            <Select
              options={organization}
              placeholder="Select Customer"
              styles={{
                option: (base, { isFocused, isSelected }) => {
                  return {
                    ...base,
                    background: isSelected ? '#003868' : isFocused ? '#0038681a' : undefined,
                    color: isSelected ? '#fff' : isFocused ? '#000' : undefined
                  }
                }
              }}
              onChange={(e) => onChangeOrganization(e)}
            />
          </Col>
        </Row>
      </div>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card className="border-bottom-light  newtable">
              <CardBody className='data-tables responsive-view'>
                <Row>
                  <Col md="8"><H3>Module Subscription</H3></Col>
                </Row>
                <table className='table mtop-1' id="customer-table">
                  <thead>
                    <tr className='table-header-bg'>
                      <th className='text-left'>Module Name</th>
                      <th className='text-left'>
                        Enable / Disable
                      </th>
                      <th className='text-right' style={{ width: '12rem' }}>
                        Subscription Type
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData && tableData.length ? tableData.map((item, index) =>
                      <tr key={index}>
                        <td>{item.moduleName}</td>
                        <td >
                          <div className='pl-2'>
                            <label className="switch">
                              <input type="checkbox" checked={item.isEnabled === 'TRUE'} onChange={(event) => handleOnChange(event, item.moduleID)} />
                              <div className="slider round">
                                <span className="on"></span>
                                <span className="off"></span>
                              </div>
                            </label>
                          </div>
                        </td>
                        <td>
                          <Nav as="ul" className="nav nav-tabs" id="subscription-type" role="tablist">
                            <Nav.Item as="li" className="nav-item" role="presentation">
                              <Nav.Link as="button" disabled={item.isEnabled === 'FALSE'} type="button" onClick={() => handleOnChangeSubType('MONTHLY', item.moduleID)} className={item.subscriptionType === 'MONTHLY' ? 'active' : ''} eventKey="Monthly">Monthly</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" className="nav-item" >
                              <Nav.Link as="button" disabled={item.isEnabled === 'FALSE'} type="button" onClick={() => handleOnChangeSubType('ANNUALLY', item.moduleID)} className={item.subscriptionType === 'ANNUALLY' ? 'active' : ''} eventKey="Annually">Annually</Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </td>
                      </tr>
                    ) : <tr>
                      <td colSpan={3}>
                        <h4 style={{ textAlign: 'center' }}>Kindly select the customer.</h4>
                      </td>
                    </tr>
                    }
                  </tbody>
                </table>
              </CardBody>
              {tableData && tableData.length ?
                <div>
                  {
                    loading ? <ButtonLoader className='mb-3 ml-1' loaderText={'Submitting'} color='secondary' style={{ width: '10rem' }} />
                      : <Button color='secondary' className='mb-3 ml-1'
                        // disabled={!tableData.some(item => item.subscriptionType && item.isEnabled === 'TRUE')} 
                        onClick={submitSubscription} style={{ width: '10rem' }}>Submit</Button>
                  }
                </div> : <></>}
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};

export default ModuleSubscribe;