import React, { Suspense, Fragment, useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Callback from '../Common/Auth/Callback';
import Loader from '../Common/Layout/Layout';
import LayoutRoutes from './LayoutRoutes';
import PrivateRoute from './PrivateRoute'
import { authRoutes } from './AuthRoutes';
import LoginComponent from '../Common/Component/Login/Login';
import VerificationEmail from '../Common/Component/Login/VerificationEmail';
import Wrapper from './Wrapper';
import Notification from '../Common/Component/Login/Notification';
import SessionExpiry from '../Common/Component/Login/SessionExpiry';
import ForgotPwd from '../Common/Component/Login/ForgotPassword';
import ChangePwd from '../Common/Component/Login/ChangePassword';

const Routers = () => {

        const [authenticated, setAuthenticated] = useState(true);
        const jwt_token = sessionStorage.getItem('accessToken');
        useEffect(() => {
                // let abortController = new AbortController();
                // setAuthenticated(sessionStorage.getItem('accessToken') ? true : false);
                // console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed'];
                // console.disableYellowBox = true;
                // return () => {
                //         abortController.abort();
                // };
        }, []);
        const userType = sessionStorage.getItem('userType');
        return (
                <Fragment>
                        <BrowserRouter basename='/'>
                                <Wrapper>
                                        <Suspense fallback={<Loader />}>
                                                <Routes>
                                                        <Route path={'/'} element={<PrivateRoute />}>
                                                                <Route path={`/*`} element={<LayoutRoutes />} />
                                                        </Route>
                                                        <Route path={`${process.env.PUBLIC_URL}/callback`} render={() => <Callback />} />

                                                        <Route exact path={`${process.env.PUBLIC_URL}/login`} element={<LoginComponent />} />
                                                        <Route exact path={`${process.env.PUBLIC_URL}/verification-email`} element={<VerificationEmail />} />
                                                        <Route exact path={`${process.env.PUBLIC_URL}/notification`} element={<Notification />} />
                                                        <Route exact path={`${process.env.PUBLIC_URL}/session-expired`} element={<SessionExpiry />} />
                                                        <Route exact path={`${process.env.PUBLIC_URL}/forgot-password`} element={<ForgotPwd />} />
                                                        <Route exact path={`${process.env.PUBLIC_URL}/change-password`} element={<ChangePwd />} />

                                                        {authRoutes.map(({ path, Component }, i) => (
                                                                <Route path={path} element={Component} key={i} />
                                                        ))}
                                                </Routes>
                                        </Suspense>
                                </Wrapper>
                                
                        </BrowserRouter>
                </Fragment>
        );
};
export default Routers;