import { PROJECTS_DATA } from "../Constant"

const projectArray = (state = [], action) => {
    switch (action.type) {
        case PROJECTS_DATA:
            return {
                ...state,
                projectArray: action.payload
            }
        default: {
            return state;
        }
    }
}

export default projectArray;