import React, { Fragment, useState, useEffect } from "react";
import { InputGroup, InputGroupText, Modal, ModalBody, ModalHeader } from "reactstrap";
import { H3 } from "../../AbstractElements";
import { X } from "react-feather";
import {
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Button
} from "reactstrap";
import { useForm } from "react-hook-form";
import fetchWrapper from "../../Common/api/fetchWrapper";
import { API_URL } from "../../Common/Config/Config";
import { toast } from "react-toastify";
import { currencySymbol } from "../../Common/api/currencyData";

const AddInventory = ({ value, closeModal, data, action, setUpdate }) => {
    const [open, setOpen] = useState(value);
    const { register, formState: { errors }, reset, getValues, handleSubmit } = useForm({ defaultValues: data });
    const [storeData, setStoreData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [storeId, setStoreId] = useState(data?.storeId);
    const [categoryId, setCategoryId] = useState(data?.categoryId);
    const userCurrency = sessionStorage.getItem('userCurrency')

    const getStoreData = async () => {
        await fetchWrapper(`${API_URL}/store/type/JEWELLERY`)
            .then((res) => {
                setStoreData(res.json);
            }, (err) => {
                console.log(err)
            })
    }

    const getCategoryData = async () => {
        await fetchWrapper(`${API_URL}/productcategory/type/JEWELLERY`)
            .then((res) => {
                setCategoryData(res.json)
            }, (err) => {
                console.log(err)
            })
    }

    const onClose = (update) => {
        setOpen(false);
        closeModal(update)
    }

    useEffect(() => {
        getStoreData()
        getCategoryData()
    }, [])

    const onSubmit = async (data) => {
        let url = action === 'Add' ? `/invjewellery` : `/invjewellery/${data?.id}`;
        const options = {
            method: data?.id ? 'PUT' : 'POST',
            body: data
        }
        await fetchWrapper(`${API_URL}${url}`, options)
            .then((res) => {
                if (res.status === 200) {
                    onClose(true)
                    setUpdate(true)
                    toast.success('Inventory Added Succussfully')
                }
            }, (err) => {
                console.log(err)
                onClose(false)
                toast.error('Something went wrong!')
            })
    }

    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size='xl'>
                <ModalHeader style={{ display: 'flex' }}>
                    <H3 attrH3={{ className: 'modal-title-color' }}>{data ? 'Update Inventory' : 'Add Inventory'} <X className="x-button" onClick={() => onClose(false)} /></H3>
                </ModalHeader>
                <ModalBody className="model-scroll">
                    <Fragment>
                        <Row>
                            <Col lg='12'>
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                    {sessionStorage.getItem('userType') !== 'STORE_USER' ?
                                        <Col lg='4'>
                                            <div style={{ marginBottom: "35px" }}>
                                                <FormGroup style={{ marginBottom: "8px" }}>
                                                    <Label for="skutext">Store <span className='required'>*</span></Label>
                                                    <select
                                                        className="form-select"
                                                        name="storeId"
                                                        value={storeId}
                                                        {...register('storeId', { required: true })}
                                                        onChange={(e) => setStoreId(e.target.value)}
                                                    >
                                                        <option value={''}>- Select Store -</option>
                                                        {
                                                            storeData?.map((item) => <option value={item?.id}>{item?.name}</option>)
                                                        }
                                                    </select>
                                                    <span className="text-danger">{errors?.storeId && 'Store is Required'}</span>
                                                </FormGroup>
                                            </div>
                                        </Col> : <></>}
                                        <Col lg='4'>
                                            <div style={{ marginBottom: "35px" }}>
                                                <FormGroup style={{ marginBottom: "8px " }}>
                                                    <Label for="category">Product Category <span className='required'>*</span></Label>
                                                    <select
                                                        className="form-select"
                                                        name="categoryId"
                                                        value={categoryId}
                                                        {...register('categoryId', { required: true })}
                                                        onChange={(e) => setCategoryId(e.target.value)}
                                                    >
                                                        <option value={''}>- Select Category -</option>
                                                        {
                                                            categoryData?.map((item) => <option value={item?.id}>{item?.name}</option>)
                                                        }
                                                    </select>
                                                    <span className="text-danger">{errors?.categoryId && 'Product Category is Required'}</span>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col lg="4">
                                            <div style={{ marginBottom: "35px" }}>
                                                <FormGroup >
                                                    <Label for="plant">Brand Name <span className='required'>*</span></Label>
                                                    <input
                                                        className="form-control"
                                                        name="brandName"
                                                        placeholder=""
                                                        type="text"
                                                        defaultValue={data?.brandName}
                                                        {...register('brandName', { required: true })}
                                                    />
                                                    <span className="text-danger">{errors?.brandName && 'Brand Name is Required'}</span>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="4">
                                            <div style={{ marginBottom: "35px" }}>
                                                <FormGroup >
                                                    <Label for="plant">SKU ID <span className='required'>*</span></Label>
                                                    <input
                                                        className="form-control"
                                                        name="skuId"
                                                        placeholder=""
                                                        type="text"
                                                        defaultValue={data?.skuId}
                                                        {...register('skuId', { required: true })}
                                                    />
                                                    <span className="text-danger">{errors?.skuId && 'SKU ID is Required'}</span>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col lg="4">
                                            <div style={{ marginBottom: "35px" }}>
                                                <FormGroup >
                                                    <Label for="plant">Supplier ID <span className='required'>*</span></Label>
                                                    <input
                                                        className="form-control"
                                                        name="supplierId"
                                                        placeholder=""
                                                        type="text"
                                                        defaultValue={data?.supplierId}
                                                        {...register('supplierId', { required: true })}
                                                    />
                                                    <span className="text-danger">{errors?.supplierId && 'Supplier ID is Required'}</span>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col lg="4">
                                            <div style={{ marginBottom: "35px" }}>
                                                <FormGroup >
                                                    <Label for="plant">Product Name <span className='required'>*</span></Label>
                                                    <input
                                                        className="form-control"
                                                        name="productName"
                                                        placeholder=""
                                                        type="text"
                                                        defaultValue={data?.productName}
                                                        {...register('productName', { required: true })}
                                                    />
                                                    <span className="text-danger">{errors?.productName && 'Product Name is Required'}</span>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="4">
                                            <div style={{ marginBottom: "35px" }}>
                                                <FormGroup >
                                                    <Label for="plant">Product Unit <span className='required'>*</span></Label>
                                                    <input
                                                        className="form-control"
                                                        name="productUnit"
                                                        placeholder=""
                                                        type="text"
                                                        defaultValue={data?.productUnit}
                                                        {...register('productUnit', { required: true })}
                                                    />
                                                    <span className="text-danger">{errors?.productUnit && 'Product Unit is Required'}</span>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col lg="4">
                                            <div style={{ marginBottom: "35px" }}>
                                                <FormGroup >
                                                    <Label for="plant">Product Cost <span className='required'>*</span></Label>
                                                    <InputGroup>
                                                        <InputGroupText>{currencySymbol[userCurrency]}</InputGroupText>
                                                        <input
                                                            className="form-control"
                                                            name="productCost"
                                                            placeholder=""
                                                            type="text"
                                                            defaultValue={data?.productCost}
                                                            {...register('productCost', { required: true })}
                                                        />
                                                    </InputGroup>

                                                    <span className="text-danger">{errors?.productCost && 'Product Cost is Required'}</span>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col lg="4">
                                            <div style={{ marginBottom: "35px" }}>
                                                <FormGroup >
                                                    <Label for="plant">Product Price <span className='required'>*</span></Label>
                                                    <InputGroup>
                                                        <InputGroupText>{currencySymbol[userCurrency]}</InputGroupText>
                                                        <input
                                                            className="form-control"
                                                            name="productPrice"
                                                            placeholder=""
                                                            type="text"
                                                            defaultValue={data?.productPrice}
                                                            {...register('productPrice', { required: true })}
                                                        />
                                                    </InputGroup>

                                                    <span className="text-danger">{errors?.productPrice && 'Product Price is Required'}</span>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="4">
                                            <div style={{ marginBottom: "35px" }}>
                                                <FormGroup >
                                                    <Label for="plant">Availability <span className='required'>*</span></Label>
                                                    <input
                                                        className="form-control"
                                                        name="availability"
                                                        placeholder=""
                                                        type="number"
                                                        defaultValue={data?.availability}
                                                        {...register('availability', { required: true, min: -1 })}
                                                    />
                                                    <span className="text-danger">{errors?.availability?.type === 'required' && 'Availability is Required'}</span>
                                                    <span className="text-danger">{errors?.availability?.type === 'min' && 'Availability value should be positive'}</span>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col lg="4">
                                            <div style={{ marginBottom: "35px" }}>
                                                <FormGroup >
                                                    <Label for="plant">Safety Stock <span className='required'>*</span></Label>
                                                    <input
                                                        className="form-control"
                                                        name="safetyStock"
                                                        placeholder=""
                                                        type="text"
                                                        defaultValue={data?.safetyStock}
                                                        {...register('safetyStock', { required: true, min: -1 })}
                                                    />
                                                    <span className="text-danger">{errors?.safetyStock?.type === 'required' && 'Safety Stock is Required'}</span>
                                                    <span className="text-danger">{errors?.safetyStock?.type === 'min' && 'Safety Stock value should be positive'}</span>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col lg="4">
                                            <div style={{ marginBottom: "35px" }}>
                                                <FormGroup >
                                                    <Label for="plant">SOH Quantity <span className='required'>*</span></Label>
                                                    <input
                                                        className="form-control"
                                                        name="sohQuantity"
                                                        placeholder=""
                                                        type="text"
                                                        defaultValue={data?.sohQuantity}
                                                        {...register('sohQuantity', { required: true, min: -1 })}
                                                    />
                                                    <span className="text-danger">{errors?.sohQuantity?.type === 'required' && 'SOH Quantity is Required'}</span>
                                                    <span className="text-danger">{errors?.sohQuantity?.type === 'min' && 'SOH Quantity value should be positive'}</span>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>


                                        <Col lg="4">
                                            <div style={{ marginBottom: "35px" }}>
                                                <FormGroup >
                                                    <Label for="plant">Reorder Level <span className='required'>*</span></Label>
                                                    <input
                                                        className="form-control"
                                                        name="reorderLevel"
                                                        placeholder=""
                                                        type="text"
                                                        defaultValue={data?.reorderLevel}
                                                        {...register('reorderLevel', { required: true, min: -1 })}
                                                    />
                                                    <span className="text-danger">{errors?.reorderLevel?.type === 'required' && 'Reorder Level is Required'}</span>
                                                    <span className="text-danger">{errors?.reorderLevel?.type === 'min' && 'Reorder Level value should be positive'}</span>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col lg="4">
                                            <div style={{ marginBottom: "35px" }}>
                                                <FormGroup >
                                                    <Label for="plant">Condition <span className='required'>*</span></Label>
                                                    <input
                                                        className="form-control"
                                                        name="condition"
                                                        placeholder=""
                                                        type="text"
                                                        defaultValue={data?.condition}
                                                        {...register('condition', { required: true })}
                                                    />
                                                    <span className="text-danger">{errors?.condition && 'Condition is Required'}</span>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col lg="4">
                                            <div style={{ marginBottom: "35px" }}>
                                                <FormGroup >
                                                    <Label for="plant">Restocking Lead Time <span className='required'>*</span></Label>
                                                    <input
                                                        className="form-control"
                                                        name="restockingLeadTime"
                                                        placeholder=""
                                                        type="text"
                                                        defaultValue={data?.restockingLeadTime}
                                                        {...register('restockingLeadTime', { required: true })}
                                                    />
                                                    <span className="text-danger">{errors?.restockingLeadTime && 'Restocking Lead Time is Required'}</span>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>


                                        <Col lg="4">
                                            <div style={{ marginBottom: "35px" }}>
                                                <FormGroup >
                                                    <Label for="plant">Manufacturer <span className='required'>*</span></Label>
                                                    <input
                                                        className="form-control"
                                                        name="manufacturer"
                                                        placeholder=""
                                                        type="text"
                                                        defaultValue={data?.manufacturer}
                                                        {...register('manufacturer', { required: true })}
                                                    />
                                                    <span className="text-danger">{errors?.manufacturer && 'Manufacturer is Required'}</span>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col lg="4">
                                            <div style={{ marginBottom: "35px" }}>
                                                <FormGroup >
                                                    <Label for="plant">Reorder Point <span className='required'>*</span></Label>
                                                    <input
                                                        className="form-control"
                                                        name="reorderPoint"
                                                        placeholder=""
                                                        type="text"
                                                        defaultValue={data?.reorderPoint}
                                                        {...register('reorderPoint', { required: true, min: -1 })}
                                                    />
                                                    <span className="text-danger">{errors?.reorderPoint?.type === 'required' && 'Reorder Point is Required'}</span>
                                                    <span className="text-danger">{errors?.reorderPoint?.type === 'min' && 'Reorder Point value should be positive'}</span>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col lg="4">
                                            <div style={{ marginBottom: "35px" }}>
                                                <FormGroup >
                                                    <Label for="plant">Warranty Information <span className='required'>*</span></Label>
                                                    <input
                                                        className="form-control"
                                                        name="warrantyInformation"
                                                        placeholder=""
                                                        type="text"
                                                        defaultValue={data?.warrantyInformation}
                                                        {...register('warrantyInformation', { required: true })}
                                                    />
                                                    <span className="text-danger">{errors?.warrantyInformation && 'Warranty Information is Required'}</span>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="4">
                                            <div style={{ marginBottom: "35px" }}>
                                                <FormGroup >
                                                    <Label for="plant">Importer <span className='required'>*</span></Label>
                                                    <input
                                                        className="form-control"
                                                        name="importer"
                                                        placeholder=""
                                                        type="text"
                                                        defaultValue={data?.importer}
                                                        {...register('importer', { required: true })}
                                                    />
                                                    <span className="text-danger">{errors?.importer && 'Importer is Required'}</span>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col lg="4">
                                            <div style={{ marginBottom: "35px" }}>
                                                <FormGroup >
                                                    <Label for="plant">Packer <span className='required'>*</span></Label>
                                                    <input
                                                        className="form-control"
                                                        name="packer"
                                                        placeholder=""
                                                        type="text"
                                                        defaultValue={data?.packer}
                                                        {...register('packer', { required: true })}
                                                    />
                                                    <span className="text-danger">{errors?.packer && 'Packer is Required'}</span>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col lg="4">
                                            <div style={{ marginBottom: "35px" }}>
                                                <FormGroup >
                                                    <Label for="plant">Included Components <span className='required'>*</span></Label>
                                                    <input
                                                        className="form-control"
                                                        name="includedComponents"
                                                        placeholder=""
                                                        type="text"
                                                        defaultValue={data?.includedComponents}
                                                        {...register('includedComponents', { required: true })}
                                                    />
                                                    <span className="text-danger">{errors?.includedComponents && 'Included Components is Required'}</span>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="4">
                                            <div style={{ marginBottom: "35px" }}>
                                                <FormGroup >
                                                    <Label for="plant">RFID <span className='required'>*</span></Label>
                                                    <input
                                                        className="form-control"
                                                        name="rfid"
                                                        placeholder=""
                                                        type="text"
                                                        defaultValue={data?.rfid}
                                                        {...register('rfid', { required: true })}
                                                    />
                                                    <span className="text-danger">{errors?.rfid && 'RFID is Required'}</span>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col lg="4">
                                            <div style={{ marginBottom: "35px" }}>
                                                <FormGroup >
                                                    <Label for="plant">Metal <span className='required'>*</span></Label>
                                                    <input
                                                        className="form-control"
                                                        name="metal"
                                                        placeholder=""
                                                        type="text"
                                                        defaultValue={data?.metal}
                                                        {...register('metal', { required: true })}
                                                    />
                                                    <span className="text-danger">{errors?.metal && 'Metal is Required'}</span>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col lg="4">
                                            <div style={{ marginBottom: "35px" }}>
                                                <FormGroup >
                                                    <Label for="plant">Metal Component <span className='required'>*</span></Label>
                                                    <input
                                                        className="form-control"
                                                        name="metalComponent"
                                                        placeholder=""
                                                        type="text"
                                                        defaultValue={data?.metalComponent}
                                                        {...register('metalComponent', { required: true })}
                                                    />
                                                    <span className="text-danger">{errors?.metalComponent && 'Metal Component is Required'}</span>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="4">
                                            <div style={{ marginBottom: "35px" }}>
                                                <FormGroup >
                                                    <Label for="plant">Metal Rate (per Gram) <span className='required'>*</span></Label>
                                                    <InputGroup>
                                                        <InputGroupText>{currencySymbol[userCurrency]}</InputGroupText>
                                                        <input
                                                            className="form-control"
                                                            name="metalRate"
                                                            placeholder=""
                                                            type="text"
                                                            defaultValue={data?.metalRate}
                                                            {...register('metalRate', {
                                                                required: true,
                                                                pattern: {
                                                                    value: /^(0|[1-9]\d*)(\.\d+)?$/
                                                                }
                                                            })}
                                                        />
                                                    </InputGroup>

                                                    <span className="text-danger">{errors?.metalRate?.type === 'required' && 'Metal Rate is Required'}</span>
                                                    <span className="text-danger">{errors?.metalRate?.type === 'pattern' && 'Metal Rate is Invaliid'}</span>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col lg="4">
                                            <div style={{ marginBottom: "35px" }}>
                                                <FormGroup >
                                                    <Label for="plant">Metal Weight (in Grams) <span className='required'>*</span></Label>
                                                    <input
                                                        className="form-control"
                                                        name="metalWeight"
                                                        placeholder=""
                                                        type="text"
                                                        defaultValue={data?.metalWeight}
                                                        {...register('metalWeight', {
                                                            required: true,
                                                            pattern: {
                                                                value: /^(0|[1-9]\d*)(\.\d+)?$/
                                                            }
                                                        })}
                                                    />
                                                    <span className="text-danger">{errors?.metalWeight?.type === 'required' && 'Metal Weight is Required'}</span>
                                                    <span className="text-danger">{errors?.metalWeight?.type === 'pattern' && 'Metal Weight is Invaliid'}</span>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col lg="4">
                                            <div style={{ marginBottom: "35px" }}>
                                                <FormGroup >
                                                    <Label for="plant">Stone Weight (in Grams) </Label>
                                                    <input
                                                        className="form-control"
                                                        name="stoneWeight"
                                                        placeholder=""
                                                        type="text"
                                                        defaultValue={data?.stoneWeight}
                                                        {...register('stoneWeight', {
                                                            pattern: {
                                                                value: /^(0|[1-9]\d*)(\.\d+)?$/
                                                            }
                                                        })}
                                                    />
                                                    <span className="text-danger">{errors?.stoneWeight?.type === 'required' && 'Stone Weight is Required'}</span>
                                                    <span className="text-danger">{errors?.stoneWeight?.type === 'pattern' && 'Stone Weight is Invaliid'}</span>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="4">
                                            <div style={{ marginBottom: "35px" }}>
                                                <FormGroup >
                                                    <Label for="plant">Stone Rate (per Gram) </Label>
                                                    <InputGroup>
                                                        <InputGroupText>{currencySymbol[userCurrency]}</InputGroupText>
                                                        <input
                                                            className="form-control"
                                                            name="stoneRate"
                                                            placeholder=""
                                                            type="text"
                                                            defaultValue={data?.stoneRate}
                                                            {...register('stoneRate', {
                                                                pattern: {
                                                                    value: /^(0|[1-9]\d*)(\.\d+)?$/
                                                                }
                                                            })}
                                                        />
                                                    </InputGroup>

                                                    <span className="text-danger">{errors?.stoneRate?.type === 'required' && 'Stone Rate is Required'}</span>
                                                    <span className="text-danger">{errors?.stoneRate?.type === 'pattern' && 'Stone Rate is Invaliid'}</span>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col lg="4">
                                            <div style={{ marginBottom: "35px" }}>
                                                <FormGroup >
                                                    <Label for="plant">Metal Total Value <span className='required'>*</span></Label>
                                                    <InputGroup>
                                                        <InputGroupText>{currencySymbol[userCurrency]}</InputGroupText>
                                                        <input
                                                            className="form-control"
                                                            name="metalTotalValue"
                                                            placeholder=""
                                                            type="text"
                                                            defaultValue={data?.metalTotalValue}
                                                            {...register('metalTotalValue', {
                                                                required: true,
                                                                pattern: {
                                                                    value: /^(0|[1-9]\d*)(\.\d+)?$/
                                                                }
                                                            })}
                                                        />
                                                    </InputGroup>

                                                    <span className="text-danger">{errors?.metalTotalValue?.type === 'required' && 'Metal Total Value is Required'}</span>
                                                    <span className="text-danger">{errors?.metalTotalValue?.type === 'pattern' && 'Metal Total is Invaliid'}</span>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col lg="4">
                                            <div style={{ marginBottom: "35px" }}>
                                                <FormGroup >
                                                    <Label for="plant">Stone Total Value </Label>
                                                    <InputGroup>
                                                        <InputGroupText>{currencySymbol[userCurrency]}</InputGroupText>
                                                        <input
                                                            className="form-control"
                                                            name="stoneTotalValue"
                                                            placeholder=""
                                                            type="text"
                                                            defaultValue={data?.stoneTotalValue}
                                                            {...register('stoneTotalValue', {
                                                                pattern: {
                                                                    value: /^(0|[1-9]\d*)(\.\d+)?$/
                                                                }
                                                            })}
                                                        />
                                                    </InputGroup>

                                                    <span className="text-danger">{errors?.stoneTotalValue?.type === 'required' && 'Stone Total Value is Required'}</span>
                                                    <span className="text-danger">{errors?.stoneTotalValue?.type === 'pattern' && 'Stone Total Value is Invaliid'}</span>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="4">
                                            <div style={{ marginBottom: "35px" }}>
                                                <FormGroup >
                                                    <Label for="plant">Discount <span className='required'>*</span></Label>
                                                    <InputGroup>
                                                        <InputGroupText>{currencySymbol[userCurrency]}</InputGroupText>
                                                        <input
                                                            className="form-control"
                                                            name="discount"
                                                            placeholder=""
                                                            type="text"
                                                            defaultValue={data?.discount}
                                                            {...register('discount', { required: true })}
                                                        />
                                                    </InputGroup>

                                                    <span className="text-danger">{errors?.discount && 'Discount is Required'}</span>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col lg="4">
                                            <div style={{ marginBottom: "35px" }}>
                                                <FormGroup >
                                                    <Label for="plant">Final Value <span className='required'>*</span></Label>
                                                    <InputGroup>
                                                        <InputGroupText>{currencySymbol[userCurrency]}</InputGroupText>
                                                        <input
                                                            className="form-control"
                                                            name="finalValue"
                                                            placeholder=""
                                                            type="text"
                                                            defaultValue={data?.finalValue}
                                                            {...register('finalValue', {
                                                                required: true,
                                                                pattern: {
                                                                    value: /^(0|[1-9]\d*)(\.\d+)?$/
                                                                }
                                                            })}
                                                        />
                                                    </InputGroup>

                                                    <span className="text-danger">{errors?.finalValue?.type === 'required' && 'Final Value is Required'}</span>
                                                    <span className="text-danger">{errors?.finalValue?.type === 'pattern' && 'Final Value is Invaliid'}</span>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div style={{ marginBottom: "35px" }}>
                                                <FormGroup >
                                                    <Label for="plant">Product Description <span className='required'>*</span></Label>
                                                    <textarea className="form-control" cols={50} rows={3} {...register('productDescription', { required: true })} />
                                                    <span className="text-danger">{errors?.productDescription && 'Product Description is Required'}</span>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className="d-flex" >
                                            <Button color="primary">{data ? 'Update' : 'Add'}</Button>
                                            <Button color="secondary" onClick={() => onClose(false)} className="ml-1">Cancel</Button>
                                        </div>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Fragment>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default AddInventory;