import React, { Fragment, useContext, useEffect, useLayoutEffect, useState } from 'react';
import Loader from './Loader';
import Taptop from './TapTop';
import Header from './Header';
import SideBarLayout from './Sidebar';
import Footer from './Footer';
import { Outlet, useLocation } from 'react-router-dom';
import CustomizerContext from '../../Common/_helper/Customizer';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import ConfigDB from '../Config/ThemeConfig';
import AnimationThemeContext from '../../Common/_helper/AnimationTheme';
import { ToastContainer } from 'react-toastify';
import "../../assets/css/style.css";
import { Alert } from 'reactstrap';
import { API_URL } from '../../Common/Config/Config';
import fetchWrapper from '../../Common/api/fetchWrapper';
import moment from 'moment';
import {SubscriptionContext} from '../../Common/api/helper';
import { useNavigate } from 'react-router-dom';

const Layout = ({ children, classNames, ...rest }) => {
  const Navigate = useNavigate();
  const { sidebar_types, toggleIcon, setToggleIcon, defaultClass, clickEvent, setDefaultClass, setwidth } = useContext(CustomizerContext);
  const sidebar_types1 = sessionStorage.getItem('sidebar_types') || sidebar_types;
  const location = useLocation();
  const { animation } = useContext(AnimationThemeContext);
  const animationTheme = sessionStorage.getItem('animation') || animation || ConfigDB.data.router_animation;
  const [window_Width, setWindow_Width] = useState(0);
  const [moduleExp, setModuleExp] = useState([]);
  const [subscriptionModule, setSubscriptionModule] = useState([]);

  window.addEventListener('resize', () => {
    if (window.innerWidth < 1401) {
      setToggleIcon(true);
      setDefaultClass(true);
    } else {
      setDefaultClass(false);
      setToggleIcon(false);
    }
  });

  useEffect(() => {
    let userType = sessionStorage.getItem('userType');
    if (userType === 'STORE_USER' || userType === 'STORE_ADMIN') {
      getSubscribeMenu();
    }
  }, []);

  const getSubscribeMenu = async () => {
    await fetchWrapper(`${API_URL}/customer/subscriptions`)
      .then((res) => {
        if (res && res.json.length) {
          let expiryModule = [];
          let subModule = [];
          res.json.forEach((sub) => {
            if (sub.isEnabled === 'TRUE') {
              const disableDate = moment(sub.subscriptionDisabledDate)
              const expiryDate = moment(disableDate).subtract(3, 'd');
              const currentDate = moment();
              if (currentDate.isBetween(expiryDate, disableDate)) {
                expiryModule.push(sub);
              }
              if(!(currentDate.isSame(disableDate, 'day'))) {
                subModule.push(sub);
              } 
            }
          });
          setModuleExp(expiryModule);
          setSubscriptionModule(subModule);
        } else {
          Navigate(`${process.env.PUBLIC_URL}/notification`);
        }
      }).catch((err) => {
        console.log(err)
      });
  }

  useLayoutEffect(() => {
    function updateSize() {
      setWindow_Width(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [clickEvent]);

  useEffect(() => {
    setwidth(window_Width);
    if (window_Width - 4 < 1400) {
      setToggleIcon(true);
    } else {
      setToggleIcon(false);
    }
  }, [window_Width])

  const closeOverlay = () => {
    setToggleIcon(!toggleIcon);
  }
  return (
    <Fragment>
      <Loader />
      <Taptop />
      <div className={`page-wrapper ${!defaultClass ? sidebar_types1 : 'compact-wrapper'}`} id="pageWrapper">
        <div className={`page-header ${toggleIcon ? 'close_icon' : ''}`}>
          <Header />
        </div>
        <div className="page-body-wrapper">
          <div className={`sidebar-wrapper ${toggleIcon ? 'close_icon' : ''}`}>
            { sessionStorage.getItem('userType') === 'SYS_ADMIN' || sessionStorage.getItem('userType') === 'SYS_USER' ? <SideBarLayout /> :
            subscriptionModule && subscriptionModule.length ? 
            <SubscriptionContext.Provider value={subscriptionModule}>
              <SideBarLayout />
            </SubscriptionContext.Provider>
            : <></>}
          </div>
          <div className="page-body">
            {moduleExp.length ?
              <Alert color="info" style={{ top: '1rem' }}>
                {moduleExp.map((item, index) => <b className='me-2' key={index}>{item.moduleName} - {item.subscriptionDisabledDate}</b>)} Your license is nearing expiration. Kindly get in touch with the administrator for renewal..
              </Alert> : <></>}

            <TransitionGroup {...rest}>
              <CSSTransition
                key={location.key}
                timeout={100}
                classNames={animationTheme}
                unmountOnExit
              >
                <Outlet />
              </CSSTransition>
            </TransitionGroup>
          </div>
          <Footer />
        </div>
      </div>
      <div className={`bg-overlay ${((window_Width) < 991) && (toggleIcon === false) ? 'active' : ''}`} onClick={() => { closeOverlay() }} ></div>
      {/* <ThemeCustomize /> */}
      <ToastContainer />
    </Fragment >
  );
};
export default Layout;