import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { Container, Row, Col, Card, CardBody, FormGroup, Button, Form } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import { useForm } from "react-hook-form";

const TagManagement = () => {
    const [tag, setTag] = useState();
    const { register, formState: { errors }, handleSubmit } = useForm();
    const refresh = () => {
        setTag('')
    }

    const onSubmit = async (data) => {

    }
    return (
        <Fragment>
            <Breadcrumbs parent="Tag Management" />
            <Row>
                <Col lg={8}>
                    <Card>
                        <CardBody>
                            <Form onSubmit={handleSubmit(onSubmit)} >
                                <FormGroup>
                                    <textarea
                                        className='form-control'
                                        name='tag'
                                        value={tag}
                                        {...register('tagId', {required: true, maxLength: 500})}
                                        onChange={(e) => setTag(e.target.value)}
                                    />
                                    <span className="text-danger">{errors?.tagId?.type === 'required' && 'Required'}</span>
                                    <span className="text-danger">{errors?.tagId?.type === 'maxLength' && 'Maximum length is 500'}</span>
                                </FormGroup>
                                <div className="d-flex">
                                    <Button color="primary" >Save</Button>
                                    <Button color="secondary" className="ml-1" onClick={refresh}>Read Again</Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}

export default TagManagement;