import React, { Fragment, useState, useEffect } from "react";
import { CardBody } from "reactstrap";
import {
    Row,
    Card,
    Col,
    Form,
    FormGroup,
    Label,
    Button
} from "reactstrap";
import { H3 } from "../../../AbstractElements";
import { useForm } from "react-hook-form";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ButtonLoader from '../ButtonLoader';

const UserForm = ({ title, action, navigateUrl, cancel, preData }) => {
    const [data, setData] = useState(action === 'Edit' ? preData : '')
    const { register, formState: { errors }, reset, getValues, handleSubmit, setValue } = useForm({ defaultValues: data });
    const [storeData, setStoreData] = useState([])
    const [storeId, setStoreId] = useState(preData?.storeId);
    const [loading, setLoading] = useState(false);

    const userType = sessionStorage.getItem('userType')
        
    const navigate = useNavigate();

    const getAllStores = async() => {
        await fetchWrapper(`${API_URL}/store`)
        .then((res) => {
            setStoreData(res.json)
        }, (err) => console.log(err))
    }

    useEffect(() => {
        setData(prev => ({ ...prev, ...preData }))
        reset(preData)
    }, [preData])

    useEffect(()=>{
        if(userType === 'STORE_ADMIN') {
            getAllStores()
        }
        if(preData) {
            setStoreId(preData?.storeId)
            setValue('storeId', preData?.storeId)
        }
    }, [preData])

    const onSubmit = async (data) => {
        setLoading(true)
        let url, message, bodyData = {};
        let method = action === "Add" ? 'POST' : 'PUT';
        try {
            if (action === 'Add') {
                data.userName = userType === 'SYS_ADMIN' ? 'SYS_USER' : 'STORE_USER';
                data.userType = userType === 'SYS_ADMIN' ? 'SYS_USER' : 'STORE_USER';
                url = `${API_URL}/user`
                message = 'User Created Successfully!'
                bodyData = {...data};
            }
            if (action === 'Edit') {
                url = `${API_URL}/user/${data.userID}`
                message = 'User Updated Successfully!'
                bodyData = {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    mobileNumber: data.mobileNumber,
                    storeId: data.storeId
                }
            }
            const options = {
                method: method,
                body: bodyData
            }
            
            await fetchWrapper(url, options)
                .then((res) => {
                    if (res.ok) {
                        setLoading(false)
                        toast.success(message)
                        cancel(true)
                        if(action === 'Add') {
                            navigate(navigateUrl)
                        }
                        
                    }
                })
                .catch((err) => {
                    if (err.status == 409) {
                        setLoading(false);
                        toast.error('User Already Exist..')
                        console.log(err)
                    }
                })

        } catch (error) {

        }
    }

    return (
        (<Fragment>
            <Row>
                <Col lg='12'>
                    {title && <H3 attrH3={{ className: 'mtop-2 ml-15' }}>{title}</H3>}
                    <Card className={`mb-0`}>
                        <CardBody className={'p-0 mb-0'}>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    
                                    <Col lg='6'>
                                        <div style={{ marginBottom: "35px" }}>
                                            <FormGroup style={{ marginBottom: "8px" }}>
                                                <Label for="exampleSelect">First Name <span className='required'>*</span></Label>
                                                <input
                                                    className="form-control"
                                                    name="firstName"
                                                    type="text"
                                                    defaultValue={data?.firstName || ''}
                                                    {...register('firstName', { required: true })}
                                                />
                                                {console.log(errors)}
                                                <span className="text-danger">{errors.firstName && 'First Name is Required'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div style={{ marginBottom: "35px" }}>
                                            <FormGroup >
                                                <Label for="exampleEmail">  Last Name <span className='required'>*</span></Label>
                                                <input
                                                    className="form-control"
                                                    name="lastName"
                                                    placeholder=""
                                                    type="text"
                                                    defaultValue={data?.lastName || ''}
                                                    {...register('lastName', { required: true })}
                                                />
                                                <span className="text-danger">{errors.lastName && 'Last Name is Required'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                    </Row>
                                    <Row>
                                    <Col lg='6'>
                                        <div style={{ marginBottom: "35px" }}>
                                            <FormGroup style={{ marginBottom: "8px " }}>
                                                <Label for="exampleSelect">Email <span className='required'>*</span></Label>
                                                <input
                                                    className="form-control"
                                                    name="email"
                                                    placeholder=""
                                                    type="text"
                                                    disabled={action === 'Edit'}
                                                    defaultValue={data?.email || ''}
                                                    {...register('email', { required: true, pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ })}
                                                />
                                                <span className="text-danger">{errors.email?.type === 'required' && 'email is Required'}</span>
                                                <span className="text-danger">{errors.email?.type === 'pattern' && 'Invalid Email'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div style={{ marginBottom: "35px" }}>
                                            <FormGroup >
                                                <Label for="exampleEmail">  Phone <span className='required'>*</span></Label>
                                                <input
                                                    className="form-control"
                                                    name="mobileNumber"
                                                    placeholder=""
                                                    type="text"
                                                    defaultValue={data?.last_name || ''}
                                                    {...register('mobileNumber', { required: true, pattern: /^(\+\d{1,3}[- ]?)?\d{10}$/ })}
                                                />
                                                <span className="text-danger">{errors.mobileNumber?.type === 'required' && 'Phone is Required'}</span>
                                                <span className="text-danger">{errors.mobileNumber?.type === 'pattern' && 'Invalid Phone Number'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                {
                                        userType === 'STORE_ADMIN' && <Col lg="6">
                                        <FormGroup >
                                            <Label for="storeName"> Assign Store <span className='required'>*</span></Label>
                                            <select className="form-select" name="storeId" value={storeId}
                                                {...register('storeId', { required: true })}
                                                onChange={(e)=> setStoreId(e.target.value)}
                                            >
                                                <option value=''>- Select Store -</option>
                                                {
                                                    storeData?.map((item) => <option value={item.id}>{item.name}</option>)
                                                }
                                            </select>
                                            <span className="text-danger">{errors.storeId && 'Please assign the store for this User'}</span>
                                        </FormGroup>
                                    </Col>
                                    }
                                </Row>
                                <Row>
{/*                                     
                                    <Col lg="6">
                                        <div style={{ marginBottom: "35px" }}>
                                            <FormGroup >
                                                <Label for="exampleEmail">  User Name {action === 'Add' && <span className='required'>*</span>}</Label>
                                                <input
                                                    className="form-control"
                                                    name="userName"
                                                    placeholder=""
                                                    type="text"
                                                    disabled={action === "Add" ? false : true}
                                                    defaultValue={data?.userName || ''}
                                                    {...register('userName', { required: true })}
                                                />
                                                <span className="text-danger">{errors.userName && 'User Name is Required'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col> */}
                                </Row>
                                <Row>
                                    <div className="d-flex" >
                                        {
                                            loading ? <ButtonLoader loaderText={action === 'Edit' ? 'Updating' : 'Adding'} color={"primary"} /> : <Button color="primary" >{action === 'Edit' ? 'Update' : 'Add'}</Button>
                                        }
                                        <Button color="secondary" className="ml-1" onClick={cancel}> Cancel</Button>
                                    </div>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>)
    );
}

export default UserForm;