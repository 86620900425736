import React, { Fragment, useEffect, useState } from 'react';
import { Image, LI, P, UL } from '../../../../AbstractElements';
import { User, Mail, FileText, LogOut } from 'react-feather';
import { Link, useNavigate } from 'react-router-dom';
// import man from '../../../../assets/images/dashboard/profile.png';
import { Account, Inbox, logout, Taskboard } from '../../../../Common/Constant';
import fetchWrapper from '../../../api/fetchWrapper';
import { API_URL } from '../../../Config/Config';
import { FaUserCircle } from 'react-icons/fa';

const LogoutClass = () => {
    const Navigate = useNavigate();
    const [profileData, setProfileData] = useState();
    const authenticated = JSON.parse(sessionStorage.getItem('authenticated'));
    const auth_profile = JSON.parse(sessionStorage.getItem('auth_profile'));
    const [profile, setProfile] = useState('');
    const [name, setName] = useState('');

    const loadProfile = async () => {
        await fetchWrapper(`${API_URL}/users/userProfile`)
        .then((res)=>{
            console.log(res)
            if(res.status === 200) {
                setProfileData(res.json)
            }
        }, (err)=>{
            console.log(err)
            Navigate(`${process.env.PUBLIC_URL}/login`);
        })
    }

    useEffect(() => {
        // loadProfile();
        setProfile(sessionStorage.getItem('profileURL'));
        setName(sessionStorage.getItem('Name') || 'Helen Walter');
    }, [setProfile, setName]);

    const Logout = () => {
        sessionStorage.removeItem('profileURL');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('auth_profile');
        sessionStorage.removeItem('Name');
        sessionStorage.setItem('authenticated', false);
        sessionStorage.setItem('login', false);
        sessionStorage.clear();
        Navigate(`${process.env.PUBLIC_URL}/login`);
    };

    return (
        <Fragment>
            <LI attrLI={{ className: "profile-nav onhover-dropdown p-0" }} >
                <div className="d-flex profile-media">
                    <FaUserCircle className='m-0 profile-icon' height={50} width={50}/>
                    {/* <Image attrImage={{ className: 'b-r-50 m-0', src: profileImg, alt: '' }} /> */}
                    <div className="flex-grow-1"><span>{sessionStorage.getItem('profileName')}</span>
                        {/* <P attrPara={{ className: "mb-0 font-roboto" }} >Admin <i className="middle fa fa-angle-down"></i></P> */}
                    </div >
                </div >
                <UL attrUL={{ className: "profile-dropdown onhover-show-div" }}>
                    {
                        sessionStorage.getItem('userType') !== 'SYS_ADMIN' &&  <LI>
                        <Link to={`${process.env.PUBLIC_URL}/profile`}><User />{'Profile'}</Link>
                    </LI>
                    }
                   
                    <LI attrLI={{ onClick: Logout }}>
                        <Link to={`${process.env.PUBLIC_URL}/login`}><LogOut />{logout}</Link>
                    </LI>
                </UL>
            </LI >
        </Fragment >
    );
};

export default LogoutClass;