import React, { Fragment, useState, useEffect } from "react";
import AdminForm from "../../Common/Component/AdminForm";
import fetchWrapper from "../../Common/api/fetchWrapper";
import { API_URL } from "../../Common/Config/Config";
import LayoutLoader from '../../Common/Component/LayoutLoader/LayoutLoader'
import { defaultUserType } from "../../Common/api/helper";

const EditCustomer = () => {
    const [ data, setData ] = useState();
    useEffect(() => {
        const url = window.location.pathname.split('/');
        loadData(url[3])
    }, []);
    
    const loadData = async (id) => {
        await fetchWrapper(`${API_URL}/customer/${id}`)
        .then((res)=> {
            setData(res.json);
        })
        .catch((error) => {
            console.debug(error)
        })
    }

    return (
        <Fragment>
            <LayoutLoader />
            <AdminForm type={'customer'} action={'Edit'} title={'Edit Customer'} 
                navigateUrl={`${process.env.PUBLIC_URL}/${defaultUserType[sessionStorage.getItem('userType')]}/customer-list`} 
            preData={data} />
        </Fragment>
    )
}

export default EditCustomer;