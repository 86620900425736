import React, { Fragment, useState, useEffect, useRef, useCallback } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import AgGridTable from '../../Common/Component/AgGridTable';
import { Trash } from 'react-feather';
import AddModule from './AddModule';
import { Edit } from 'react-feather';
import fetchWrapper from '../../Common/api/fetchWrapper';
import { API_URL } from '../../Common/Config/Config';
import { toast } from 'react-toastify';


const SubscriptionModules = () => {
    const [open, setOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState();
    const [data, setData] = useState([]);
    const gridRef = useRef(null);

    const tableColumns = sessionStorage.getItem('userType') === 'SYS_ADMIN' ? [
        {
            headerName: 'Module Name',
            filter: true,
            sortable: true,
            flex: 2,
            suppressMovable: true,
            valueGetter: (params) => {
                return params.data.name
            },
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Edit',
            width: 90,
            cellRenderer: params => {
                return (
                    <Edit className={`edit-icon`} onClick={() => clickRowEdit(params.data)} />
                )
            },
            suppressMovable: true,
        },
        {
            headerName: 'Delete',
            width: 90,
            cellRenderer: params => {
                return (
                    <Trash className={`edit-icon`} onClick={() => clickRowDelete(params.data)} />
                )
            },
            suppressMovable: true,
        },
    ] : [
        {
            headerName: 'Module Name',
            filter: true,
            sortable: true,
            flex: 2,
            suppressMovable: true,
            valueGetter: (params) => {
                return params.data.name
            },
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Edit',
            width: 90,
            cellRenderer: params => {
                return (
                    <Edit className={`edit-icon`} onClick={() => clickRowEdit(params.data)} />
                )
            },
            suppressMovable: true,
        }];

    const clickRowEdit = (item) => {
        setSelectedItem(item);
        setOpen(true);
    }
    const handleClick = () => {
        setSelectedItem();
        setOpen(true);
    }

    useEffect(() => {
        getModules();
    }, []);

    const clickRowDelete = async (data) => {
        const requestOptions = {
            method: 'DELETE',
        };
        gridRef?.current?.api?.showLoadingOverlay();
        await fetchWrapper(`${API_URL}/module/${data?.moduleId}`, requestOptions)
            .then((res) => {
                console.log(res);
            }, (err) => {
                console.log(err)
                if(err.status === 204) {
                    toast.success('Module Deleted Successfully!')
                    getModules();
                } else if(err.status === 412) {
                    toast.error('This Module is subscribed by the Customer');
                    gridRef?.current?.api?.hideOverlay();
                }
            })
    }

    const getModules = async () => {
        gridRef?.current?.api?.showLoadingOverlay();
        await fetchWrapper(`${API_URL}/module`)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    setData(res.json);
                }
                gridRef?.current?.api?.hideOverlay();
            }, (err) => {
                console.log(err)
                gridRef?.current?.api?.hideOverlay();
            })
    }

    const onGridReady = useCallback(async (params) => {
        gridRef?.current?.api?.showLoadingOverlay();
        getModules();
    })

    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <div className="d-flex">
                            <Breadcrumbs mainTitle="" parent="Modules" title="" />
                            <div className="text-right action_button">
                                <div className="link action_button" onClick={handleClick}>
                                    <i style={{ fontSize: '25px', color: "#2b7704" }} className="icofont  icofont-plus-circle" ></i>
                                    &nbsp;&nbsp;{"Add Module"}
                                </div>
                            </div>
                        </div>
                        {open && <AddModule isOpen={open} data={selectedItem} setOpenModal={setOpen} loadModule={getModules} />}
                        <Card className="border-bottom-light  newtable">
                            <CardBody className='data-tables responsive-view'>
                                <AgGridTable
                                    columnData={tableColumns}
                                    tableData={data}
                                    agRef={gridRef}
                                    onGridReady={onGridReady}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );

};

export default SubscriptionModules;