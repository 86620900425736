import React, { Fragment, useEffect, useState } from "react";
import { InputGroup, InputGroupText, Modal, ModalBody, ModalHeader } from "reactstrap";
import { H3 } from "../../AbstractElements";
import { X } from "react-feather";
import {
    Row,
    Card,
    Col,
    Form,
    FormGroup,
    Label,
    CardBody,
    Button
} from "reactstrap";
import { useForm } from "react-hook-form";
import fetchWrapper from "../../Common/api/fetchWrapper";
import { API_URL } from "../../Common/Config/Config";
import { toast } from "react-toastify";
import { currencySymbol } from "../../Common/api/currencyData";

const AddInventory = ({ value, closeModal, data, action }) => {
    const [open, setOpen] = useState(value);
    const { register, formState: { errors }, reset, getValues, handleSubmit } = useForm({ defaultValues: data });
    const [storeData, setStoreData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [storeId, setStoreId] = useState(data?.storeId);
    const [categoryId, setCategoryId] = useState(data?.categoryId);
    const userCurrency = sessionStorage.getItem('userCurrency');

    const getStoreData = async () => {
        await fetchWrapper(`${API_URL}/store/type/APPAREL`)
            .then((res) => {
                setStoreData(res.json);
            }, (err) => {
                console.log(err)
            })
    }

    const getCategoryData = async () => {
        await fetchWrapper(`${API_URL}/productcategory/type/APPAREL/details`)
            .then((res) => {
                setCategoryData(res.json)
            }, (err) => {
                console.log(err)
            })
    }

    const onClose = (update) => {
        setOpen(false);
        closeModal(update)
    }

    useEffect(() => {
        getStoreData()
        getCategoryData()
    }, [])

    const onSubmit = async (data) => {
        let bodydata = data;
        bodydata.ean = Number(data.ean);
        bodydata.plant = Number(data.plant);
        bodydata.size = Number(data.size);
        bodydata.stLocation = Number(data.stLocation);
        bodydata.mrp = Number(data.mrp);
        bodydata.sohWithoutBlockedStock = Number(data.sohWithoutBlockedStock);
        bodydata.sohQuantiy = Number(data.sohQuantiy);
        bodydata.value = Number(data.value);
        bodydata.sohBlockedStock = Number(data.sohBlockedStock);
        
        let url = action === 'Add' ? `/inventory` : `/inventory/${data?.id}`;
        const options = {
            method: data?.id ? 'PUT' : 'POST',
            body: bodydata
        }
        await fetchWrapper(`${API_URL}${url}`, options)
            .then((res) => {
                if (res.status === 200) {
                    onClose(true)
                    toast.success('Inventory Added Succussfully')
                } 
            }, (err) => {
                console.log(err)
                onClose(false)
                toast.error('Something went wrong!')
            })
    }

    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size='lg'>
                <ModalHeader style={{ display: 'flex' }}>
                    <H3 attrH3={{ className: 'modal-title-color' }}>{data ? 'Update Inventory' : 'Add Inventory'} <X className="x-button" onClick={() => onClose(false)} /></H3>
                </ModalHeader>
                <ModalBody className="model-scroll">
                    <Fragment>
                        <Row>
                            <Col lg='12'>
                                {/* <H3 attrH3={{ className: 'mtop-2 ml-15' }}>Store</H3> */}
                                <Card className={`${action === 'Add' && 'mb-0'} ${action === 'Edit' && 'border-bottom-light '}`}>
                                    <CardBody className={action === 'Add' && 'p-0 mb-0'}>
                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                            <Row>
                                                {sessionStorage.getItem('userType') !== 'STORE_USER' ?
                                                <Col lg='6'>
                                                    <div style={{ marginBottom: "35px" }}>
                                                        <FormGroup style={{ marginBottom: "8px" }}>
                                                            <Label for="skuNumber">Store <span className='required'>*</span></Label>
                                                            <select
                                                                className="form-select"
                                                                name="storeId"
                                                                value={storeId}
                                                                {...register('storeId', { required: true })}
                                                                onChange={(e) => setStoreId(e.target.value)}
                                                            >
                                                                <option value={''}>- Select Store -</option>
                                                                {
                                                                    storeData?.map((item) => <option value={item?.id}>{item?.name}</option>)
                                                                }
                                                            </select>
                                                            <span className="text-danger">{errors?.storeId && 'Store is Required'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                : <></>}
                                                <Col lg='6'>
                                                    <div style={{ marginBottom: "35px" }}>
                                                        <FormGroup style={{ marginBottom: "8px " }}>
                                                            <Label for="category">Product Category <span className='required'>*</span></Label>
                                                            <select
                                                                className="form-select"
                                                                name="categoryId"
                                                                value={categoryId}
                                                                {...register('categoryId', { required: true })}
                                                                onChange={(e) => setCategoryId(e.target.value)}
                                                            >
                                                                <option value={''}>- Select Category -</option>
                                                                {
                                                                    categoryData?.map((item) => <option value={item?.id}>{item?.name}</option>)
                                                                }
                                                            </select>
                                                            <span className="text-danger">{errors?.categoryId && 'Product Category is Required'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <div style={{ marginBottom: "35px" }}>
                                                        <FormGroup >
                                                            <Label for="plant">Plant <span className='required'>*</span></Label>
                                                            <input
                                                                className="form-control"
                                                                name="plant"
                                                                placeholder=""
                                                                type="number"
                                                                defaultValue={data?.plant}
                                                                {...register('plant', { required: true })}
                                                            />
                                                            <span className="text-danger">{errors?.plant && 'Plant is Required'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col lg="6">
                                                    <div style={{ marginBottom: "35px" }}>
                                                        <FormGroup >
                                                            <Label for="brand">Retek Group <span className='required'>*</span></Label>
                                                            <input
                                                                className="form-control"
                                                                name="retekGroup"
                                                                placeholder=""
                                                                type="text"
                                                                defaultValue={data?.retekGroup}
                                                                {...register('retekGroup', { required: true })}
                                                            />
                                                            <span className="text-danger">{errors?.retekGroup && 'Retek Group is Required'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <div style={{ marginBottom: "35px" }}>
                                                        <FormGroup >
                                                            <Label for="brand">Retek Dept <span className='required'>*</span></Label>
                                                            <input
                                                                className="form-control"
                                                                name="retekDept"
                                                                placeholder=""
                                                                type="text"
                                                                defaultValue={data?.retekDept}
                                                                {...register('retekDept', { required: true })}
                                                            />
                                                            <span className="text-danger">{errors?.retekDept && 'Retek Dept is Required'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col lg="6">
                                                    <div style={{ marginBottom: "35px" }}>
                                                        <FormGroup >
                                                            <Label for="brand">Retek Class <span className='required'>*</span></Label>
                                                            <input
                                                                className="form-control"
                                                                name="retekClass"
                                                                placeholder=""
                                                                type="text"
                                                                defaultValue={data?.retekClass}
                                                                {...register('retekClass', { required: true })}
                                                            />
                                                            <span className="text-danger">{errors?.retekClass && 'Retek Class is Required'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <div style={{ marginBottom: "35px" }}>
                                                        <FormGroup >
                                                            <Label for="brand">Retek Subclass <span className='required'>*</span></Label>
                                                            <input
                                                                className="form-control"
                                                                name="retekSubClass"
                                                                placeholder=""
                                                                type="text"
                                                                defaultValue={data?.retekSubClass}
                                                                {...register('retekSubClass', { required: true })}
                                                            />
                                                            <span className="text-danger">{errors?.retekSubClass && 'Retek Subclass is Required'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col lg="6">
                                                    <div style={{ marginBottom: "35px" }}>
                                                        <FormGroup >
                                                            <Label for="brand">Season <span className='required'>*</span></Label>
                                                            <input
                                                                className="form-control"
                                                                name="season"
                                                                placeholder=""
                                                                type="text"
                                                                defaultValue={data?.season}
                                                                {...register('season', { required: true })}
                                                            />
                                                            <span className="text-danger">{errors?.season && 'Season is Required'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <div style={{ marginBottom: "35px" }}>
                                                        <FormGroup >
                                                            <Label for="brand">EAN <span className='required'>*</span></Label>
                                                            <input
                                                                className="form-control"
                                                                name="ean"
                                                                placeholder=""
                                                                type="number"
                                                                defaultValue={data?.ean}
                                                                {...register('ean', { required: true })}
                                                            />
                                                            <span className="text-danger">{errors?.ean && 'EAN is Required'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col lg="6">
                                                    <div style={{ marginBottom: "35px" }}>
                                                        <FormGroup >
                                                            <Label for="brand">RFID Number <span className='required'>*</span></Label>
                                                            <input
                                                                className="form-control"
                                                                name="rfidNumber"
                                                                placeholder=""
                                                                type="text"
                                                                defaultValue={data?.rfidNumber}
                                                                {...register('rfidNumber', { required: true })}
                                                            />
                                                            <span className="text-danger">{errors?.rfidNumber && 'RFID Number is Required'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <div style={{ marginBottom: "35px" }}>
                                                        <FormGroup >
                                                            <Label for="size">Size <span className='required'>*</span></Label>
                                                            <input
                                                                className="form-control"
                                                                name="size"
                                                                placeholder=""
                                                                type="number"
                                                                defaultValue={data?.size}
                                                                {...register('size', { required: true, min: 0 })}
                                                            />
                                                            <span className="text-danger">{errors?.size?.type === 'required' && 'Size is Required'}</span>
                                                            <span className="text-danger">{errors?.size?.type === 'min' && 'Size should be Positive'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col lg="6">
                                                    <div style={{ marginBottom: "35px" }}>
                                                        <FormGroup >
                                                            <Label for="color">Sleeve</Label>
                                                            <input
                                                                className="form-control"
                                                                name="sleeve"
                                                                placeholder=""
                                                                type="text"
                                                                defaultValue={data?.sleeve}
                                                                {...register('sleeve')}
                                                            />
                                                            <span className="text-danger">{errors?.sleeve && 'Sleeve is Required'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <div style={{ marginBottom: "35px" }}>
                                                        <FormGroup >
                                                            <Label for="inventoryLevel">Style Code <span className='required'>*</span></Label>
                                                            <input
                                                                className="form-control"
                                                                name="styleCode"
                                                                placeholder=""
                                                                type="text"
                                                                defaultValue={data?.styleCode}
                                                                {...register('styleCode', { required: true })}
                                                            />
                                                            <span className="text-danger">{errors?.styleCode && 'Style Code is Required'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col lg="6">
                                                    <div style={{ marginBottom: "35px" }}>
                                                        <FormGroup >
                                                            <Label for="inventoryLevel">St. Location <span className='required'>*</span></Label>
                                                            <input
                                                                className="form-control"
                                                                name="stLocation"
                                                                placeholder=""
                                                                type="number"
                                                                defaultValue={data?.stLocation}
                                                                {...register('stLocation', { required: true })}
                                                            />
                                                            <span className="text-danger">{errors?.stLocation && 'St. Location is Required'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <div style={{ marginBottom: "35px" }}>
                                                        <FormGroup >
                                                            <Label for="inventoryLevel">Variant <span className='required'>*</span></Label>
                                                            <input
                                                                className="form-control"
                                                                name="variant"
                                                                placeholder=""
                                                                type="text"
                                                                defaultValue={data?.variant}
                                                                {...register('variant', { required: true })}
                                                            />
                                                            <span className="text-danger">{errors?.variant && 'Variant is Required'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col lg="6">
                                                    <div style={{ marginBottom: "35px" }}>
                                                        <FormGroup >
                                                            <Label for="inventoryLevel">Price <span className='required'>*</span></Label>
                                                            <InputGroup>
                                                                <InputGroupText>{currencySymbol[userCurrency]}</InputGroupText>
                                                                <input
                                                                className="form-control"
                                                                name="mrp"
                                                                placeholder=""
                                                                type="number"
                                                                defaultValue={data?.mrp}
                                                                {...register('mrp', { required: true, min: 0 })}
                                                            />
                                                            </InputGroup>
                                                            <span className="text-danger">{errors?.mrp?.type === 'required' && 'Price is Required'}</span>
                                                            <span className="text-danger">{errors?.mrp?.type === 'min' && 'Price should be Positive'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <div style={{ marginBottom: "35px" }}>
                                                        <FormGroup >
                                                            <Label for="inventoryLevel">SOH Blocked Stock <span className='required'>*</span></Label>
                                                            <input
                                                                className="form-control"
                                                                name="sohBlockedStock"
                                                                placeholder=""
                                                                type="text"
                                                                defaultValue={data?.sohBlockedStock}
                                                                {...register('sohBlockedStock', { required: true, min: 0 })}
                                                            />
                                                            <span className="text-danger">{errors?.sohBlockedStock?.type === 'required' && 'SOH Blocked Stock is Required'}</span>
                                                            <span className="text-danger">{errors?.sohBlockedStock?.type === 'min' && 'SOH Blocked Stock should be Positive'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col lg="6">
                                                    <div style={{ marginBottom: "35px" }}>
                                                        <FormGroup >
                                                            <Label for="inventoryLevel">SOH without Blocked Stock <span className='required'>*</span></Label>
                                                            <input
                                                                className="form-control"
                                                                name="sohWithoutBlockedStock"
                                                                placeholder=""
                                                                type="number"
                                                                defaultValue={data?.sohWithoutBlockedStock}
                                                                {...register('sohWithoutBlockedStock', { required: true, min: 0 })}
                                                            />
                                                            <span className="text-danger">{errors?.sohWithoutBlockedStock?.type === 'required' && 'SOH without Blocked Stock is Required'}</span>
                                                            <span className="text-danger">{errors?.sohWithoutBlockedStock?.type === 'min' && 'SOH without Blocked Stock should be Positive'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <div style={{ marginBottom: "35px" }}>
                                                        <FormGroup >
                                                            <Label for="inventoryLevel">SOH Quantity <span className='required'>*</span></Label>
                                                            <input
                                                                className="form-control"
                                                                name="sohQuantity"
                                                                placeholder=""
                                                                type="number"
                                                                defaultValue={data?.sohQuantity}
                                                                {...register('sohQuantity', { required: true, min: 0 })}
                                                            />
                                                            <span className="text-danger">{errors?.sohQuantity?.type === 'required' && 'SOH Quantity is Required'}</span>
                                                            <span className="text-danger">{errors?.sohQuantity?.type === 'min' && 'SOH Quantity should be Positive'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col lg="6">
                                                    <div style={{ marginBottom: "35px" }}>
                                                        <FormGroup >
                                                            <Label for="inventoryLevel">Value <span className='required'>*</span></Label>
                                                            <InputGroup>
                                                                <InputGroupText>{currencySymbol[userCurrency]}</InputGroupText>
                                                                <input
                                                                className="form-control"
                                                                name="value"
                                                                placeholder=""
                                                                type="number"
                                                                defaultValue={data?.value}
                                                                {...register('value', { required: true })}
                                                            />
                                                            </InputGroup>
                                                            <span className="text-danger">{errors?.value?.type === 'required' && 'Value is Required'}</span>
                                                            <span className="text-danger">{errors?.value?.type === 'min' && 'Value should be Positive'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                            <Col lg="6">
                                                    <div style={{ marginBottom: "35px" }}>
                                                        <FormGroup >
                                                            <Label for="inventoryLevel">Total Quantity <span className='required'>*</span></Label>
                                                            <input
                                                                className="form-control"
                                                                name="totalQuantity"
                                                                placeholder=""
                                                                type="number"
                                                                defaultValue={data?.totalQuantity}
                                                                {...register('totalQuantity', { required: true, min: 0 })}
                                                            />
                                                            <span className="text-danger">{errors?.totalQuantity?.type === 'required' && 'Total Value is Required'}</span>
                                                            <span className="text-danger">{errors?.totalQuantity?.type === 'min' && 'Total Value should be Positive'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <div className="d-flex" >
                                                    <Button color="primary" type="submit">{data ? 'Update' : 'Add'}</Button>
                                                    <Button color="secondary" type="button" className="ml-1" onClick={() => onClose(false)}>Cancel</Button>
                                                </div>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Fragment>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default AddInventory;