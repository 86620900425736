import React, { Fragment, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { H3 } from "../../AbstractElements";
import { X } from "react-feather";
import AdminForm from "../../Common/Component/AdminForm";

const AddCustomerModal = (props) => {
    const [open, setOpen] = useState(props.value);
    const onClose = () => {
        setOpen(false);
        props.setOpenModal(false);
        props.loadCustomerData();
    }


    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size='lg'>
                <ModalHeader style={{ display: 'flex' }}>
                    <H3 attrH3={{ className: 'modal-title-color' }}>New Customer Onboarding
                    </H3>
                    <X className="x-button" onClick={onClose}/>
                </ModalHeader>
                <ModalBody>
                    <AdminForm type={'customer'} action={'Add'} cancel={onClose} navigateUrl={`${process.env.PUBLIC_URL}/sys-admin/customer-list`} />
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default AddCustomerModal;