import React, { Fragment, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { H3 } from "../../AbstractElements";
import { X } from "react-feather";
import UserForm from "../../Common/Component/UserForm";

const AddAsoUser = ({value, setOpenModal, orgData, action}) => {
    const [open, setOpen] = useState(value);
    
    const onClose = (e) => {
        setOpen(false);
        setOpenModal(e)
    }

    
    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size='lg'>
                <ModalHeader style={{display: 'flex'}}>
                    <H3 attrH3={{ className: 'modal-title-color' }}>User - Onboarding <X className="x-button" onClick={onClose}/></H3>
                </ModalHeader>
                <ModalBody>
                    <UserForm action={action} cancel={onClose} navigateUrl={`${process.env.PUBLIC_URL}/sys-admin/module-origanization`} preData={orgData} />
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default AddAsoUser;