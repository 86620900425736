import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as ReactDOMClient from 'react-dom/client';
import './i18n';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { applyMiddleware, compose } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { reducers } from './Common/reducers';
import thunk from 'redux-thunk';
import { Amplify } from 'aws-amplify';
import awsmobile from './aws-exports';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';
import sessionStorage from 'redux-persist/lib/storage/session'

Amplify.configure(awsmobile);

const persistConfig = {
  key: 'root',
  storage: sessionStorage,
};

const persistedReducer = persistReducer(persistConfig, reducers);


const store = configureStore(
  { reducer: persistedReducer },
  compose(
    applyMiddleware(thunk),
    window.devToolsExtension ? window.devToolsExtension() : f => f
  )
)
const persistor = persistStore(store);


const root = ReactDOMClient.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
