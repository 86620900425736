import React, { Fragment, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { H3 } from "../../AbstractElements";
import { Image } from '../../AbstractElements';
import { X } from "react-feather";
import {
    Row, Col
} from "reactstrap";
import fetchWrapper from "../../Common/api/fetchWrapper";
import { API_URL } from "../../Common/Config/Config";
import { currencyFormatter } from "../../Common/api/helper";

const ViewProduct = ({ value, setOpenModal, data }) => {
    const [open, setOpen] = useState(value);
    const [categoryData, setCategory] = useState();
    const currency = sessionStorage.getItem('userCurrency');

    const getCategoryDetails = async () => {
        await fetchWrapper(`${API_URL}/productcategory/${data?.categoryId}`)
            .then(res => {
                setCategory(res.json)
            }, (err) => console.log(err));
    }

    useEffect(() => {
        getCategoryDetails()
    }, [])

    const onClose = () => {
        setOpen(false);
        setOpenModal(false)
    }


    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size='lg'>
                <ModalHeader style={{ display: 'flex' }}>
                    <H3 attrH3={{ className: 'modal-title-color' }}>View Product <X className="x-button" onClick={onClose} /></H3>
                </ModalHeader>
                <ModalBody>
                    <Fragment>
                        <Row>
                            <Col lg='6' className="ml-3">
                                <img style={{ width: '100%', marginBottom: '2rem' }} className='img-fluid for-light' src={`data:image/jpeg;base64,${categoryData?.logo}`} />
                            </Col>
                            <Col lg='4 ml-3' style={{ lineHeight: '3' }}>
                                <label>{data?.rfidNumber}</label>
                                <h3 style={{ borderBottom: '1px solid #2e2846', paddingBottom: '1rem' }}>{data?.retekClass} - {data?.retekSubClass}</h3>
                                <div><span style={{ fontSize: 'small' }}>{data?.categoryName}</span></div>
                                <div className="mtop-1">
                                        <h4>Price <span>{currencyFormatter(data?.mrp, currency)}</span></h4>
                                    </div>
                                <label>Size</label>
                                <div style={{ border: '1px solid', borderRadius: '7px', width: '51%', 'paddingLeft': '0.5rem' }}>{data?.size}</div>
                                <div>
                                    <div className="mtop-1">
                                        <h5>SOH Quantity: <span className="ml-1">{data?.sohQuantity}</span></h5>
                                    </div>
                                    <div className="mtop-1">
                                        <h5>Style Code: <span className="ml-1">{data?.styleCode}</span></h5>
                                    </div>
                                    <div className="mtop-1">
                                        <h5>Varient: <span className="ml-1">{data?.variant}</span></h5>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Fragment>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default ViewProduct;
