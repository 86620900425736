import React, { Fragment, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { H3 } from "../../AbstractElements";
import { X } from "react-feather";
import {
    Container,
    Row,
    Card,
    Col,
    Form,
    FormGroup,
    Label,
    CardBody,
    Button,
    InputGroup,
    InputGroupText
} from "reactstrap";
import { countries } from "../../Common/Data/CountryList/Defaultdata";
import { useForm } from "react-hook-form";
import fetchWrapper from "../../Common/api/fetchWrapper";
import { API_URL } from "../../Common/Config/Config";
import { toast } from "react-toastify";

const AddStore = ({ value, closeModal, data, type }) => {
    const [open, setOpen] = useState(value);
    const [state, setState] = useState();
    const [stateList, setStateList] = useState([]);
    const [country, setCountry] = useState();
    const { register, formState: { errors }, reset, getValues, handleSubmit } = useForm({ defaultValues: data });
    const [dialCode, setDialCode] = useState('');
    const action = 'Add';
    const onClose = (update) => {
        setOpen(false);
        closeModal(update);
    }

    const countryChangeEvent = (item, countryList) => {
        const selectedItem = countryList.find((countryItem) => countryItem.country === item);
        setCountry(item);
        setDialCode(selectedItem?.dial_code)
        setStateList(selectedItem.states);
        // setValue('state', '')
        setState();
    }

    const onSubmit = async (data) => {
        data.categoryType = type === 'apparel' ? 'APPAREL' : 'JEWELLERY';
        data.mobileNumber = dialCode + ' ' + data?.mobileNumber
        const options = {
            method: 'POST', 
            body: data
        }
        await fetchWrapper(`${API_URL}/store`, options)
        .then((res) => {
            console.log(res.json)
            onClose(true);
            toast.success('Store Created Successfully!')
        }, (err) => console.log(err))
    }

    return (
        (<Fragment>
            <Modal centered={true} isOpen={open} size='lg'>
                <ModalHeader style={{ display: 'flex' }}>
                    <H3 attrH3={{ className: 'modal-title-color' }}>{data ? 'Update Store' : 'Add Store'} <X className="x-button" onClick={() => onClose(false)} /></H3>
                </ModalHeader>
                <ModalBody>
                    <Fragment>
                        <Row>
                            <Col lg='12'>
                                {/* <H3 attrH3={{ className: 'mtop-2 ml-15' }}>Store</H3> */}
                                <Card className={`${action === 'Add' && 'mb-0'} ${action === 'Edit' && 'border-bottom-light '}`}>
                                    <CardBody className={action === 'Add' && 'p-0 mb-0'}>
                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                            <Row>
                                                <Col lg='6'>
                                                    <div style={{ marginBottom: "35px" }}>
                                                        <FormGroup style={{ marginBottom: "8px" }}>
                                                            <Label for="name">Name <span className='required'>*</span></Label>
                                                            <input
                                                                className="form-control"
                                                                name="name"
                                                                type="text"
                                                                defaultValue={data?.name}
                                                                {...register('name', { required: true })}
                                                            />
                                                            <span className="text-danger">{errors.name && 'Name is Required'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col lg="6">
                                                    <div style={{ marginBottom: "35px" }}>
                                                        <FormGroup >
                                                            <Label for="address">Address <span className='required'>*</span></Label>
                                                            <input
                                                                className="form-control"
                                                                name="address"
                                                                placeholder=""
                                                                type="text"
                                                                defaultValue={data?.address}
                                                                {...register('address', { required: true })}
                                                            />
                                                            <span className="text-danger">{errors.address && 'Address is Required'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg='6'>
                                                    <div style={{ marginBottom: "35px" }}>
                                                        <FormGroup style={{ marginBottom: "8px" }}>
                                                            <Label for="city">City <span className='required'>*</span></Label>
                                                            <input
                                                                className="form-control"
                                                                name="city"
                                                                type="text"
                                                                defaultValue={data?.city}
                                                                {...register('city', { required: true })}
                                                            />
                                                            <span className="text-danger">{errors.city && 'City is Required'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col lg="6">
                                                    <div style={{ marginBottom: "35px" }}>
                                                        <FormGroup >
                                                            <Label for="zipcode">ZipCode <span className='required'>*</span></Label>
                                                            <input
                                                                className="form-control"
                                                                name="zipcode"
                                                                placeholder=""
                                                                type="textarea"
                                                                defaultValue={data?.zipCode}
                                                                {...register('zipCode', { required: true, pattern: /[+-]?([0-9]*[.])?[0-9]+$/ })}
                                                            />
                                                            <span className='text-danger'>{errors.zipCode && errors.zipCode.type === 'required'
                                                                && 'Zipcode is Required'}</span>
                                                            <span className='text-danger'>{errors.zipCode && errors.zipCode.type === 'pattern'
                                                                && 'Zipcode Value must be decimal'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg='6'>
                                                    <div style={{ marginBottom: "35px" }}>
                                                        <FormGroup >
                                                            <Label for="exampleSelect"> Country <span className='required'>*</span></Label>
                                                            <select className="form-select" name="country"
                                                                defaultValue={data?.country}
                                                                {...register('country', { required: true })}
                                                                onChange={e => countryChangeEvent(e.target.value, countries)}
                                                            >
                                                                <option value=''>-Select Country-</option>
                                                                {countries.map((item, i) => {
                                                                    return (
                                                                        <option value={item.country}>{item.country}</option>
                                                                    )
                                                                }
                                                                )}
                                                            </select>
                                                            <span className="text-danger">{errors.country && 'Country is Required'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col lg="6">
                                                    <div style={{ marginBottom: "35px" }}>
                                                        <FormGroup >
                                                            <Label for="exampleSelect"> State <span className='required'>*</span></Label>
                                                            <select className="form-select" name="state"
                                                                defaultValue={data?.state}
                                                                {...register('state', { required: true })}
                                                                onChange={(e) => setState(e.target.value)}
                                                            >
                                                                <option value={''}>-Select State-</option>
                                                                {
                                                                    stateList && stateList.length > 0 && stateList.map(i => {
                                                                        return (<option value={i}>{i}</option>)
                                                                    })
                                                                }
                                                            </select>
                                                            <span className="text-danger">{errors.state && 'State is Required'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg='6'>
                                                    <div style={{ marginBottom: "35px" }}>
                                                        <FormGroup style={{ marginBottom: "8px " }}>
                                                            <Label for="email">Store Email <span className='required'>*</span></Label>
                                                            <input
                                                                className="form-control"
                                                                name="email"
                                                                placeholder=""
                                                                type="text"
                                                                defaultValue={data?.email}
                                                                {...register('email', { required: true, pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ })}
                                                            />
                                                            <span className="text-danger">{errors.email?.type === 'required' && 'Store email is Required'}</span>
                                                            <span className="text-danger">{errors.email?.type === 'pattern' && 'Invalid Email'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col lg="6">
                                                    <div style={{ marginBottom: "35px" }}>
                                                        <FormGroup >
                                                            <Label for="phone">Phone Number <span className='required'>*</span></Label>
                                                            <InputGroup>
                                                                <InputGroupText>{dialCode}</InputGroupText>
                                                                <input
                                                                className="form-control"
                                                                name="phone"
                                                                placeholder=""
                                                                type="text"
                                                                defaultValue={data?.phone}
                                                                {...register('mobileNumber', { required: true, pattern: /^(\+\d{1,3}[- ]?)?\d{10}$/ })}
                                                            />
                                                            </InputGroup>
                                                            
                                                            <span className="text-danger">{errors.mobileNumber?.type === 'required' && 'Phone is Required'}</span>
                                                            <span className="text-danger">{errors.mobileNumber?.type === 'pattern' && 'Invalid Phone Number'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <div className="d-flex" >
                                                    <Button color="primary" >{action === 'Edit' ? 'Update' : 'Add'}</Button>
                                                    <Button color="secondary" className="ml-1" onClick={() => onClose(false)}> Cancel</Button>
                                                </div>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Fragment>
                </ModalBody>
            </Modal>
        </Fragment>)
    );
}

export default AddStore;