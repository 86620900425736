

const config = require('./AppConfig.json');

// Auth0
export const auth0 = ({
    domain: config.auth0.domain,
    clientId: config.auth0.clientID,
    redirectUri: config.auth0.redirectUri
});

// Jwt
export const Jwt_token = config.jwt_token;

// export const API_URL = 'https://api.green-carbon.ai';

export const API_URL = 'https://wave-connect-api-new.azurewebsites.net/api';

export const clientId = "b0e0f443-44cd-4e36-a556-a912c3c0ff84";
export const clientSecret = "BML8Q~Pka5aUTCXHzsEt2ZEJXlibD4ucvLwE9bda";
export const reportId = "80c6bddd-68bd-4c10-9771-afb157dfd2ef";
export const workspaceId = "fdc0f985-ff1e-4155-b4f3-9c8eb69ea0f4";
export const datasetId = "4a551b11-3bc5-4573-b60a-2bf7ddae5677";
// Scope of AAD app. Use the below configuration to use all the permissions provided in the AAD app through Azure portal.
// Refer https://aka.ms/PowerBIPermissions for complete list of Power BI scopes
export const scopes = ["https://analysis.windows.net/powerbi/api/Report.Read.All"];


