import React, { Fragment, useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Container, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, Image } from '../../../AbstractElements';
import { API_URL } from '../../Config/Config';
import { useForm } from 'react-hook-form';
import { Eye, EyeOff } from 'react-feather';
import { useNavigate } from 'react-router';
import ButtonLoader from '../ButtonLoader';

const VerificationEmail = () => {
    const [loading, setLoading] = useState(false)
    const { register, formState: { errors }, getValues, trigger, handleSubmit } = useForm()
    const [toggleConfirmPassword, setToggleConfirmPassword] = useState(false);
    const [toggleNewPassword, setToggleNewPassword] = useState(false);
    const navigate = useNavigate();

    const onSubmit = async (data) => {
        setLoading(true)
        const bodyData = {
            confirmationCode: data.otp,
            userName: email,
            newPassword: data.confirmPassword
        }
        const options = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(bodyData),
        }
        await fetch(`${API_URL}/confirmForgotPassword`, options)
            .then((res) => {
                res.json()
                    .then((json) => {
                        if (res.status === 200) {
                            toast.success(json.message)
                            setLoading(false)
                            setTimeout(() => {
                                navigate(`${process.env.PUBLIC_URL}/login`)
                            }, 2000)
                        } else if (res.status === 500) {
                            toast.success(json.message)
                            setSent(false)
                            setLoading(false)
                        }
                    })
            }, (error) => {
                console.log(error);
                if (error.status === 500) {
                    toast.error("Something went wrong")
                    setSent(false);
                    setLoading(false)
                }
            });
    }

    return (
        (<Fragment>
            <section>
                <Container className="p-0" fluid={true}>
                    <Row className='justify-content-center mt-5'>
                        <Col xl='4'>
                            <div className='login-main'>
                                <div className='center-logo'>
                                    <Image attrImage={{ className: 'img-fluid for-light', src: `${require('../../../assets/images/logo/app-logo@3x.png')}` }} />
                                    {/* <H6>Enter your email address and we'll send you a link to reset your password</H6> */}
                                </div>
                                <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                                    <FormGroup className='form-group'>
                                        <Label className="col-form-label">Verification Code <span className='required'>*</span></Label>
                                        <input
                                            className="form-control"
                                            type="otp"
                                            required={true}
                                            {...register('otp', { required: true })}
                                            onChange={e => setOtp(e.target.value)}
                                        />
                                        <span className='text-danger'>{errors.otp && errors.otp.type === 'required' && 'Please Enter Verification Code'}</span>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>New Password <span className='required'>*</span></Label>
                                        <input
                                            className="form-control mb-1"
                                            name="newPassword"
                                            onCopy={(e) => e.preventDefault()}
                                            onPaste={(e) => e.preventDefault()}
                                            type={toggleNewPassword ? 'text' : 'password'}
                                            {...register('newPassword', { required: true, pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/g })}
                                        />
                                        <div className="show-hide reset-password" onClick={() => setToggleNewPassword(!toggleNewPassword)}>
                                            <span>{toggleNewPassword ? <Eye className='show-icon' /> : <EyeOff className='show-icon' />}</span>
                                        </div>
                                        <span className="text-danger">{errors.newPassword && errors.newPassword.type === 'required' && 'Enter New Password'}</span>
                                        <span className="text-danger">{errors.newPassword && errors.newPassword.type === 'pattern' && 'Your password contains 8 characters, One Upper case, One Special characters, One Numeric Characters'}</span>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Confirm New Password <span className='required'>*</span></Label>
                                        <input
                                            className="form-control mb-1"
                                            name="confirmPassword"
                                            onChange={(e) => setPassword(e.target.value)}
                                            onCopy={(e) => e.preventDefault()}
                                            onPaste={(e) => e.preventDefault()}
                                            type={toggleConfirmPassword ? 'text' : 'password'}
                                            {...register('confirmPassword', { required: true, validate: (value) => getValues('newPassword') === value })}
                                        />
                                        <div className="show-hide reset-password" onClick={() => setToggleConfirmPassword(!toggleConfirmPassword)}>
                                            <span>{toggleConfirmPassword ? <Eye className='show-icon' /> : <EyeOff className='show-icon' />}</span>
                                        </div>
                                        <span className="text-danger">{errors.confirmPassword && errors.confirmPassword.type === 'required' && 'Enter Confirm Password'}</span>
                                        <span className="text-danger">{errors.confirmPassword && errors.confirmPassword.type === 'validate' && 'New password and Confirm password must be same'}</span>
                                    </FormGroup>
                                    <FormGroup className="form-group mb-0">
                                        <div className='text-end mt-3'>
                                            {
                                                loading ?
                                                    <ButtonLoader color='primary w-100' className={'btn-block'} loaderText={'Reseting...'} /> :
                                                    <Btn attrBtn={{ color: 'primary w-100', className: 'btn-block', type: 'submit' }}>{'Set Password'}</Btn>
                                            }
                                        </div>
                                    </FormGroup>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment >)
    );
}

export default VerificationEmail;