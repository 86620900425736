import { DETAILS, METHODOLOGY, DOCUMENTS_DETAILS, PROPONENT_DETAILS } from "../Constant"

const projectDetails = (state = {}, action) => {
    switch (action.type) {
        case DETAILS:
            return {
                ...state,
                detailsData: action.payload
            }
        case METHODOLOGY:
            return {
                ...state,
                methododlogyData: action.payload
            }
        case DOCUMENTS_DETAILS:
            return {
                ...state,
                documentData: action.payload
            }
        default: {
            return state;
        }
    }
}

export default projectDetails;