import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Image } from '../../../AbstractElements';
import { useNavigate } from 'react-router';
const Notification = () => {
    const navigate = useNavigate();

    return (
        <Fragment>
            <section>
                <Container className="p-0" fluid={true}>
                    <Row>
                        <Col xl='12'>
                            <Image attrImage={{ className: 'img-fluid for-light', src: `${require('../../../assets/images/logo/app-logo@3x.png')}` }} />
                        </Col>
                        <Col xl='12'>
                            <div className='row d-flex align-items-center h-100 notification-container'>
                                <div className='col-12 d-flex flex-column justify-content-center align-items-center'>
                                    <div
                                        className='text-dark fw-bold text-center'
                                        style={{ fontSize: 'calc(0.8rem + 1vw)' }}>
                                       Currently you are not subscribing any modules.
                                       <br /> Kindly contact Wave Connect admin for more details.
                                    </div>
                                    <div className='col-12 d-flex flex-column justify-content-center align-items-center pt-4'>
                                        <button className='btn btn-primary btn-block' onClick={() => navigate('/login')}>Log In</button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    )
}

export default Notification;