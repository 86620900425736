import React, { Fragment, useState, useEffect } from "react";
import { CardBody, InputGroupText } from "reactstrap";
import {
    Row,
    Card,
    Col,
    Form,
    FormGroup,
    Label,
    Button,
    InputGroup
} from "reactstrap";
import { H3 } from "../../../AbstractElements";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { countries } from "../../Data/CountryList/Defaultdata";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { toast } from "react-toastify";
import ButtonLoader from "../ButtonLoader";
import Multiselect from 'multiselect-react-dropdown';

let logoFile;

const AdminForm = ({ type, title, action, navigateUrl, cancel, preData }) => {
    const [loading, setLoading] = useState(false)
    const [selectedAmr, setSelectedAmr] = useState([]);
    const [amrOptions, setAmrOptions] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [country, setCountry] = useState();
    const [data, setData] = useState(preData)
    const { register, handleSubmit, formState: { errors }, reset, setValue, getValues, resetField } = useForm({ defaultValues: data });
    const [state, setState] = useState(preData?.state);
    const [currency, setCurrency] = useState();
    const [dialCode, setDialCode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState()

    const countryChangeEvent = (item, countryList) => {
        const selectedItem = countryList.find((countryItem) => countryItem.country === item);
        setCountry(item);
        setCurrency(selectedItem?.currency_code);
        setDialCode(selectedItem?.dial_code)
        setValue('currency', selectedItem?.currency_code);
        setStateList(selectedItem.states);
        setValue('state', '')
        setState();
    }
    // const [orgType, setOrgType] = useState('');
    const navigate = useNavigate();


    const onSubmit = async (data) => {
        setLoading(true)
        let url, message;
        let method = action === "Add" ? 'POST' : 'PUT';
        if (action === 'Add') {
            url = `${API_URL}/customer`
            message = 'Customer Created Successfully!'
        }
        if (action === 'Edit') {
            url = `${API_URL}/customer/${data.customerId}`
            message = 'Customer Updated Successfully!'
        }
        data.logo = logoFile;
        data.contactNumber = dialCode + ' ' + data?.contactNumber
        data.deviceID = selectedAmr?.map(item => item.key).join(',');
        delete data.deviceList;
        let formData = new FormData();
        Object.keys(data).forEach(key => {
            formData.append(key, data[key]);
        });
        const options = {
            method: method,
            body: formData,
            headers: {
                "Authorization": `Bearer ${sessionStorage.getItem('accessToken')}`
            }
        }
        await fetchWrapper(url, options)
            .then((res) => {
                if (res.ok) {
                    toast.success(message)
                    setLoading(false)
                    action === 'Add' && cancel()
                    action === 'Edit' && navigate(navigateUrl)
                }
            })
            .catch((err) => {
                setLoading(false)
                if (err.status == 409) {
                    toast.error('Customer Already Exist..')
                    console.log(err)
                } else if (err.status === 500) {
                    toast.error('Something went wrong')
                }
            })
    }

    useEffect(() => {
        setData(prev => ({ ...prev, ...preData }))
        getUnAllocatedDevices();
        reset(preData)
        if (preData) {
            let statesList = countries.find((item) => item.country === preData?.country)
            setDialCode(statesList?.dial_code)
            setStateList(statesList?.states)
            const selectedDevice = preData.deviceList?.map(item => { return { key: item.id, name: item.deviceName } });
            setSelectedAmr(selectedDevice);
            let phone = preData?.contactNumber.split(' ')[1];
            setPhoneNumber(phone)
            setValue('contactNumber', phone)
        }
    }, [preData])

    const getUnAllocatedDevices = async () => {
        await fetchWrapper(`${API_URL}/device/unallocated`)
            .then((res) => {
                let unAllocatedList = res.json?.map(item => { return { key: item.id, name: item.deviceName } });
                preData?.deviceList?.forEach(item => {
                    unAllocatedList.push({
                        key: item.id,
                        name: item.deviceName
                    })
                })
                setAmrOptions(unAllocatedList);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const onFileChange = (event) => {
        logoFile = event.target.files[0];
    };
    return (
        (<Fragment>
            <Row>
                <Col lg='12'>
                    {title && <H3 attrH3={{ className: 'mtop-2 ml-15' }}>{title}</H3>}
                    <Card className={`${action === 'Add' && 'mb-0'} ${action === 'Edit' && 'border-bottom-light '}`}>
                        <CardBody className={action === 'Add' && 'p-0 mb-0'}>
                            <Form
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <Row>
                                    <Col lg="6">
                                        <div>
                                            <FormGroup >
                                                <Label for="exampleEmail"> Customer Name <span className='required'>*</span> </Label>
                                                <input
                                                    className="form-control"
                                                    name="name"
                                                    placeholder=""
                                                    type="text"
                                                    defaultValue={data?.name || ''}
                                                    {...register('name', { required: true })}
                                                />
                                                <span className="text-danger">{errors.name && 'Customer Name is Required'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                    <Col lg='6'>
                                        <div>
                                            <FormGroup style={{ marginBottom: "8px " }}>
                                                <Label for="exampleSelect"> Address <span className='required'>*</span></Label>
                                                <input
                                                    className="form-control"
                                                    id="exampleEmail"
                                                    name="address"
                                                    placeholder=""
                                                    type="text"
                                                    defaultValue={data?.address || ''}
                                                    {...register('address', { required: true })}
                                                />
                                                <span className="text-danger">{errors.address && 'Address is Required'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <div>
                                            <FormGroup >
                                                <Label for="exampleSelect"> City <span className='required'>*</span></Label>
                                                <input
                                                    className="form-control"
                                                    id="city"
                                                    name="city"
                                                    placeholder=""
                                                    type="text"
                                                    defaultValue={data?.city || ''}
                                                    {...register('city', { required: true })}
                                                />
                                                <span className="text-danger">{errors.city && 'City is Required'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div>
                                            <FormGroup >
                                                <Label for="exampleSelect"> Country <span className='required'>*</span></Label>
                                                <select className="form-select" name="country"
                                                    defaultValue={data?.country || ''}
                                                    {...register('country', { required: true })}
                                                    onChange={e => countryChangeEvent(e.target.value, countries)}
                                                >
                                                    <option value=''>-Select Country-</option>
                                                    {countries.map((item, i) => {
                                                        return (
                                                            <option value={item.country}>{item.country}</option>
                                                        )
                                                    }
                                                    )}
                                                </select>
                                                <span className="text-danger">{errors.country && 'Country is Required'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <div>
                                            <FormGroup >
                                                <Label for="exampleSelect"> State <span className='required'>*</span></Label>
                                                <select className="form-select" name="state"
                                                    value={state}
                                                    {...register('state', { required: true })}
                                                    onChange={(e) => setState(e.target.value)}
                                                >
                                                    <option>-Select State-</option>
                                                    {
                                                        stateList && stateList.length > 0 && stateList.map(i => {
                                                            return (<option value={i}>{i}</option>)
                                                        })
                                                    }
                                                </select>
                                                <span className="text-danger">{errors.state && 'State is Required'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div>
                                            <FormGroup >
                                                <Label for="zipcode"> Zip Code <span className='required'>*</span></Label>
                                                <input
                                                    className="form-control"
                                                    id="zipcode"
                                                    name="zipcode"
                                                    type="text"
                                                    defaultValue={data?.zipcode || ''}
                                                    {...register('zipcode', { required: true, pattern: /^[0-9]{1,7}$/ })}
                                                />
                                                <span className="text-danger">{errors.zipcode?.type === 'required' && 'Zipcode is Required'}</span>
                                                <span className="text-danger">{errors?.zipcode?.type === 'pattern' && 'Invalid Zipcode'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>

                                    <Col lg='6'>
                                        <div>
                                            <FormGroup style={{ marginBottom: "8px " }}>
                                                <Label for="websiteURL"> Website <span className='required'>*</span> </Label>
                                                <input
                                                    className="form-control"
                                                    id="websiteURL"
                                                    name="websiteURL"
                                                    placeholder=""
                                                    type="text"
                                                    defaultValue={data?.websiteURL || ''}
                                                    {...register('websiteURL', { required: true, pattern: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ })}
                                                />
                                                <span className="text-danger">{errors?.websiteURL?.type === 'required' && 'Website is Required'}</span>
                                                <span className="text-danger">{errors?.websiteURL?.type === 'pattern' && 'Invalid Website URL'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                    <Col lg='6'>
                                        <div>
                                            <FormGroup style={{ marginBottom: "8px " }}>
                                                <Label for="exampleSelect"> Brand Logo </Label>
                                                <div>
                                                    <input
                                                        className="file-upload"
                                                        id="exampleEmail"
                                                        name="website"
                                                        onChange={onFileChange}
                                                        placeholder=""
                                                        accept=".png,.jpeg"
                                                        type="file"
                                                    />
                                                </div>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <div>
                                            <FormGroup >
                                                <Label for="zipcode">Currency </Label>
                                                <input
                                                    className="form-control"
                                                    id="currency"
                                                    name="currency"
                                                    type="text"
                                                    value={currency}
                                                    disabled={true}
                                                    {...register('currency')}
                                                />
                                                <span className="text-danger">{errors.currency?.type === 'required' && 'Currency is Required'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                </Row>
                                {console.log(errors)}
                                <div className="mb-20">
                                    <H3>Device Allocation</H3>
                                </div>
                                <Row>
                                    <Col lg='6'>
                                        <FormGroup >
                                            <Label for="exampleSelect"> AMR Device</Label>
                                            <Multiselect
                                                displayValue='name'
                                                placeholder="Select AMR"
                                                options={amrOptions}
                                                selectedValues={selectedAmr}
                                                onSelect={(event) => setSelectedAmr(event)}
                                                onRemove={(event) => setSelectedAmr(event)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <div className="mb-20">
                                    <H3>Contact Person Details</H3>
                                </div>
                                <Row>
                                    <Col lg='6'>
                                        <div>
                                            <FormGroup style={{ marginBottom: "8px " }}>
                                                <Label for="exampleSelect"> Contact Name <span className='required'>*</span></Label>
                                                <input
                                                    className="form-control"
                                                    id="contactName"
                                                    name="contactName"
                                                    placeholder=""
                                                    type="text"
                                                    defaultValue={data?.contactName || ''}
                                                    {...register('contactName', { required: true })}
                                                />
                                                <span className="text-danger">{errors.contactName && 'Contact Name is Required'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div>
                                            <FormGroup >
                                                <Label for="contactEmail"> Email ID  {action === 'Add' && <span className='required'>*</span>}</Label>
                                                <input
                                                    className="form-control"
                                                    id="contactEmail"
                                                    name="contactEmail"
                                                    placeholder=""
                                                    type="email"
                                                    disabled={action === 'Add' ? false : true}
                                                    defaultValue={data?.contactEmail || ''}
                                                    {...register('contactEmail', { required: true, pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ })}
                                                />
                                                <span className="text-danger">{errors?.contactEmail?.type === 'required' && 'Email is Required'}</span>
                                                <span className="text-danger">{errors?.contactEmail?.type === 'pattern' && 'Invalid Email'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <div>
                                            <FormGroup style={{ marginBottom: "8px " }}>
                                                <Label for="contactNumber"> Phone <span className='required'>*</span></Label>
                                                <InputGroup>
                                                    <InputGroupText>{dialCode}</InputGroupText>
                                                    <input
                                                    className="form-control"
                                                    id="contactNumber"
                                                    name="contactNumber"
                                                    placeholder=""
                                                    type="text"
                                                    prefix={dialCode}
                                                    value={phoneNumber}
                                                    {...register('contactNumber', { required: true, pattern: /^\d{7,10}$/g })}
                                                    onChange={(e)=>setPhoneNumber(e.target.value)}
                                                />
                                                </InputGroup>
                                                
                                                <span className="text-danger">{errors.contactNumber?.type === 'required' && 'Phone Number is Required'}</span>
                                                <span className="text-danger">{errors.contactNumber?.type === 'pattern' && 'Invalid Phone Number'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                    <Col lg='6'>
                                        <div >
                                            <FormGroup >
                                                <Label for="contactUserName"> User Name {action === 'Add' && <span className='required'>*</span>}</Label>
                                                <input
                                                    className="form-control"
                                                    id="contactUserName"
                                                    name="contactUserName"
                                                    placeholder=""
                                                    disabled={action === 'Add' ? false : true}
                                                    type="text"
                                                    defaultValue={data?.contactUserName || ''}
                                                    {...register('contactUserName', { required: true, pattern: /^(?=.{4,20}$)(?:[a-z\d]+(?:(?:\.|-|_)[a-zA-Z\d])*)+$/ })}
                                                />
                                                <span className="text-danger">{errors.contactUserName?.type === 'required' && 'User Name is Required'}</span>
                                                <span className="text-danger">{errors.contactUserName?.type === 'pattern' && 'User Name should be contain 4 - 20 characters and should be Alphanumeric(Lowercase only) and Underscore(_) only'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <div className="d-flex " style={{ marginTop: "25px" }}>
                                        {
                                            loading ? <ButtonLoader loaderText={`${action}ing`} color="primary" /> : <Button color="primary" >{action}</Button>

                                        }
                                        <Link to={navigateUrl}>
                                            <Button color="secondary" className="ml-1" onClick={cancel}> Cancel</Button>
                                        </Link>
                                    </div>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>)
    );
}

export default AdminForm;