import React, { Fragment, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Col, Container, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H6, Image } from '../../../AbstractElements';
import { API_URL } from '../../Config/Config';
import { EmailAddress } from '../../Constant';
import fetchWrapper from '../../api/fetchWrapper';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import ButtonLoader from '../ButtonLoader';

const ForgotPwd = () => {
    const [email, setEmail] = useState('');
    const [sent, setSent] = useState(false);
    const [loading, setLoading] = useState(false)
    const { register, formState: { errors }, getValues, trigger, handleSubmit } = useForm()
    const navigate = useNavigate();

    const handleSent = async () => {
        setLoading(true)
        if (email != '') {
            const requestOptions = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: ({
                    email: email
                })
            };
            await fetchWrapper(`https://wave-connect-api-new.azurewebsites.net/forgotPassword`, requestOptions)
                .then((res) => {
                    const data = res.json;
                    toast.success(data);
                    setLoading(false);
                }, (error) => {
                    if(error.status === 403) {
                        toast.error(error.json.ErrorMessage);
                    } else if(error.status === 200) {
                        sessionStorage?.setItem('email',email)
                        toast.success('Password reset succesfully.');
                        setTimeout(() => {
                        navigate(`${process.env.PUBLIC_URL}/login`)
                        }, 1000)
                    }
                    setLoading(false);
                });
        } else {
            setLoading(false)
            toast.error('Please Enter Email')
        }
    }

    return (
        (<Fragment>
            <ToastContainer />
            <section>
                <Container className="p-0" fluid={true}>
                    <Row>
                        <Col xl='12'>
                            <div className="login-card">
                                <div className='login-main'>
                                    <div className='logo'>
                                        <Image attrImage={{ className: 'img-fluid for-light', src: `${require('../../../assets/images/logo/app-logo@3x.png')}` }} />
                                        <H6>Enter your email address and we'll send you a link to reset your password</H6>
                                    </div>
                                    <Form className="theme-form">
                                        <FormGroup className='form-group'>
                                            <Label className="col-form-label">{EmailAddress} <span className='required'>*</span></Label>
                                            <input
                                                className="form-control"
                                                type="email"
                                                required={true}
                                                onChange={e => setEmail(e.target.value)}
                                            />
                                        </FormGroup>
                                        <FormGroup className="form-group mb-0">
                                            <div className='text-center mt-3'>
                                                {
                                                    loading ?
                                                        <ButtonLoader color='primary' className={'btn-block'} loaderText={'Submitting'} /> :
                                                        <Btn attrBtn={{ color: 'primary', className: 'btn-block', onClick: () => handleSent() }}>{'Submit'}</Btn>
                                                }
                                                <Btn attrBtn={{ color: 'secondary ms-3', className: 'btn-block', type: 'button', onClick: () => navigate(`${process.env.PUBLIC_URL}/login`)}} >Login</Btn>
                                            </div>
                                        </FormGroup>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>)
    );
}

export default ForgotPwd;