import React, { Fragment, useEffect, useState, useContext } from "react";
import {
    Container,
    Row,
    Card,
    Col,
    Button,
    CardBody
} from "reactstrap";
import { H3 } from "../../../AbstractElements";
import { GoBriefcase } from "react-icons/go";
import { FaRegAddressCard, FaUser } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { MdPhone } from "react-icons/md";
import { FaGlobe } from "react-icons/fa";
import { AiFillDollarCircle } from "react-icons/ai";
import { HiPencilSquare } from "react-icons/hi2";
import EditProfileModal from "./EditProfileModal";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { countries } from "../../Data/CountryList/Defaultdata";

const Profile = () => {
    const [open, setOpen] = useState(false);
    const [userProfile, setUserProfile] = useState();

    useEffect(()=>{
        if(sessionStorage.getItem('userType') === 'STORE_USER') {
            getUserDetails();
        } else {
            getCustomerDetails();
        }
    }, [])

    const getCustomerDetails = async () => {
        await fetchWrapper(`${API_URL}/customer/${sessionStorage.getItem('userId')}`)
            .then((res) => {
                setUserProfile(res.json)
            }, (err) => console.log(err))
    }

    const getUserDetails = async () => {
        await fetchWrapper(`${API_URL}/user/${sessionStorage.getItem('userId')}`)
            .then((res) => {
                const profile = res.json;
                setUserProfile({
                    contactName: `${profile.firstName} ${profile.lastName}`,
                    contactEmail: profile.email,
                    contactNumber: formatPhoneNumber(profile.mobileNumber),
                    userType: profile.userType,
                    currency: sessionStorage.getItem('userCurrency')
                })
            }, (err) => console.log(err))
    }

    const formatPhoneNumber = (mobileNumber) => {
        if(mobileNumber && mobileNumber.indexOf('+') === -1) {
            const selectedItem = countries.find((item) => item.country === sessionStorage.getItem('userCountry'));
            return `${selectedItem?.dial_code} ${mobileNumber}`;
        } else {
            return mobileNumber;
        }
    }


    return (
        <Fragment>
            <Container>
                <div className="d-flex">
                    <div className="page-title">
                        <H3>Profile Details</H3>
                    </div>
                    {sessionStorage.getItem('userType') === 'STORE_ADMIN' ?
                    <Button color="secondary" onClick={()=>setOpen(true)} className="action_button edit-button"><HiPencilSquare className="mr-1" height={50} width={50}/>Edit</Button>
                    : <></> }
                </div>
                <Row>
                    {sessionStorage.getItem('userType') === 'STORE_ADMIN' ?
                    <Col lg='6'>
                        <Card>
                            <CardBody className="profile-card-padding">
                                <div className="store-profile ">Company Details</div>
                                <div className="border-bottom"></div>
                                <div className="ml-1 mtop-1">
                                    <div>
                                        <div className="d-flex profile-title">
                                            <GoBriefcase /><div className="ml-1"> Name </div>
                                        </div>
                                        <div className="ml-2 mtop-1">{userProfile?.name}</div>
                                    </div>
                                    <div>
                                        <div className="d-flex profile-title mtop-1">
                                            <FaRegAddressCard /><div className="ml-1"> Address </div>
                                        </div>
                                        <div className="ml-2 mtop-1">{userProfile?.address},</div>
                                        <div className="ml-2">{userProfile?.city} - {userProfile?.zipcode},</div>
                                        <div className="ml-2">{userProfile?.state},</div>
                                        <div className="ml-2">{userProfile?.country}</div>
                                    </div>
                                    <div className="mtop-1 d-flex">
                                        <div className="d-flex profile-title">
                                            <FaGlobe /><div className="ml-1"><a href={userProfile?.websiteURL} target="_blank">{userProfile?.websiteURL}</a> </div>
                                        </div>
                                        {/* <div className="ml-2">{userProfile?.websiteURL}</div> */}
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col> : <></>
                    }
                    <Col lg='6'>
                        <Card>
                            <CardBody className="sec-card-padding">
                                <div className="store-profile">Contact Details</div>
                                <div className="border-bottom"></div>
                                <div className="ml-1 mtop-1">
                                    <div>
                                        <div className="d-flex profile-title">
                                            <FaUser /><div className="ml-1">Name </div>
                                        </div>
                                        <div className="ml-2 mtop-1">{userProfile?.contactName}</div>
                                    </div>
                                    <div className="mtop-1">
                                        <div className="d-flex profile-title">
                                            <IoMail /><div className="ml-1">Email </div>
                                        </div>
                                        <div className="ml-2 mtop-1">{userProfile?.contactEmail}</div>
                                    </div>
                                    <div className="mtop-1">
                                        <div className="d-flex profile-title">
                                            <MdPhone /><div className="ml-1">Phone Number </div>
                                        </div>
                                        <div className="ml-2 mtop-1">{userProfile?.contactNumber}</div>
                                    </div>
                                    <div className="mtop-10px d-flex">
                                        <div className="d-flex profile-title">
                                            <AiFillDollarCircle />
                                            <div className="ml-1">{userProfile?.currency} </div>
                                        </div>
                                        {/* <div className="ml-2">{userProfile?.currency}</div> */}
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                { open && <EditProfileModal value={open} onCloseModal={(e) => {e && getCustomerDetails(); setOpen(false)}} preData={userProfile}/> }
            </Container>
        </Fragment>
    )
}

export default Profile;