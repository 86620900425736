import React, { Fragment, useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next'
import { LI, UL } from '../../../AbstractElements';
import { Back } from '../../../Common/Constant';
import { MenuItem } from './Menu';
import SidebarMenulist from './SidebarMenulist';
import { useStore } from 'react-redux';
import {SubscriptionContext} from '../../../Common/api/helper';
import { useNavigate } from 'react-router-dom';

const SidebarMenuItems = ({ wrapper, margin }) => {
  const Navigate = useNavigate();
  const { t } = useTranslation();
  const [menuList, setMenuList] = useState();
  const subscriptionContext = useContext(SubscriptionContext);

  let userType = useStore().getState().user?.userType;
  userType = userType ? userType : sessionStorage.getItem('userType');

  useEffect(() => {
    if (userType === 'STORE_USER' || userType === 'STORE_ADMIN') {
      getSubscribeMenu()
    } else {
      setMenuList(MenuItem[userType])
    }
  }, [])

  const getSubscribeMenu = () => {
    if (subscriptionContext && subscriptionContext.length) {
      let subscribedMenu = MenuItem[userType][0].Items.filter(item =>
        subscriptionContext.find(sub => sub.moduleID === item.id && sub.isEnabled)
      );

      if (userType === 'STORE_ADMIN') {
        subscribedMenu.push(...MenuItem[userType][0].defaultMenu)
      } else if(userType === 'STORE_USER') {
        subscribedMenu = subscribedMenu.map(item => {
          item.children = item.children.filter(child => child.title !== 'Stores');
          return item;
        });
      }

      if (subscribedMenu.length) {
        MenuItem[userType][0].Items = subscribedMenu
        setMenuList(MenuItem[userType])
      } else {
        unSubscribeUser();
      }
    }
  }

  const unSubscribeUser = () => {
    // toast.error('Not subscribe any module for this organization. Please contact to wave-connecr admin.');
    // logoutHelper()
    Navigate(`${process.env.PUBLIC_URL}/notification`);
  }

  const toggletNavActive = (item) => {
    if (window.innerWidth <= 991) {
      document.querySelector('.page-header').className = 'page-header close_icon';
      document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper close_icon ';
      if (item.type === 'sub') {
        document.querySelector('.page-header').className = 'page-header ';
        document.querySelector('.sidebar-main').className = 'sidebar-main ';
      }
    }
  };

  return (
    <Fragment>
      <UL attrUL={{ className: 'sidebar-links simple-list', id: 'simple-bar' }}>
        <div className="simplebar-wrapper" style={{ margin: '0px' }}>
          <div className="simplebar-height-auto-observer-wrapper">
            <div className="simplebar-height-auto-observer"></div>
          </div>
          <div className="simplebar-mask">
            <div className="simplebar-offset">
              <div className='simplebar-content-wrapper custom-scrollbar' style={{ height: '100%', overflow: 'hidden scroll' }}>
                <div className='simplebar-content p-0' style={wrapper.split(' ').includes('horizontal-wrapper') ? { marginLeft: margin + 'px' } : { margin: '0px' }}>
                  <LI attrLI={{ className: 'back-btn' }}>
                    <div className="mobile-back text-end"><span>{Back}</span><i className="fa fa-angle-right ps-2"></i></div>
                  </LI>
                  {menuList && <SidebarMenulist MENUITEMS={menuList} t={t} toggletNavActive={toggletNavActive} />}
                </div>
              </div>
            </div>
          </div>
          <div className="simplebar-placeholder" style={{ width: 'auto', height: '54px' }}></div>
        </div>
        <div className="simplebar-track simplebar-horizontal" style={{ visibility: 'hidden' }}>
          <div className="simplebar-scrollbar" style={{ width: 0, display: 'none' }}></div>
        </div>
        <div className="simplebar-track simplebar-vertical" style={{ visibility: 'visible' }}>
          <div className="simplebar-scrollbar" style={{ height: 77, transform: 'translate3d(0, 304, 0)', display: 'block' }}></div>
        </div>
      </UL >
    </Fragment >
  );
};
export default SidebarMenuItems;
