import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import DataTableComponent from '../../Common/Component/DataTable/DataTableComponent';
import { Link, useParams } from 'react-router-dom';
import { Edit, MapPin } from 'react-feather';
import AddStore from './AddStore';
import BulkUpload from './BulkUpload';
import StoreLocation from './StoreLocation';
import { LocationDetails } from '../../Common/Constant';
import Sidebar from '../Sidebar/index';
import DeviceDetailsModal from '../../Admin/Customer/DeviceDetails';
import AgGridTable from '../../Common/Component/AgGridTable';
import { useRef } from 'react';
import fetchWrapper from '../../Common/api/fetchWrapper';
import { API_URL } from '../../Common/Config/Config';
import { countries } from "../../Common/Data/CountryList/Defaultdata";
import { toast } from 'react-toastify';
import { capitalize } from '../../Common/api/helper'; 

const StoreMgmtlist = () => {
    const [deviceModelOpen, setDeviceModelOpen] = useState(false);
    const [deviceList, setDeviceList] = useState();
    const [open, setOpen] = useState(false);
    const [openLocation, setOpenLocation] = useState(false);
    const [openBulk, setOpenBulk] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [data, setData] = useState();
    const [storeData, setStoreData] = useState();
    const [loading, setLoading] = useState(false)
    const [orgData, setOrgData] = useState();
    const storeRef = useRef(null);
    const [activeData, setactiveData] = useState('')
    const type = useParams()?.type;

    const loadData = async () => {
        storeRef?.current?.api?.showLoadingOverlay();
        await fetchWrapper(`${API_URL}/store/type/${type === 'apparel' ? 'APPAREL' : 'JEWELLERY'}`)
            .then((res) => {
                setStoreData(res.json)
                setData(res.json)
                storeRef?.current?.api?.hideOverlay();
            })
            .catch((err) => {
                storeRef?.current?.api?.hideOverlay();
                console.error(err)
            })
    }


    const deviceDetailsClick = (value, id) => {
        setDeviceList(id);
        setDeviceModelOpen(true);
    }


    const handleLocation = (data) => {
        setOpenLocation(true)
        setactiveData(data)
    }

    const formatPhoneNumber = (data) => {
        console.log(capitalize(data.country))
        if(data.mobileNumber && data.mobileNumber.indexOf('+') === -1) {
            const selectedItem = countries.find((item) => item.country === capitalize(data.country));
            return `${selectedItem?.dial_code} ${data.mobileNumber}`;
        } else {
            return data.mobileNumber;
        }
    }

    const handleStatus = async (data) => {
        let updateStatus = data?.active === 'TRUE' ? 'FALSE' : 'TRUE';
        const options = {
            method: 'PUT'
        }
        await fetchWrapper(`${API_URL}/store/status/${data?.id}/${updateStatus}`, options)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    console.log(res.json)
                    if (res?.json?.active === 'FALSE') {
                        toast.error('Store has been disabled!')
                    } else {
                        toast.success('Store has been enabled!')
                    }
                    loadData();
                }
            })
            .catch(err => console.log(err))
    }


    const columnData = [
        {
            headerName: 'Store Location',
            field: 'name',
            suppressMovable: true,
            flex: 2,
            cellRenderer: (params) => {
                return (
                    <div className='d-flex'>
                        <MapPin className="mr-1" onClick={() => handleLocation(params.data)} /><span>{params.data?.name}</span>
                    </div>
                )
            },
        },
        {
            headerName: 'Email',
            field: 'email',
            filter: true,
            sortable: true,
            flex: 2,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Phone Number',
            valueGetter: (params) => {
                return formatPhoneNumber(params.data);
            },
            filter: true,
            sortable: true,
            suppressMovable: true,
            flex: 2,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Allocated Devices',
            filter: false,
            sortable: true,
            width: 150,
            suppressMovable: true,
            cellRenderer: (params) => {
                return (
                    <>
                        {
                            params.data?.allocatedDevices > 0 ? <div className='allocated-device-count' onClick={() => deviceDetailsClick(params.data?.allocatedDevices, params.data?.id)}>
                                {params.data?.allocatedDevices}
                            </div> : <div className='text-center w-100'>{0}</div>
                        }
                    </>
                )
            }
        },
        {
            headerName: 'Status',
            filter: false,
            sortable: true,
            suppressMovable: true,
            width: 100,
            cellRenderer: (params) => {
                return (
                    <div className='mr-21'>
                        <label className="switch">
                            <input type="checkbox" id="togBtn" defaultChecked={params.data?.active === 'TRUE' ? true : false}
                                onChange={() => handleStatus(params.data)}
                            />
                            <div className="slider round">
                                <span className="on"></span>
                                <span className="off"></span>
                            </div>
                        </label>
                    </div>
                )
            }
        },
    ]

    const handleClick = () => {
        setOrgData(undefined);
        setOpen(true);
    }
    const handleBuldClick = () => setOpenBulk(true);
    const onGridReady = useCallback(async (params) => {
        loadData();
    })

    const onGridSizeChanged = useCallback((params) => {
        // get the current grids width
        var gridWidth = document.getElementById('grid-wrapper').offsetWidth;
        // keep track of which columns to hide/show
        var columnsToShow = [];
        var columnsToHide = [];
        // iterate over all columns (visible or not) and work out
        // now many columns can fit (based on their minWidth)
        var totalColsWidth = 0;
        var allColumns = params.api.getColumns();
        if (allColumns && allColumns.length > 0) {
            for (var i = 0; i < allColumns.length; i++) {
                var column = allColumns[i];
                totalColsWidth += column.getMinWidth() || 0;
                if (totalColsWidth > gridWidth) {
                    columnsToHide.push(column.getColId());
                } else {
                    columnsToShow.push(column.getColId());
                }
            }
        }
        // show/hide columns based on current grid width
        params.api.setColumnsVisible(columnsToShow, true);
        params.api.setColumnsVisible(columnsToHide, false);
        // fill out any available space to ensure there are no gaps
        params.api.sizeColumnsToFit();
    }, []);
    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col sm="12 store-container">
                        <div className='d-flex'>
                            <Breadcrumbs mainTitle="Users" parent="Store Management" title="List" />
                            <div className="text-right  action_button">
                                <div className="link action_button" onClick={handleClick}>
                                    <i style={{ fontSize: '25px', color: "#2b7704" }} className="icofont  icofont-plus-circle" ></i>
                                    &nbsp;&nbsp;{"Add Store"}</div>
                            </div>
                            <div className="text-right  action_button ml-1">
                                <div className="link action_button" onClick={handleBuldClick}>
                                    {
                                        loading ? <i className="fa fa-spinner fa-spin"></i> : <i style={{ fontSize: '25px', color: "#2b7704" }} className="icofont  icofont-plus-circle" ></i>
                                    }
                                    &nbsp;&nbsp;{"Bulk Upload"}</div>
                            </div>
                        </div>
                        <Row>
                            {storeData && <Col lg="2" className='pe-0'><Sidebar data={storeData} setData={setData} /></Col>}
                            <Col lg="10">
                                <div className="store-table">
                                    <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                        <AgGridTable
                                            columnData={columnData}
                                            tableData={data}
                                            agRef={storeRef}
                                            onGridReady={onGridReady}
                                            onGridSizeChanged={onGridSizeChanged}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    {open && <AddStore value={open} closeModal={(e) => {e && loadData(); setOpen(false)}} data={orgData} type={type} />}
                    {openLocation && <StoreLocation value={openLocation} setOpenModal={setOpen} data={activeData} />}
                    {openBulk && <BulkUpload value={openBulk} closeModal={(e) => {e && loadData(); setOpenBulk(false)}} type={'store'} storeType={type}/>}
                    {deviceModelOpen && <DeviceDetailsModal value={deviceModelOpen} setOpenModal={setDeviceModelOpen} 
                        data={deviceList} type={'store'} />}
                </Row>
            </Container>
        </Fragment>
    );

};

export default StoreMgmtlist;