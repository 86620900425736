import { USER_TYPE, USER_TOKEN, USER_DETAILS } from "../Constant"

const user = (state = {}, action) => {
    switch (action.type) {
        case USER_TYPE:
            return {
                ...state,
                userType: action.payload
            }
        case USER_TOKEN:
            return {
                ...state,
                token: action.payload,
                authenticated: sessionStorage.getItem('accessToken') ? true : false
            }
        case USER_DETAILS:
            return {
                ...state,
                userDetails: action.payload
            }
        default: {
            return state;
        }
    }
}

export default user;