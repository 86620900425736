import React, { Fragment, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { H3 } from "../../AbstractElements";
import Dropzone from "react-dropzone-uploader";
import { X } from "react-feather";
import {
    Container,
    Row,
    Card,
    Col,
    Form,
    FormGroup,
    Label,
    Button
} from "reactstrap";
import fetchWrapper from "../../Common/api/fetchWrapper";
import { API_URL } from "../../Common/Config/Config";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

const BulkUpload = ({ value, closeModal, type, storeType, categoryList, storeList }) => {
    const [open, setOpen] = useState(value);
    const [isLocalImport, setIsLocalImport] = useState(true);
    const action = 'Add';
    const [data, setData] = useState({});
    const { register, formState: { errors }, reset, getValues, handleSubmit } = useForm();
    const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }
    const [storeId, setStoreId] = useState();
    const [categoryId, setCategoryId] = useState();
    const [loading, setLoading] = useState(false);

    const onClose = (update) => {
        setOpen(false);
        closeModal(update)
    }

    const handleChange = async (files) => {
        setLoading(true)
        const authToken = sessionStorage.getItem('accessToken')
        let data = new FormData();
        data.append('file', files[0]?.file);
        data.append('uploadType', type === 'store' ? 'BULKSTOREDATA' : type === 'inventoryApparel' ? 'BULKUPLOADAPPAREL' : 'BULKUPLOADJEWELLERY')
        if (type !== 'store') {
            if (storeId && categoryId) {
                data.append('storeId', storeId);
                data.append('productCategory', categoryId);
            } else {
                toast.error('Please select store and product category');
                setLoading(false)
                return false;
            }
        } else {
            data.append('productCategory', storeType === 'apparel' ? 'APPAREL' : 'JEWELLERY')
        }
        const options = {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${authToken}`
            },
            body: data
        }
        await fetchWrapper(`${API_URL}/bulkUpload/uploadData`, options)
            .then((res) => {
                if (res.status === 200) {
                    toast.success('Document Uploaded successfully!')
                    onClose(true)
                } else if (res.json?.length > 0) {
                    toast.error('Valid records loaded, skipped few records as they already existed or missing mandatory fields')
                    onClose(false)
                }
            })
            .catch((error) => {
                if (error.status === 200) {
                    toast.success('Document Uploaded successfully!')
                    onClose(true)
                } else if (error.json?.length > 0) {
                    toast.error('Valid records loaded, skipped few records as they already existed or missing mandatory fields')
                    onClose(false)
                }
                if (error.status == 412) {
                    setLoading(false)
                    toast.error('File with the same name already exists')
                }
                if (error.status === 403) {
                    setLoading(false)
                    toast.error('Invalid file format.')
                }
            })
    };

    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size='lg'>
                <ModalHeader style={{ display: 'flex' }}>
                    <H3 attrH3={{ className: 'modal-title-color' }}>Bullk Upload <X className="x-button" onClick={() => onClose(false)} /></H3>
                </ModalHeader>
                <ModalBody>
                    <Fragment>
                        {
                            type !== 'store' && <Row>
                                {sessionStorage.getItem('userType') !== 'STORE_USER' ?
                                <Col lg="6">
                                    <FormGroup style={{ marginBottom: "8px" }}>
                                        <Label>Store <span className='required'>*</span></Label>
                                        <select
                                            className="form-select"
                                            name="storeId"
                                            value={storeId}
                                            {...register('storeId', { required: true })}
                                            onChange={(e) => setStoreId(e.target.value)}
                                        >
                                            <option value={''}>- Select Store -</option>
                                            {
                                                storeList?.map((item) => <option value={item?.id}>{item?.name}</option>)
                                            }
                                        </select>
                                        <span className="text-danger">{errors?.storeId && 'Store is Required'}</span>
                                    </FormGroup>
                                </Col> : <></>}
                                <Col lg="6">
                                    <FormGroup style={{ marginBottom: "8px" }}>
                                        <Label>Product Category <span className='required'>*</span></Label>
                                        <select
                                            className="form-select"
                                            name="categoryId"
                                            value={categoryId}
                                            {...register('categoryId', { required: true })}
                                            onChange={(e) => setCategoryId(e.target.value)}
                                        >
                                            <option value={''}>- Select Category -</option>
                                            {
                                                categoryList?.map((item) => <option value={item?.key}>{item?.name}</option>)
                                            }
                                        </select>
                                        <span className="text-danger">{errors?.categoryId && 'Product Category is Required'}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                        }

                        <Row className="mb-2">
                            <Col lg="3">
                                <h5>Import Data From: </h5>
                            </Col>
                            <Col lg="2">
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={isLocalImport} onClick={() => setIsLocalImport(!isLocalImport)} />
                                    <label className="form-check-label" for="flexRadioDefault1">
                                        Local File
                                    </label>
                                </div>
                            </Col>
                            <Col lg="3">
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" disabled={true} checked={!isLocalImport} onClick={() => setIsLocalImport(!isLocalImport)} />
                                    <label className="form-check-label" for="flexRadioDefault2">
                                        Remote FTP Server
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg='12'>
                                {
                                    isLocalImport ? <>
                                        {loading ? <div className="text-center loading-text">Loading....</div> : <></>}
                                        <div style={{ opacity: loading ? '0.2' : '1' }}>
                                            <Dropzone
                                                accept='.xlsx,.xls,.json'
                                                maxFiles={1}
                                                getUploadParams={getUploadParams}
                                                onSubmit={handleChange}
                                                multiple={false}
                                                maxSizeBytes={4194304}
                                                inputContent="Drag and Drop Files"
                                            />
                                            <p className='no-wrap mtop-1'>[Note:Supported formats xlsx, xls and Json]</p>
                                        </div></> : <Form>
                                        <Row>
                                            <Col lg='6'>
                                                <div style={{ marginBottom: "35px" }}>
                                                    <FormGroup style={{ marginBottom: "8px" }}>
                                                        <Label for="exampleSelect">Host Name/IP <span className='required'>*</span></Label>
                                                        <input
                                                            className="form-control"
                                                            name="host_name"
                                                            type="text"
                                                            defaultValue={data?.host_name || ''}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            </Col>
                                            <Col lg="6">
                                                <div style={{ marginBottom: "35px" }}>
                                                    <FormGroup >
                                                        <Label for="exampleEmail">  FTP Port <span className='required'>*</span></Label>
                                                        <input
                                                            className="form-control"
                                                            name="ftp_port"
                                                            placeholder=""
                                                            type="text"
                                                            required
                                                            defaultValue={data?.ftp_port || ''}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg='6'>
                                                <div style={{ marginBottom: "35px" }}>
                                                    <FormGroup style={{ marginBottom: "8px " }}>
                                                        <Label for="exampleSelect">Remote Image Folder</Label>
                                                        <input
                                                            className="form-control"
                                                            name="image_folder"
                                                            placeholder=""
                                                            type="text"
                                                            defaultValue={data?.image_folder || ''}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            </Col>
                                            <Col lg="6">
                                                <div style={{ marginBottom: "35px" }}>
                                                    <FormGroup >
                                                        <Label for="exampleEmail">  Remote File Folder <span className='required'>*</span></Label>
                                                        <input
                                                            className="form-control"
                                                            name="file_folder"
                                                            placeholder=""
                                                            type="text"
                                                            required
                                                            defaultValue={data?.file_folder || ''}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="6">
                                                <div style={{ marginBottom: "35px" }}>
                                                    <FormGroup >
                                                        <Label for="exampleEmail">  User Name {action === 'Add' && <span className='required'>*</span>}</Label>
                                                        <input
                                                            className="form-control"
                                                            name="username"
                                                            placeholder=""
                                                            type="text"
                                                            required
                                                            defaultValue={data?.username || ''}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            </Col>
                                            <Col lg="6">
                                                <div style={{ marginBottom: "35px" }}>
                                                    <FormGroup >
                                                        <Label for="exampleEmail">  Password {action === 'Add' && <span className='required'>*</span>}</Label>
                                                        <input
                                                            className="form-control"
                                                            name="password"
                                                            placeholder=""
                                                            type="password"
                                                            required
                                                            defaultValue={data?.password || ''}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <div className="d-flex" >
                                                <Button color="secondary" >Import</Button>
                                            </div>
                                        </Row>
                                    </Form>
                                }
                            </Col>
                        </Row>
                    </Fragment>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default BulkUpload;