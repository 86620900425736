import React, { Fragment, useState } from "react";
import {
    Card, CardBody, Col, Row
} from 'reactstrap';
import Chart from 'react-apexcharts';

const DeviceChart = (props) => {
    const [selectedItem, setSelectedItem] = useState(props.default);

    const onSelectCard = (item) => {
        setSelectedItem(item);
        props.selectedCard(item);
    }

    return (
        <Fragment>
            <Row className="justify-space-even">
                {
                    props.deviceSummary?.map((item, i) => {
                        return (
                            <Col lg="3" sm="6" key={i}>
                                <Card className={selectedItem?.name !== item.name ? "sale-chart active-card" : "sale-chart"} onClick={() => onSelectCard(item)}>
                                    <CardBody className="p-0">
                                        <div className="d-flex">
                                            <div className="sales-chart" id="sales-chart">
                                                <Chart options={item.chart.options} series={item.chart.series} type="radialBar" height={300} width={300} />
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        )
                    })
                }
            </Row>
        </Fragment>
    );
};
export default DeviceChart;