import React, { Fragment } from 'react';
import { Col, Container, Row} from 'reactstrap';
import LoginForm from './LoginForm';
import img from '../../../assets/images/bg-img.jpeg' 

const LoginComponent = () => {
    return (
        <Fragment>
            <Container fluid={true} className="p-0">
                <Row className="m-0">
                <Col xl='12' className='b-center bg-size' style={{ backgroundImage: `url(${img})`, 
                    backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', display: 'block' }}>
                        <div className="login-card">
                            <LoginForm/>                          
                        </div>
                </Col>
                </Row>
            </Container>
        </Fragment>
    );
};
export default LoginComponent;