import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Collapse, Input, Label } from 'reactstrap';
import { capitalize } from '../../Common/api/helper';


const Filter = ({ storeData, setData }) => {
    const [locations, setLocations] = useState([]);

    const handleFilterData = () => {
        console.log(storeData)
        let objects = [], total = storeData?.length
        const cityList = storeData?.map((item) => {
            return item.city
        })
        const cityCounts = {};

        cityList?.forEach(city => {
            const formattedCity = city?.toUpperCase();
            cityCounts[formattedCity] = (cityCounts[formattedCity] || 0) + 1;
        });

        objects = Object?.keys(cityCounts).map(city => ({
            state: city,
            count: cityCounts[city],
            checked: true
        }));
        setLocations(objects)
    }

    useEffect(() => {
        handleFilterData(storeData)
    }, [])

    const handleChange = (value, checked) => {
        let locationData = [...locations].map((item) => {
            if (item.state === value) {
                item.checked = checked;
            }
            return item;
        });
        setLocations(locationData);
        let getData = [];
        locationData?.forEach(ele => {
            if (ele.checked) {
                const data = storeData?.filter((item) => item?.city?.toUpperCase() === ele.state)
                getData = [...getData, ...data];
            }
        });
        setData(getData);
    }

    useEffect(() => { }, [locations])

    return (
        <Fragment>
            <Col xl="12">
                <Card>
                    <CardHeader>
                        <h5 className='mb-0 p-0' >
                            {storeData?.length} stores
                        </h5>
                    </CardHeader>
                    <Collapse isOpen={true}>
                        <CardBody className="filter-cards-view animate-chk">
                            {/* <div className="job-filter mb-3">
                                <div className="faq-form">
                                    <Input className="form-control" type="text" placeholder="Search.." />
                                </div>
                            </div> */}
                            <div className="checkbox-animated mtop-0">
                                {locations.map((item, index) => {
                                    return <Label className={"d-block"} key={index} for="chk-ani">
                                        <input className="checkbox_animated" id="chk-ani" type="checkbox" defaultChecked={item?.checked}
                                            onChange={(e) => handleChange(item.state, e.target.checked)} />
                                        {capitalize(item?.state)} ({item.count})
                                    </Label>
                                })}
                            </div>
                        </CardBody>
                    </Collapse>
                </Card>
            </Col>
        </Fragment >
    );
};

export default Filter;