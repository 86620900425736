import { UserPlus, Settings } from 'react-feather';
import { HiOutlineShoppingCart } from 'react-icons/hi';
import { HiOutlineOfficeBuilding } from 'react-icons/hi';
import { FiGrid, FiBox, FiBriefcase, FiUsers, FiClipboard, FiTablet } from 'react-icons/fi';

const STORE_ADMIN = [
    {
        menutitle: '',
        menucontent: 'Dashboards,Widgets',
        Items: [
            {
                title: 'Retail - Apparel', id: 'MOD-B90X755BE3', icon: FiBox, badge: 'true', type: 'sub', active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/store-admin/store-mgmt/apparel`, title: 'Stores', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/store-admin/inventory-mgmt-apparel`, title: 'Inventory', type: 'link' },
                    {
                        title: 'Shelf Insights', badge: 'false', type: 'sub', active: false, children: [
                            // { path: `${process.env.PUBLIC_URL}/store-admin/apparel/summary-report   `, title: 'Summary', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/store-admin/apparel/sales-trends`, title: 'Sales Trends', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/store-admin/apparel/top-selling`, title: 'Top Selling', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/store-admin/apparel/performance`, title: 'Performance', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/store-admin/apparel/shelf-placement`, title: 'Planogram', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/store-admin/apparel/inventory-trunover`, title: 'Inventory Status', type: 'link' },
                        ]
                    },
                    // { path: `${process.env.PUBLIC_URL}/store-admin/planogram-compliance`, title: 'Planogram Compliance', type: 'link' },
                    // { path: `${process.env.PUBLIC_URL}/store-admin/assortment`, title: 'Assortment Optimizer', type: 'link' }
                ]
            },
            {
                title: 'Retail - Jewellery', id: 'MOD-75EU88KOAF', icon: FiBriefcase, badge: 'true', type: 'sub', active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/store-admin/store-mgmt/jewellery`, title: 'Stores', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/store-admin/inventory-mgmt-jewellery`, title: 'Inventory', type: 'link' },
                    {
                        title: 'Shelf Insights', badge: 'false', type: 'sub', active: false, children: [
                            // { path: `${process.env.PUBLIC_URL}/store-admin/summary-report`, title: 'Summary', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/store-admin/sales-trends`, title: 'Sales Trends', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/store-admin/top-selling`, title: 'Top Selling', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/store-admin/performance`, title: 'Performance', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/store-admin/shelf-placement`, title: 'Planogram', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/store-admin/inventory-trunover`, title: 'Inventory Status', type: 'link' },
                        ]
                    },
                    // { path: `${process.env.PUBLIC_URL}/store-admin/planogram-compliance`, title: 'Planogram Compliance', type: 'link' },
                    // { path: `${process.env.PUBLIC_URL}/store-admin/assortment`, title: 'Assortment Optimizer', type: 'link' }
                ]
            }
        ],
        defaultMenu: [
            {
                title: 'User Mgmt', icon: UserPlus, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/store-admin/user-list`
            },
            {
                title: 'Device Mgmt', icon: FiTablet, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/store-admin/amr-mgmt`
            },
            {
                title: 'Settings', id: 1, icon: Settings, badge: 'true', type: 'sub', active: false, children: [
                    // { path: `${process.env.PUBLIC_URL}/store-admin/threshold-mgmt`, title: 'Threshold Mgmt', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/store-admin/product-category-management`, title: 'Product Category Mgmt', type: 'link' },
                    // { path: `${process.env.PUBLIC_URL}/store-admin/tag-management`, title: 'Tag Mgmt', type: 'link' },
                ]
            },
        ]
    }
];

const STORE_USER = [
    {
        menutitle: '',
        menucontent: 'Dashboards,Widgets',
        Items: [
            {
                title: 'Retail - Apparel', id: 'MOD-B90X755BE3', icon: FiBox, badge: 'true', type: 'sub', active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/store-user/store-mgmt/apparel`, title: 'Stores', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/store-user/inventory-mgmt-apparel`, title: 'Inventory', type: 'link' },
                    {
                        title: 'Shelf Insights', badge: 'false', type: 'sub', active: false, children: [
                            // { path: `${process.env.PUBLIC_URL}/store-admin/apparel/summary-report   `, title: 'Summary', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/store-user/apparel/sales-trends`, title: 'Sales Trends', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/store-user/apparel/top-selling`, title: 'Top Selling', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/store-user/apparel/performance`, title: 'Performance', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/store-user/apparel/shelf-placement`, title: 'Planogram', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/store-user/apparel/inventory-trunover`, title: 'Inventory Status', type: 'link' },
                        ]
                    },
                    // { path: `${process.env.PUBLIC_URL}/store-admin/planogram-compliance`, title: 'Planogram Compliance', type: 'link' },
                    // { path: `${process.env.PUBLIC_URL}/store-admin/assortment`, title: 'Assortment Optimizer', type: 'link' }
                ]
            },
            {
                title: 'Retail - Jewellery', id: 'MOD-75EU88KOAF', icon: FiBriefcase, badge: 'true', type: 'sub', active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/store-user/store-mgmt/jewellery`, title: 'Stores', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/store-user/inventory-mgmt-jewellery`, title: 'Inventory', type: 'link' },
                    {
                        title: 'Shelf Insights', badge: 'false', type: 'sub', active: false, children: [
                            // { path: `${process.env.PUBLIC_URL}/store-user/summary-report`, title: 'Summary', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/store-user/sales-trends`, title: 'Sales Trends', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/store-user/top-selling`, title: 'Top Selling', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/store-user/performance`, title: 'Performance', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/store-user/shelf-placement`, title: 'Planogram', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/store-user/inventory-trunover`, title: 'Inventory Status', type: 'link' },
                        ]
                    },
                    // { path: `${process.env.PUBLIC_URL}/store-admin/planogram-compliance`, title: 'Planogram Compliance', type: 'link' },
                    // { path: `${process.env.PUBLIC_URL}/store-admin/assortment`, title: 'Assortment Optimizer', type: 'link' }
                ]
            }
        ],
        defaultMenu: []
    }
]


const SYS_USER = [
    {
        menutitle: '',
        menucontent: 'Dashboards,Widgets',
        Items: [
            {
                title: 'Customer Mgmt', icon: HiOutlineOfficeBuilding, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/sys-user/customer-list`
            },
            {
                title: 'Modules', icon: FiBriefcase, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/sys-user/subscription-modules`
            },
            {
                title: 'Device Mgmt', icon: FiTablet, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/sys-user/amr-modules`
            }
        ]
    }
];

const SYS_ADMIN = [
    {
        menutitle: '',
        menucontent: 'Dashboards,Widgets',
        Items: [
            // {
            //     title: 'Dashboard', icon: FiGrid, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/sys-admin/dashboard`
            // },
            {
                title: 'Customer Mgmt', icon: HiOutlineOfficeBuilding, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/sys-admin/customer-list`
            },
            {
                title: 'Modules', icon: FiBriefcase, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/sys-admin/subscription-modules`
            },
            {
                title: 'Licence Mgmt', icon: FiClipboard, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/sys-admin/module-origanization`
            },
            {
                title: 'User Mgmt', icon: FiUsers, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/sys-admin/user-management`
            },
            {
                title: 'Device Mgmt', icon: FiTablet, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/sys-admin/amr-modules`
            }
        ]
    }
]

export const MenuItem = {
    STORE_USER, STORE_ADMIN, SYS_USER, SYS_ADMIN
}