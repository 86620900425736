import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const PrivateRoute = () => {
    const location = useLocation();
    const authUrl = ['/login', '/reset-password', '/forgot-password', '/404-error', '/profile'];
    
    const validateRoute = () => {
        const userType = sessionStorage.getItem('userType')?.toLowerCase()?.replace('_', '-');
        if(sessionStorage.getItem('accessToken') && (location.pathname.indexOf(userType) > -1)) {
            return true;
        } else if(authUrl?.includes(location?.pathname)) {
            return true
        } else {
            return false;
        }
    }
    
    return (
        validateRoute() ? <Outlet /> : <Navigate exact to={`${process.env.PUBLIC_URL}/login`} />
    );
};
export default PrivateRoute;

