import React, { Fragment } from 'react';
import { Container, Row, Col} from 'reactstrap';
import { Image } from '../../AbstractElements';

const Dashboard = () => {
    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                    <Image attrImage={{ style: {width: '100%'}, className: 'img-fluid for-light', src: `${require('../../assets/images/wave-connect-logo/screen1.png')}` }} />
                    </Col>
                    <Col sm="12">
                    <Image attrImage={{style: {width: '100%'}, className: 'img-fluid for-light', src: `${require('../../assets/images/wave-connect-logo/screen2.png')}` }} />
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
 
};

export default Dashboard;