import React, { Fragment, useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, Table } from "reactstrap";
import { H3 } from "../../AbstractElements";
import { X } from "react-feather";
import {
    Row, Col
} from "reactstrap";
import fetchWrapper from "../../Common/api/fetchWrapper";
import { API_URL } from "../../Common/Config/Config";
import { currencyFormatter } from "../../Common/api/helper";

const ViewJewellery = ({ value, setOpenModal, data }) => {
    const [open, setOpen] = useState(value);
    const [categoryData, setCategory] = useState();
    const currency = sessionStorage.getItem('userCurrency')

    const action = 'Add';
    console.log(data)
    const getCategoryDetails = async () => {
        await fetchWrapper(`${API_URL}/productcategory/${data?.categoryId}`)
            .then(res => {
                setCategory(res.json)
            }, (err) => console.log(err));
    }

    useEffect(() => {
        getCategoryDetails()
    }, [])

    const onClose = () => {
        setOpen(false);
        setOpenModal(false)
    }


    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size='xl'>
                <ModalHeader style={{ display: 'flex' }}>
                    <H3 attrH3={{ className: 'modal-title-color' }}>View Product <X className="x-button" onClick={onClose} /></H3>
                </ModalHeader>
                <ModalBody>
                    <Fragment>
                        <Row>
                            <Col lg='4' className="ml-3">
                                <img style={{ width: '100%', marginBottom: '2rem' }} className='img-fluid for-light'
                                    src={`data:image/jpeg;base64,${categoryData?.logo}`} />
                            </Col>
                            <Col lg='6 ml-3' style={{ lineHeight: '3' }}>
                                <label>{data?.skuId}</label>
                                <div>
                                    <h3 style={{ borderBottom: '1px solid #2e2846', paddingBottom: '1rem' }}>{data?.productName}</h3>
                                    <span style={{ fontSize: 'small' }}>{data?.brandName}</span>
                                </div>
                                <div>
                                    <div>
                                        <h4>Price <span>{currencyFormatter(data?.productPrice, currency)}</span></h4>
                                    </div>
                                    <div>
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                <th>Component</th>
                                                <th>Rate</th>
                                                <th>Weight (g)</th>
                                                <th>Discount</th>
                                                <th>Final Value</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{data?.metal}</td>
                                                    <td>{currencyFormatter(data?.metalRate, currency)}</td>
                                                    <td>{Number(data?.metalWeight)?.toFixed(2)}</td>
                                                    <td>{data?.discount ? currencyFormatter(data?.discount, currency) : '-'}</td>
                                                    <td>{currencyFormatter(data?.metalTotalValue, currency)}</td>
                                                </tr>
                                                <tr>
                                                    <td>{'STONE'}</td>
                                                    <td>{currencyFormatter(data?.stoneRate, currency)}</td>
                                                    <td>{Number(data?.stoneWeight)?.toFixed(2)}</td>
                                                    <td>{data?.discount ? currencyFormatter(data?.discount, currency) : '-'}</td>
                                                    <td>{currencyFormatter(data?.stoneTotalValue, currency)}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                        <div className="d-flex">
                                            <div>Grand Total</div> <div className="grand-total">{currencyFormatter(data?.productPrice, currency)}</div>
                                        </div>
                                    </div>
                                </div>
                                {/* <label>Metal Weight (grams)</label>
                                <div style={{ border: '1px solid', borderRadius: '7px', width: '51%', 'paddingLeft': '0.5rem' }}>{data?.metalWeight}</div>
                                <label>Stone Weight (grams)</label>
                                <div style={{ border: '1px solid', borderRadius: '7px', width: '51%', 'paddingLeft': '0.5rem' }}>{data?.stoneWeight}</div>
                                <span>Gold Purity: {data?.gold_purity}</span> */}
                            </Col>
                        </Row>
                        <div className="d-flex justify-around">
                            <div className="d-flex">
                                <h5>Available Qty <span className="ml-1" style={{ border: '1px solid', borderRadius: '7px', width: '100%', 'padding': '0.5rem' }}>{data?.availability}</span></h5>
                            </div>
                            <div className="d-flex">
                                <h5>SOH Qty <span className="ml-1" style={{ border: '1px solid', borderRadius: '7px', width: '100%', 'padding': '0.5rem' }}>{data?.sohQuantity}</span></h5>
                            </div>
                            
                        </div>
                    </Fragment>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default ViewJewellery;