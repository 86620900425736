import React, { Fragment } from "react";
import { Container, Col, Row } from 'reactstrap';
import { Image } from '../../AbstractElements';

const SummaryChart = () => {

    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col sm="12 mt-3 mb-3">
                        <h3>Sales Inventory & Inventory Turn-over</h3>
                        <Image attrImage={{ style: { width: '100%', marginBottom: '2rem' }, className: 'img-fluid for-light', src: `${require(`../../assets/images/dashboards/summary-details.png`)}` }} />
                        <h3 className="mb-2">Percentage change based on 2022 Growth Rates by States</h3>
                        <Image attrImage={{ style: { width: '60%', height: '40vh', marginLeft: '15rem' }, className: 'img-fluid for-light', src: `${require(`../../assets/images/dashboards/summary-map.png`)}` }} />
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};
export default SummaryChart;