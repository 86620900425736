import React, { Fragment, useState, useEffect } from "react";
import fetchWrapper from "../../Common/api/fetchWrapper";
import { API_URL } from "../../Common/Config/Config";
import Loader from '../../Common/Layout/Loader';
import { Breadcrumbs, Btn } from "../../AbstractElements";
import { Container, Row, Col, Card, CardBody, CardHeader, Table } from "reactstrap";
import { Link } from "react-router-dom";
import LayoutLoader from "../../Common/Component/LayoutLoader/LayoutLoader";

const ViewCustomer = () => {
    const [data, setData] = useState();
    useEffect(() => {
        const url = window.location.pathname.split('/');
        loadData(url[3])
    }, []);

    const loadData = async (id) => {
        // const options = {
        //     method: 'GET'
        // }
        // await fetchWrapper(`${API_URL}/organization/${id}`, options)
        //     .then((res) => {
        //         console.log(res)
        setData({
            "id": "827e6350-5671-40c4-ae08-33dd4cf1d07c",
            "companyName": "Raymond",
            "website": "https://www.raymond.in",
            "address": "Plot No. 156, H. No. 2, Village Zadgaon",
            "country": "India",
            "state": "Maharashtra",
            "orgType": "ASSET_OWWER",
            "workspace": null,
            "firstName": "John",
            "zipCode": "415 612",
            "lastName": "Davis",
            "email": "johndavis@raymond.in",
            "phoneNumber": "+91 9999888444",
            "isactive": true,
            "isdeleted": false,
            "createdTs": "2023-09-13 14:09:15.307",
            "createdBy": "a66600bd-29f0-41df-9d1c-1a538048d615",
            "lastModifiedBy": null,
            "lastModifiedTs": null,
            "subjectId": "546b8758-f03c-4fcc-94c9-58bf8745e783",
            "userName": "johndavis",
            "currency": null,
            "ghgEmission": true,
            "emissionUploadFrequency": null,
            "relationshipOwner": "e447586a-bcbb-406f-b1ed-b5b8bf0e227c",
            "tenantName": "AIROI",
            "customerId": "JYS70P4O",
            "assetManagerOrgId": null
        })
        // })
        // .catch((error) => {
        //     console.debug(error)
        // })
    }

    return (
        <Fragment>
            <LayoutLoader />
            <Breadcrumbs parent="Customer Mgmt" title="View" mainTitle="" />
            <Container fluid={true} className="ecommerce-page">
                <Row>
                    <Col lg={12}>
                        <Card className="browser-table">
                            <CardHeader className="border-bottom-light ">
                                <h2 className="header-color">Customer Details</h2>
                            </CardHeader>
                            <CardBody className="br-10">
                                <div className="table-responsive mb-0">
                                    <Table>
                                        <tbody>
                                            <tr>
                                                <td className="fw-td-bold">Name</td><td>{data?.companyName}</td>
                                                <td></td>
                                                <td className="fw-td-bold">Address</td><td className="text-left">{data?.address}</td>
                                            </tr>
                                            <tr>
                                                <td className="fw-td-bold">City</td><td>Ratnagiri</td>
                                                <td></td>
                                                <td className="fw-td-bold">State</td><td>{data?.state}</td>
                                            </tr>
                                            <tr>
                                                <td className="fw-td-bold">Country</td><td className="text-left">{data?.country}</td>
                                                <td></td>
                                                <td className="fw-td-bold">Zip Code</td><td>415 612</td>
                                            </tr>
                                            <tr>
                                                <td className="fw-td-bold">Website</td><td>{data?.website}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Card className="browser-table">
                            <CardHeader >
                                <h2 className="header-color">Contact Person Details</h2>
                            </CardHeader>
                            <CardBody className="br-10">
                                <div className="table-responsive mb-0">
                                    <Table>
                                        <tbody>
                                            <tr>
                                                <td className="fw-td-bold">Name</td><td>{data?.firstName + ' ' + data?.lastName}</td>
                                                <td></td>
                                                <td className="fw-td-bold">Email ID</td><td>{data?.email}</td>
                                            </tr>
                                            <tr>
                                                <td className="fw-td-bold">Phone</td><td>{data?.phoneNumber}</td>
                                                <td></td>
                                                <td className="fw-td-bold">User Name</td><td>{data?.userName}</td>
                                            </tr>

                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Card className="browser-table">
                            <CardHeader >
                                <h2 className="header-color">Subscription Details</h2>
                            </CardHeader>
                            <CardBody className="br-10">
                                <div className="table-responsive mb-0">
                                    <table className='table subscription-table'>
                                        <thead>
                                            <tr className='table-header-bg'>
                                                <th className='text-left'>Module Name</th>
                                                <th>
                                                    Subscription Type
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    Retail - Apparel</td>
                                                <td>Monthly</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <div className="mb-5">
                    <Link to={`${process.env.PUBLIC_URL}/sys-admin/customer-list`}>
                        <Btn attrBtn={{ color: 'primary' }} >
                            Back
                        </Btn>
                    </Link>
                </div>

            </Container>
        </Fragment>
    )
}

export default ViewCustomer;