import Filter from './Filter';
import React, { Fragment, useState } from 'react';
import { Col, Row } from 'reactstrap';

const Sidebar = ({data, setData}) => {
    const [IsOpen, setIsOpen] = useState(false);
    return (
        <Fragment>
            <Col xl="12">
                <div className="job-sidebar">
                    <div className={`job-left-aside custom-scrollbar ${IsOpen ? 'open' : ''}`}>
                        <div className="default-according style-1 faq-accordion job-accordion" id="accordionoc">
                            <Row>
                                <Filter storeData={data} setData={setData}/>
                            </Row>
                        </div>
                    </div>
                </div>
            </Col>
        </Fragment>
    );
};

export default Sidebar;