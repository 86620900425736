import React, { Fragment, useCallback, useState, useRef, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import AddCustomerModal from './AddCustomer';
import DeviceDetailsModal from './DeviceDetails';
import AgGridTable from '../../Common/Component/AgGridTable';
import { Edit, Trash } from 'react-feather';
import LayoutLoader from '../../Common/Component/LayoutLoader/LayoutLoader';
import fetchWrapper from '../../Common/api/fetchWrapper';
import { API_URL } from '../../Common/Config/Config';
import { Link } from "react-router-dom";
import avatarLogo from '../../assets/images/avtar/gray-avatar.png';
import DeleteCustomer from './DeleteCustomer';
import { toast } from 'react-toastify';

const CustomersList = () => {
    const [open, setOpen] = useState(false);
    const [deviceModelOpen, setDeviceModelOpen] = useState(false);
    const [deviceList, setDeviceList] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [deleteOpen, setDeleteopen] = useState(false)
    const [dataId, setDataId] = useState();
    const gridRef = useRef(null);
    let tableColumns = sessionStorage.getItem('userType') === 'SYS_ADMIN' ? [
        {
            headerName: 'Customer Name',
            filter: true,
            flex: 1.5,
            sortable: true,
            suppressMovable: true,
            cellRenderer: (param) => {
                return (<div>
                    {<img className='customer-logo' src={`${param.data?.logo ? `data:image/jpeg;base64,${param.data?.logo}` : avatarLogo}`} alt='customer-logo' />}
                    {param.data?.customerName}
                </div>)
            },
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
        },
        {
            headerName: 'Contact Person',
            field: 'contactPerson',
            filter: true,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            flex: 1
        },
        {
            headerName: 'Subscriptions',
            filter: false,
            sortable: false,
            suppressMovable: true,
            cellRenderer: (param) => {
                return (<div>
                    {param.data?.subscriptions?.filter((ele) => ele.moduleID)?.map(item => <span className='subscription-p' key={item.id}>{item.moduleName}</span>)}
                </div>)
            },
            width: 310
        },
        {
            headerName: 'City',
            field: 'city',
            filter: true,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            flex: 1
        },
        {
            headerName: 'Map Devices',
            filter: false,
            sortable: false,
            suppressMovable: true,
            cellRenderer: (row) => {
                return (row.data?.deviceAllocation.length ? <div className='allocated-device-count' onClick={() => deviceDetailsClick(row.data?.deviceAllocation)}>
                    {row.data?.deviceAllocation.length}
                </div> : <div className='text-center w-100'>{row.data?.deviceAllocation.length}</div>)
            },
            flex: 1
        },
        {
            headerName: 'Status',
            filter: false,
            sortable: true,
            suppressMovable: true,
            cellRenderer: (params) => {
                return (
                    <div className='mr-21'>
                        <label className="switch">
                            <input type="checkbox" checked={params.data.active.charAt(0) === 'T'}
                                onChange={() => handleStatus(params.data.id, params.data.active)} />
                            <div className="slider round">
                                <span className="on"></span>
                                <span className="off"></span>
                            </div>
                        </label>
                    </div>
                )
            },
            flex: 1
        },
        {
            headerName: 'Edit',
            width: 80,
            filter: false,
            sortable: true,
            suppressMovable: true,
            cellRenderer: params => {
                return (
                    <Link to={`${process.env.PUBLIC_URL}/sys-admin/edit-customer/${params.data.id}`}>
                        <Edit className={`edit-icon`} />
                    </Link>
                )
            },
        },
        {
            headerName: 'Delete',
            width: 80,
            cellRenderer: params => {
                return (
                    <Trash className={`edit-icon`} onClick={() => clickRowDelete(params.data)} />
                )
            },
            suppressMovable: true,
        },
    ]: [{
        headerName: 'Customer Name',
        filter: true,
        flex: 1.5,
        sortable: true,
        suppressMovable: true,
        cellRenderer: (param) => {
            return (<div>
                {<img className='customer-logo' src={`${param.data?.logo ? `data:image/jpeg;base64,${param.data?.logo}` : avatarLogo}`} alt='customer-logo' />}
                {param.data?.customerName}
            </div>)
        },
        filterParams: {
            filterOptions: ['contains'],
            maxNumConditions: 0,
            buttons: ['reset']
        },
    },
    {
        headerName: 'Contact Person',
        field: 'contactPerson',
        filter: true,
        sortable: true,
        suppressMovable: true,
        filterParams: {
            filterOptions: ['contains'],
            maxNumConditions: 0,
            buttons: ['reset']
        },
        flex: 1
    },
    {
        headerName: 'Subscriptions',
        filter: false,
        sortable: false,
        suppressMovable: true,
        cellRenderer: (param) => {
            return (<div>
                {param.data?.subscriptions?.filter((ele) => ele.moduleID)?.map(item => <span className='subscription-p' key={item.id}>{item.moduleName}</span>)}
            </div>)
        },
        width: 310
    },
    {
        headerName: 'City',
        field: 'city',
        filter: true,
        sortable: true,
        suppressMovable: true,
        filterParams: {
            filterOptions: ['contains'],
            maxNumConditions: 0,
            buttons: ['reset']
        },
        flex: 1
    },
    {
        headerName: 'Map Devices',
        filter: false,
        sortable: false,
        suppressMovable: true,
        cellRenderer: (row) => {
            return (row.data?.deviceAllocation.length ? <div className='allocated-device-count' onClick={() => deviceDetailsClick(row.data?.deviceAllocation)}>
                {row.data?.deviceAllocation.length}
            </div> : <div className='text-center w-100'>{row.data?.deviceAllocation.length}</div>)
        },
        flex: 1
    },
    {
        headerName: 'Status',
        filter: false,
        sortable: true,
        suppressMovable: true,
        cellRenderer: (params) => {
            return (
                <div className='mr-21'>
                    <label className="switch">
                        <input type="checkbox" checked={params.data.active.charAt(0) === 'T'}
                            onChange={() => handleStatus(params.data.id, params.data.active)} />
                        <div className="slider round">
                            <span className="on"></span>
                            <span className="off"></span>
                        </div>
                    </label>
                </div>
            )
        },
        flex: 1
    },
    {
        headerName: 'Edit',
        width: 80,
        filter: false,
        sortable: true,
        suppressMovable: true,
        cellRenderer: params => {
            return (
                <Link to={`${process.env.PUBLIC_URL}/sys-user/edit-customer/${params.data.id}`}>
                    <Edit className={`edit-icon`} />
                </Link>
            )
        },
    }];

    const loadCustomerData = async () => {
        await fetchWrapper(`${API_URL}/customer/details`)
            .then((res) => {
                console.log(res);
                setCustomerList(res.json);
                gridRef?.current?.api?.hideOverlay();
            }, (err) => {
                console.log(err)
                gridRef?.current?.api?.hideOverlay();
                if (err.status === 404) {
                    gridRef?.current?.api?.showNoRowsOverlay();
                }
            })
    }

    const handleStatus = async (id, status) => {
        const requestOptions = {
            method: 'PUT',
        };
        gridRef?.current?.api?.showLoadingOverlay();
        await fetchWrapper(`${API_URL}/customer/status/${id}/${status === 'FALSE' ? 'TRUE' : 'FALSE'}`, requestOptions)
            .then((res) => {
                if (res.status === 200) {
                    console.log(res.json)
                    if (res.json?.active === 'FALSE') {
                        toast.error('Customer Disabled Successfully!')
                    } else {
                        toast.success('Customer Enabled Successfully!')
                    }
                    loadCustomerData();
                }
            }, (error) => {
                console.error(error);
                toast.error("Someting went wrong")
            })
    }

    const onGridReady = useCallback(async (params) => {
        gridRef?.current?.api?.showLoadingOverlay();
        loadCustomerData();
    })

    const deviceDetailsClick = (item) => {
        setDeviceList(item);
        setDeviceModelOpen(true);
    }
    const handleClick = () => setOpen(true);

    const clickRowDelete = async (data) => {
        setDeleteopen(true)
        setDataId(data.id)
    }

    return (
        <Fragment>
            <LayoutLoader />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <div className="d-flex">
                            <Breadcrumbs mainTitle="" parent="Customer Management" title="" />

                            <div className="text-right action_button">
                                <div className="link action_button" onClick={handleClick}>
                                    <i style={{ fontSize: '25px', color: "#2b7704" }} className="icofont  icofont-plus-circle" ></i>
                                    &nbsp;&nbsp;{"Add Customer"}
                                </div>
                            </div>
                        </div>
                        <Card className="border-bottom-light newtable">
                            <CardBody className='data-tables responsive-view pb-10'>
                                <div className="">
                                    <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                        <AgGridTable
                                            columnData={tableColumns}
                                            tableData={customerList}
                                            agRef={gridRef}
                                            onGridReady={onGridReady}
                                        />
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    {open && <AddCustomerModal value={open} setOpenModal={setOpen} loadCustomerData={loadCustomerData} />}
                    {deviceModelOpen && <DeviceDetailsModal value={deviceModelOpen} setOpenModal={setDeviceModelOpen}
                        data={deviceList} type={'customer'} />}
                    {deleteOpen && <DeleteCustomer value={deleteOpen} setOpenModal={setDeleteopen}
                        dataId={dataId} setDelete={(e) => e === true && loadCustomerData()} />}
                </Row>
            </Container>
        </Fragment>
    );
};

export default CustomersList;