import React, { Fragment, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { H3 } from "../../AbstractElements";
import { X } from "react-feather";
import UserForm from "../../Common/Component/UserForm";
import fetchWrapper from "../../Common/api/fetchWrapper";
import { API_URL } from "../../Common/Config/Config";

const AddStoreUser = ({value, closeModal, orgData, action}) => {
    const [open, setOpen] = useState(value);
    
    const onClose = (update) => {
        setOpen(false);
        closeModal(update);
    }

    
    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size='lg'>
                <ModalHeader style={{display: 'flex'}}>
                    <H3 attrH3={{ className: 'modal-title-color' }}>User - Onboarding <X className="x-button" onClick={() => onClose(false)}/></H3>
                </ModalHeader>
                <ModalBody>
                    <UserForm action={action} cancel={(value) =>onClose(value === true ? true : false)} navigateUrl={`${process.env.PUBLIC_URL}/store-admin/user-list`} preData={orgData} />
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default AddStoreUser;