import React, { Fragment, useState } from "react";
import { Modal, ModalBody, ModalHeader, Row, Col, Card, CardBody, Form, FormGroup, Label, Button } from "reactstrap";
import { H3 } from "../../AbstractElements";
import { X } from "react-feather";
import { useForm } from "react-hook-form";
import fetchWrapper from "../../Common/api/fetchWrapper";
import { API_URL } from "../../Common/Config/Config";
import ButtonLoader from "../../Common/Component/ButtonLoader";
import { toast } from "react-toastify";

const AddAMRdevice = ({ value, setOpenModal, data, action, loadAmrData, deviceTypes }) => {
    const [open, setOpen] = useState(value);
    const [loading, setLoading] = useState(false);
    const { register, formState: { errors }, reset, getValues, handleSubmit } = useForm({ defaultValues: data });
    // const action = 'Add';

    const onClose = () => {
        setOpen(false);
        setOpenModal(false)
    }

    const onSubmit = async (data) => {
        setLoading(true);
        let url, message;
        let method = action === "Add" ? 'POST' : 'PUT';
        try {
            console.log(data)
            if (action === 'Add') {
                url = `${API_URL}/device`
                message = 'Device Added Successfully!'
            }
            if (action === 'Edit') {
                url = `${API_URL}/device/${data.id}`
                message = 'Device Updated Successfully!'
            }
            const options = {
                method: method,
                body: data
            }
            await fetchWrapper(url, options)
                .then((res) => {
                    if (res.ok) {
                        loadAmrData();
                        toast.success(message)
                        onClose();
                    }
                    setLoading(false);
                })
                .catch((err) => {
                    if (err.status == 412) {
                        loadAmrData();
                        toast.error('Device Already Exist..')
                        console.log(err);
                        setLoading(false);
                    }
                })

        } catch (error) {

        }
    }

    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size='lg'>
                <ModalHeader style={{ display: 'flex' }}>
                    <H3 attrH3={{ className: 'modal-title-color' }}>{action === 'Edit' ? 'Update' : 'Add'} Device <X className="x-button" onClick={onClose} /></H3>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg='12'>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Col lg='6'>
                                        <div style={{ marginBottom: "35px" }}>
                                            <FormGroup style={{ marginBottom: "8px" }}>
                                                <Label for="exampleSelect">Device Id <span className='required'>*</span></Label>
                                                <input
                                                    className="form-control"
                                                    name="device_id"
                                                    type="text"
                                                    defaultValue={data?.deviceID || ''}
                                                    {...register('deviceID', { required: true })}
                                                />
                                                <span className="text-danger">{errors.deviceID && 'Device Id is Required'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div style={{ marginBottom: "35px" }}>
                                            <FormGroup >
                                                <Label for="exampleEmail">Device Name <span className='required'>*</span></Label>
                                                <input
                                                    className="form-control"
                                                    name="device_name"
                                                    placeholder=""
                                                    type="text"
                                                    defaultValue={data?.deviceName || ''}
                                                    {...register('deviceName', { required: true })}
                                                />
                                                <span className="text-danger">{errors.deviceName && 'Device Name is Required'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col lg="6">
                                        <FormGroup >
                                            <Label for="exampleEmail">Device Type <span className='required'>*</span></Label>
                                            <select className="form-select" name="deviceTypeId"
                                                    defaultValue={data?.deviceTypeID || ''}
                                                    {...register('deviceTypeID', { required: true })}
                                                >
                                                    <option value=''>-Select DeviceType-</option>
                                                    {deviceTypes.map((item, i) => {
                                                        return (
                                                            <option key={i} value={item.id}>{item.name}</option>
                                                        )
                                                    }
                                                    )}
                                                </select>
                                            <span className="text-danger">{errors.deviceTypeID && 'Device Type is Required'}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <div className="d-flex" >
                                        {/* {
                                            loading ? <ButtonLoader color="primary" /> :
                                                <><Button color="primary">{action === 'Edit' ? 'Update' : 'Add'}</Button>
                                                    <Button color="secondary" className="ml-1" onClick={onClose}> Cancel</Button></>
                                        } */}
                                        <Button color="primary">{action === 'Edit' ? 'Update' : 'Add'}</Button>
                                    </div>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default AddAMRdevice;