import { createContext } from 'react';
import moment from "moment";
import { currencySymbol } from "./currencyData";

export const handleProjectData = (formData, status) => {
    console.log(formData)
    const data = {
        // ...formData,
        "name": formData.projectName,
        "description": formData.description,
        "type": formData.type,
        "status": status,
        "start_date": formData?.startDate ? moment(formData.startDate).format('YYYY-MM-DD') : '',
        "end_date": formData?.endDate ? moment(formData.endDate).format('YYYY-MM-DD') : '',
        "asset_owner_id": null,
        "methodology": formData.methodology,
        "country": formData.projectCountry,
        "region": formData.region,
        "latitude": null,
        "longitude": null,
        "area": "",
        "size": formData.projectsize,
        "average_annual_volume_of_vc_us": Number(formData.avgannualvcu),
        "crediting_period_in_years": "",
        "website": null,
        "ccbstandardname": formData?.ccbstdname,
        "ccbstandard": formData?.ccbstd,
        "isassociatedwithjnr": formData?.isassociatewithjnr,
        "sdVistaProjectType": formData?.sdvistaprojtype,
        "susdevgoalcontribution": formData?.susdevgoalcontri,
        "assessmenttype": formData.assesmentType,
        "periodicassessmenttype": formData.periodicAssesmentType,
        "volume": formData.volume != undefined ? formData.volume : '',
        "frequency": formData.frequency != undefined ? formData.frequency : '',
        "registry": formData.registry,
        "unitType": formData.unitType,
        "creditsIssuance": formData?.creditsIssuance,
        "projectorigin": formData.projectorigin,
        "projectCategoryId": formData?.projectCategoryId,
        "splitValue": formData?.splitValue,
        "registryId": formData?.registryId,
        "projectSubCategoryId": formData?.projectSubCategoryId,
        "subCategoryItemId": formData?.subCategoryItemId,
        "surface_area": formData?.surface_area,
        "carbon_baseline": formData?.carbon_baseline,
        "estimation_carbon_capacity": formData?.estimation_carbon_capacity,
        "total_gross_sequestration_potential": formData?.total_gross_sequestration_potential,
        "operational_impacts": formData?.operational_impacts,
        "project_duration": formData?.project_duration,
        "potential_per_ha_year": formData?.potential_per_ha_year,
        "potential_whole_project_year": formData?.potential_whole_project_year,
        "total_sequestration_potential": formData?.total_sequestration_potential,
        "estimated_carbon_income": formData?.estimated_carbon_income,
        "facility_field_name": formData?.facility_field_name,
        "geo_location": formData?.geo_location,
        "legal_ownership": formData?.legal_ownership,
        "remove_store_carbon": formData?.remove_store_carbon,
        "selectedAsoOrgId": formData?.selectedAsoOrgId,
        "baseline": formData?.baseline,
        "dataCollectionFrequency": formData?.dataCollectionFrequency,
        "emissionFactor": formData?.emissionFactor,
        "monitoringLevel": formData?.monitoringLevel
    }
    console.log(data)
    return data;
}


export const handleDraftData = (formData) => {
    const data = {
        "name": formData.projectName,
        "description": formData.description,
        "status": "DRAFT",
        "start_date": moment(formData.startDate).format('YYYY-MM-DD'),
        "end_date": moment(formData.endDate).format('YYYY-MM-DD'),
        "country": formData.projectCountry,
        "region": formData.region,
        "latitude": null,
        "longitude": null,
        "area": 1000,
        "crediting_period_in_years": 5,
        "website": "",
        "volume": formData.volume,
        "periodicassessmenttype": formData.periodicAssesmentType,
        "frequency": formData.frequency,
        "assessmenttype": formData.assesmentTypeValue,
        "projectorigin": formData.projectorigin,
        "projectCategoryId": formData?.projectCategoryId,
        "splitValue": formData?.splitValue,
        "registryId": formData?.registryId,
        "projectSubCategoryId": formData?.projectSubCategoryId,
        "subCategoryItemId": formData?.subCategoryItemId,
        "unitType": formData.unitType,
        "selectedAsoOrgId": formData?.selectedAsoOrgId
    }
    return data;
}

export const defaultStatus = {
    DRAFT: "Draft",
    SUBMITTED: "Submitted",
    VALIDATED: "Validated",
    REJECTED: "Rejected",
    INASSESSMENT_UNALLOCATED: "In Assessment - WA",
    INASSESSMENT_ALLOCATED: "In Assessment",
    RETURNED: "Returned",
    ACCEPTED: "Accepted",
    SUBMITTED_TO_REGISTRY: "Submitted to Registry",
    APPROVED_BY_REGISTRY: "Approved by Registry",
    ACTIVE: "Active",
    SUSPENDED: "Inactive",
    RETIRED: "Retired"
}

export const defaultStatusColorCodes = {
    DRAFT: '#FD5F72',
    SUBMITTED: '#d81689',
    REJECTED: '#FF0000',
    VALIDATED: '#4b0082',
    INASSESSMENT_UNALLOCATED: '#4169E1',
    INASSESSMENT_ALLOCATED: '#4169E1',
    RETURNED: '#B3533A',
    ACCEPTED: '#b300b3',
    SUBMITTED_TO_REGISTRY: '#003868',
    APPROVED_BY_REGISTRY: '#008080',
    ACTIVE: '#2D9E64',
    SUSPENDED: '#009EFB',
    RETIRED: '#306975',
}

export function capitalize(string) {
    if (string == null) {
        return string;
    } 
    if(string.split(" ")?.length > 1) {
        return string.replace(/\b\w/g, function(char) {
            return char.toUpperCase();
        });
    } else {
        return string[0]?.toUpperCase() + string.slice(1).toLowerCase();
    }
}

export const defaultFrequency = {
    "1": "MONTHLY",
    "3": "QUARTERLY",
    "6": "HALFYEARLY",
    "12": "YEARLY"
}

export const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export const currencyFormatter = (value, currency) => {
    if(!value) {
        return value
    }
    // value=value.toString();
    // let lastThree = value.substring(value.length-3);
    // let otherNumbers = value.substring(0,value.length-3);
    // if(otherNumbers != '')
    //     lastThree = ',' + lastThree;
    // value = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    
    return currencySymbol[currency]+' '+ Number(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}


export const logoutAction = () => {
    sessionStorage.removeItem('profileURL');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('auth_profile');
    sessionStorage.removeItem('Name');
    sessionStorage.setItem('authenticated', false);
    sessionStorage.setItem('login', false);
    sessionStorage.clear();
}

export const defaultRouter = {
    ASR_USER: '/assessor/projects',
    ASM_ADMIN: '/asm-admin/projects',
    ASM_USER: '/asm-user/projects',
    ASR_ADMIN: '/asr-admin/projects',
    ASO_USER: '/user/projects',
    ASO_ADMIN: '/aso-admin/projects',
    SYS_ADMIN: '/sys-admin/projects',
    SYS_USER: '/back-office/projects'
}

export const defaultUserType = {
    SYS_ADMIN: 'sys-admin',
    SYS_USER: 'sys-user'
}

export const defaultOrderStatusColorCodes = {
    NEW: "#003868",
    OPEN: "#1db1c0",
    LOCKED : "#31c6e0",
    FILLED: "#0070cd", //yellow
    SETTLED: "#07f50f", //green
    EXPIRED: "#e88b10", //orange
    RETIRED: "#e60742", //pink
    CANCELLED: "#dc143c" //red
}

export const handleProjectDates = (formData) => {
    let data = {...formData};
    if(formData?.start_date && formData?.end_date) {
        data.start_date = moment(formData.start_date).format('YYYY-MM-DD');
        data.end_date = moment(formData.end_date).format('YYYY-MM-DD');
    } else {
        delete data?.start_date;
        delete data?.end_date;
    }
    return data;
}

export const handleDate = (date) => {
    if(date && typeof date === 'string') {
        var d = date + ' ' + 'UTC'
        return new Date(date).toString()
    } else if(!date) {
        return ''
    }
}

export const isValidUrl = (string) => {
    var res = string.match(/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/gm);
    return (res !== null)
}

export const isValidNumber = (number) => {
    var res = number.match(/^[0-9\b]+$/)
    return (res !== null)
}

export const defaultQuarter = {
    1: 'Jan - Mar',
    2: 'Apr - Jun',
    3: 'Jul - Sep',
    4: 'Oct - Dec'
}

export const SubscriptionContext = createContext(null);