import React, { Fragment, useEffect, useState, useRef, useCallback } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import DataTableComponent from '../../Common/Component/DataTable/DataTableComponent';
import { Edit, Trash } from 'react-feather';
import AddThreshold from './add-threshold';
import fetchWrapper from '../../Common/api/fetchWrapper';
import { API_URL } from '../../Common/Config/Config';
import { toast } from 'react-toastify';
import AgGridTable from '../../Common/Component/AgGridTable';
import DeleteThreshold from './DeleteThreshold';

const ThresholdMgmtlist = () => {
    const [open, setOpen] = useState(false);
    const [openBulk, setOpenBulk] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false)
    const [orgData, setOrgData] = useState();
    const [dataId, setDataId] = useState();
    const [isDeleted, setIsDeleted] = useState(false)
    const [deleteOpen, setDeleteopen] = useState(false)
    const [categoryData, setCategoryData] = useState([]);
    const [storeData, setStoreData] = useState([]);


    const thresholdRef = useRef(null);

    const loadData = async () => {
        thresholdRef?.current?.api?.showLoadingOverlay();
        const options = {
            method: 'GET'
        }
        await fetchWrapper(`${API_URL}/threshold`)
            .then((res) => {
                console.log(res)
                setData(res.json)
                thresholdRef?.current?.api?.hideOverlay();
            })
            .catch((err) => {
                thresholdRef?.current?.api?.hideOverlay();
                console.error(err)
            })
        
    }

    const loadOrgData = async (item) => {
        setOrgData(item);
        setOpen(true);
    }

    const getStores = async () => {
        await fetchWrapper(`${API_URL}/store/list`)
            .then((res) => {
                setStoreData(res.json)
            }, (err) => console.log(err))
    }

    // const getCategory = async (val) => {
    //     setStore(val);
    //     let selStore = storeData?.find(ele => ele.id === val);
    //     setSelectedStore(selStore)
    //     await fetchWrapper(`${API_URL}/productcategory/type/${selStore?.categoryType}`)
    //         .then((res) => {
    //             setCategoryData(res.json)
    //         }, (err) => console.log(err))
    // }

    const getAllCategory = async() => {
        await fetchWrapper(`${API_URL}/productcategory`)
        .then((res)=>{
            setCategoryData(res.json)
        }, (err)=>console.log(err))
    }

    const handleDelete = (value) => {
        setDeleteopen(true)
        setDataId(value)
    }

    useEffect(() => {
        getAllCategory();
        getStores();
    }, []);

    const columnData = [
        {
            headerName: 'Store',
            field: 'storeName',
            filter: true,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            flex: 2,
        },
        {
            headerName: 'Category',
            field: 'categoryTypeName',
            filter: true,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            flex: 1,
        },
        {
            headerName: 'Notification Mail',
            field: 'email',
            filter: true,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            flex: 2,
        },
        {
            headerName: 'Threshold Percentage',
            field: 'thresholdPercentage',
            filter: true,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            flex: 2
        },
        {
            headerName: 'Edit',
            width: 80,
            cellRenderer: params => {
                return (
                    <Edit className={`edit-icon`} onClick={() => loadOrgData(params.data)} />
                )
            },
            suppressMovable: true,
        },
        {
            headerName: 'Delete',
            width: 80,
            cellRenderer: params => {
                return (
                    <Trash className={`edit-icon`} onClick={()=>handleDelete(params.data.thresholdId)} />
                )
            },
            suppressMovable: true,
        },
    ]

    const handleClick = () => {
        setOrgData(undefined);
        setOpen(true);
    }

    const onGridReady = useCallback(async() =>{
        loadData();
    })

    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <div className='d-flex'>
                            <Breadcrumbs mainTitle="Settigs" parent="Threshold Management" title="List" />
                        <div className="text-right  action_button">
                            <div className="link action_button" onClick={handleClick}>
                                <i style={{ fontSize: '25px', color: "#2b7704" }} className="icofont  icofont-plus-circle" ></i>
                                &nbsp;&nbsp;{"Add Threshold"}</div>
                        </div>
                        </div>
                        <Card className="border-bottom-light  newtable">
                            <CardBody className='data-tables'>
                            <div className="">
                                    <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                        <AgGridTable
                                            columnData={columnData}
                                            tableData={data}
                                            agRef={thresholdRef}
                                            onGridReady={onGridReady}
                                        />
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    {open && <AddThreshold value={open} storeData={storeData} categoryData={categoryData} setOpenModal={setOpen} data={orgData} 
                    action={orgData ? 'Edit' : 'Add'} setAdd={(e) => e === true && loadData()}/>}
                    {deleteOpen && <DeleteThreshold value={deleteOpen} setOpenModal={setDeleteopen} dataId={dataId} setDelete={setIsDeleted} />}
                </Row>
            </Container>
        </Fragment>
    );

};

export default ThresholdMgmtlist;