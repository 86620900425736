import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Col, Container } from 'reactstrap'
import { H3,P,Btn } from '../../../AbstractElements'
import { BACK_TO_HOME_PAGE, Error2Head, ErrorPageContent } from '../../../Common/Constant'
import { Error2 } from './Svg'

const NewPage = () => {
  return (
    <Fragment>
       <div className='page-wrapper compact-wrapper' id='pageWrapper'>
          <div className='error-wrapper'>
            <Container>
              <div className='svg-wrapper error2'>
                <Error2/>
              </div>
              <Col md="8" className="offset-md-2">
                  {/* <H3>{Error2Head}</H3> */}
                  <P attrPara={{ className: 'sub-content' }} >The page you are attempting to reach is currently work in progress.</P>
              </Col>
            </Container>
          </div>
      </div>  
    </Fragment>
  )
}
export default NewPage