import React, { Fragment, useEffect, useRef, useState, useCallback } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs, H3 } from '../../AbstractElements';
import DataTableComponent from '../../Common/Component/DataTable/DataTableComponent';
import { Edit, Trash } from 'react-feather';
import AddAMRdevice from './AddAmr';
import DeviceChart from '../../Common/Component/Chart/DeviceChart';
import fetchWrapper from '../../Common/api/fetchWrapper';
import { API_URL } from '../../Common/Config/Config'
import AgGridTable from '../../Common/Component/AgGridTable';
import { toast } from 'react-toastify';
import DeleteAmr from './DeleteAmr';

let allocated = 0, totalDevice = 0, unallocated = 0;
let allocatePercent = 0, unallocatePercent = 0;

const AmrList = () => {
    const [open, setOpen] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false)
    const [orgData, setOrgData] = useState();
    const deviceRef = useRef(null);
    const [totalData, setTotalData] = useState()
    const [allocatedData, setAllocatedData] = useState();
    const [deviceTypes, setDeviceTypes] = useState([]);
    const [unallocatedData, setUnallocatedData] = useState();
    const [action, setAction] = useState('Add')
    const [deleteOpen, setDeleteopen] = useState(false)
    const [dataId, setDataId] = useState();
    const [isDeleted, setIsDeleted] = useState(false)
    const [chartData, setChartData] = useState(false)
    const [title, setTitle] = useState('Total Device List');

    const getDeviceTypes = async () => {
        await fetchWrapper(`${API_URL}/deviceType`)
            .then((res) => {
                setDeviceTypes(res.json);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const loadData = async () => {
        deviceRef?.current?.api?.showLoadingOverlay();
        await fetchWrapper(`${API_URL}/device/customer/details`)
            .then((res) => {
                setLoading(false)
                setData(res.json)
                let deviceData = res.json
                totalDevice = res.json?.length
                let noOfAllocated = deviceData?.filter((i) => i.customerName !== null)
                let noOfUnallocated = deviceData?.filter((i) => i.customerName === null)
                allocated = noOfAllocated?.length
                unallocated = noOfUnallocated?.length
                allocatePercent = ((allocated * 100) / totalDevice);
                unallocatePercent = ((unallocated * 100) / totalDevice);
                setTotalData(res.json)
                setAllocatedData(noOfAllocated)
                setUnallocatedData(noOfUnallocated)
                setLoading(false)
                setChartSummary();
                deviceRef?.current?.api?.hideOverlay(); 
            })
            .catch((err) => {
                setLoading(false)
                console.error(err)
                deviceRef?.current?.api?.hideOverlay();
            })
    }

    const loadOrgData = async (item) => {
        setOrgData(item)
        setAction('Edit')
        setOpen(true);

    }

    const handleDelete = (value, id) => {
        setDeleteopen(true)
        setDataId(id)
    }

    const setChartSummary = () => {
        setChartData([
            {
                id: 1, name: 'Total', value: totalDevice, chart: {
                    
                    series: [100],
                    options: {
                        chart: {
                            type: 'radialBar',
                        },
                        plotOptions: {
                            radialBar: {
                                dataLabels: {
                                    name: {
                                        fontSize: '22px',
                                        color: '#000',
                                        offsetY: -10,
                                    },
                                    value: {
                                        fontSize: '25px',
                                        offsetY: 0,
                                    },
                                    total: {
                                        show: true,
                                        label: 'Total',
                                        formatter: function (w) {
                                            // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                                            return unallocated + allocated
                                        }
                                    }
                                }
                            }
                        },
                        fill: {
                            colors: '#132b5e'
                        },
                        labels: ['Total'],
                    },
                }
            },
            {
                id: 2, name: 'Allocated', value: allocated, chart: {
                    series: [allocatePercent],
                    options: {
                        chart: {
                            type: 'radialBar',
                        },
                        plotOptions: {
                            radialBar: {
                                dataLabels: {
                                    name: {
                                        fontSize: '22px',
                                        color: '#000',
                                        offsetY: -10,
                                    },
                                    value: {
                                        fontSize: '25px',
                                        offsetY: 0,
                                    },
                                    total: {
                                        show: true,
                                        label: 'Allocated',
                                        formatter: function (w) {
                                            // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                                            return allocated
                                        }
                                    }
                                }
                            }
                        },
                        fill: {
                            colors: '#c8228f'
                        },
                        labels: ['Allocated'],
                    },
                }
            },
            {
                id: 3, name: 'Un-Allocated', value: unallocated, chart: {
                    series: [unallocatePercent],
                    options: {
                        chart: {
                            type: 'radialBar',
                        },
                        plotOptions: {
                            radialBar: {
                                dataLabels: {
                                    name: {
                                        fontSize: '22px',
                                        color: '#000',
                                        offsetY: -10,
                                    },
                                    value: {
                                        fontSize: '25px',
                                        offsetY: 0,
                                    },
                                    total: {
                                        show: true,
                                        label: 'Un-Allocated',
                                        formatter: function (w) {
                                            // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                                            return unallocated
                                        }
                                    }
                                }
                            }
                        },
                        fill: {
                            colors: '#db600e'
                        },
                        labels: ['Un-Allocated'],
                    },
                }
            }
        ])
    }
    
    useEffect(()=>{}, [totalDevice])

    const handleStatus = async (data) => {
        let updateStatus = data?.active === 'TRUE' ? 'FALSE' : 'TRUE';
        const options = {
            method: 'PUT'
        }
        await fetchWrapper(`${API_URL}/device/status/${data?.id}/${updateStatus}`, options)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    if (res?.json?.status === 'FALSE') {
                        toast.error('Device has been disabled!')
                    } else {
                        toast.success('Device has been enabled!')
                    }
                }
            })
            .catch(err => console.log(err))
    }

    const columnData = sessionStorage.getItem('userType') === 'SYS_ADMIN' ? [
        {
            headerName: 'Device ID',
            field: 'deviceID',
            filter: true,
            sortable: true,
            flex: 2,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Device Name',
            field: 'deviceName',
            filter: true,
            sortable: true,
            flex: 2,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Device Type',
            field: 'deviceTypeName',
            filter: true,
            sortable: true,
            flex: 2,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Customer Name',
            field: 'customerName',
            filter: true,
            sortable: true,
            flex: 2,
            suppressMovable: true,
            valueGetter: (params) => {
                return params.data.customerName ? params.data.customerName : 'Un-Allocated'
            },
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Status',
            filter: false,
            flex: 1,
            sortable: true,
            suppressMovable: true,
            cellRenderer: (params) => {
                return (
                    <div className='mr-21'>
                        <label className="switch">
                            <input type="checkbox" id="togBtn" 
                            defaultChecked={params.data.active === 'TRUE' ? true : false} onChange={()=>handleStatus(params.data)}/>
                            <div className="slider round">
                                <span className="on"></span>
                                <span className="off"></span>
                            </div>
                        </label>
                    </div>
                )
            }
        },
        {
            headerName: 'Edit',
            flex: 1,
            cellRenderer: params => {
                return (
                    <Edit className={`edit-icon`} onClick={() => loadOrgData(params.data)} />
                )
            },
            suppressMovable: true,
        },
        {
            headerName: 'Delete',
            flex: 1,
            cellRenderer: params => {
                return (
                    <Trash className={`edit-icon`} onClick={()=>handleDelete(params.data.deviceID, params.data.id)}/>
                )
            },
            suppressMovable: true,
        },
    ] : [
        {
            headerName: 'Device ID',
            field: 'deviceID',
            filter: true,
            sortable: true,
            flex: 2,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Device Name',
            field: 'deviceName',
            filter: true,
            sortable: true,
            flex: 2,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Device Type',
            field: 'deviceTypeName',
            filter: true,
            sortable: true,
            flex: 2,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Customer Name',
            field: 'customerName',
            filter: true,
            sortable: true,
            flex: 2,
            suppressMovable: true,
            valueGetter: (params) => {
                return params.data.customerName ? params.data.customerName : 'Un-Allocated'
            },
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Status',
            filter: false,
            flex: 1,
            sortable: true,
            suppressMovable: true,
            cellRenderer: (params) => {
                return (
                    <div className='mr-21'>
                        <label className="switch">
                            <input type="checkbox" id="togBtn" 
                            defaultChecked={params.data.active === 'TRUE' ? true : false} onChange={()=>handleStatus(params.data)}/>
                            <div className="slider round">
                                <span className="on"></span>
                                <span className="off"></span>
                            </div>
                        </label>
                    </div>
                )
            }
        },
        {
            headerName: 'Edit',
            flex: 1,
            cellRenderer: params => {
                return (
                    <Edit className={`edit-icon`} onClick={() => loadOrgData(params.data)} />
                )
            },
            suppressMovable: true,
        }
    ]

    const handleClick = () => {
        setOrgData(undefined);
        setOpen(true);
    }

    const onSelectCard = (item) => {
        if (item.id === 1) {
            setData(totalData);
            setTitle('Total Device List')
        } else if(item.id === 2) {
            setData(allocatedData);
            setTitle('Allocated Device List')
        } else if(item.id === 3) {
            setData(unallocatedData)
            setTitle('Un-Allocated Device List')
        }
    }

    const onGridReady = useCallback(async (params) => {
        loadData();
        getDeviceTypes();
    })
    useEffect(()=>{
        if(isDeleted) {
            loadData();
        }
    },[isDeleted])
    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <div className='d-flex'>
                            <Breadcrumbs mainTitle="Device" parent="Device Management" title="List" />
                            <div className="text-right  action_button">
                                <div className="link action_button" onClick={handleClick}>
                                    <i style={{ fontSize: '25px', color: "#2b7704" }} className="icofont  icofont-plus-circle" ></i>
                                    &nbsp;&nbsp;{"Add Device"}</div>
                            </div>
                        </div>
                        {chartData && <DeviceChart className="mb-2" selectedCard={onSelectCard} deviceSummary={chartData} default={{ id: 1, name: 'Total', value: '100' }} /> }
                        <Col sm='12'>
                        <Card className="border-bottom-light  newtable">
                            <CardBody className='data-tables'>
                            <H3>{title}</H3>
                                <div className="">
                                    <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                        <AgGridTable
                                            columnData={columnData}
                                            tableData={data}
                                            agRef={deviceRef}
                                            onGridReady={onGridReady}
                                        />
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                        </Col>
                        
                    </Col>
                    {open && <AddAMRdevice value={open} setOpenModal={setOpen} deviceTypes={deviceTypes} data={orgData} action={orgData ? 'Edit' : 'Add'} loadAmrData={() => loadData()}/>}
                    {deleteOpen && <DeleteAmr value={deleteOpen} setOpenModal={setDeleteopen} dataId={dataId} setDelete={setIsDeleted}/>}
                </Row>
            </Container>
        </Fragment>
    );

};

export default AmrList;