import React, { Fragment, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { H3 } from "../../AbstractElements";
import { X } from "react-feather";
import {
    Container,
    Row,
    Card,
    Col,
    Form,
    FormGroup,
    Label,
    CardBody,
    Button
} from "reactstrap";
import { countries } from "../../Common/Data/CountryList/Defaultdata";
import { useForm, useWatch } from "react-hook-form";
import fetchWrapper from "../../Common/api/fetchWrapper";
import { API_URL } from "../../Common/Config/Config";
import { toast } from "react-toastify";

let logoFile;

const AddCategory = ({ value, setOpenModal, data, action, setAdd }) => {
    const [open, setOpen] = useState(value);
    const { register, formState: { errors }, handleSubmit, formState, getValues, getFieldState  } = useForm({ defaultValues: data });
    const [storeType, setType] = useState();
    const [desc, setDesc] = useState();
    
    const onClose = () => {
        setOpen(false);
        setOpenModal(false)
    }

    const onSubmit = async (item) => {
        let url;
        url = action === 'Add' ? `/productcategory` : `/productcategory/${item?.id}`;
        item.logo = logoFile;
        if(action === 'Edit') {
            delete item.name;
        }
        let formData = new FormData();
        Object.keys(item).forEach(key => {
            formData.append(key, item[key]);
        });
        
        const options = { 
            method: item?.id ? 'PUT' : 'POST',
            body: formData,
            headers: {
                "Authorization": `Bearer ${sessionStorage.getItem('accessToken')}`
            }
        }
        await fetchWrapper(`${API_URL}${url}`, options)
        .then((res) => {
            if(res.status === 200) {
                setAdd(true)
                onClose()
                toast.success('Product Category Added Succussfully')
            }
        }, (err) => {
            console.log(err)
            onClose()
            toast.error('Something went wrong!')
        })
    }

    const uploadFile = (event) => {
        logoFile = event.target.files[0];
    };

    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size='lg'>
                <ModalHeader style={{ display: 'flex' }}>
                    <H3 attrH3={{ className: 'modal-title-color' }}>{data ? 'Update Category' : 'Add Category'} <X className="x-button" onClick={onClose} /></H3>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg='12'>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Col lg='6'>
                                        <FormGroup>
                                            <Label for="name">Name <span className='required'>*</span></Label>
                                            <input
                                                className="form-control"
                                                name="name"
                                                type="text"
                                                disabled={action === 'Edit'}
                                                defaultValue={data?.name}
                                                {...register('name', { required: true })}
                                            />
                                            <span className="text-danger">{errors.name && 'Name is Required'}</span>
                                        </FormGroup>
                                    </Col>
                                    <Col lg='6'>
                                        <FormGroup>
                                            <Label for="storeType">Store Type <span className='required'>*</span></Label>
                                            <select
                                                className="form-select"
                                                name="type"
                                                value={storeType}
                                                {...register('type', { required: true })}
                                                onChange={(e) => setType(e.target.value)}
                                            >
                                                <option value={''}>- Select Store Type -</option>
                                                <option value="APPAREL">Apparel</option>
                                                <option value="JEWELLERY">Jewellery</option>
                                            </select>
                                            <span className="text-danger">{errors?.type && 'Store type is required'}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg='12'>
                                        <textarea
                                            name="description"
                                            className="form-control"
                                            placeholder="Write description..."
                                            rows={3}
                                            cols={98}
                                            {...register('description')}
                                            onChange={(e) => setDesc(e.target.value)}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                <Col lg='12'>
                                    <FormGroup >
                                                <Label for="exampleSelect">Category Logo </Label>
                                                <div>
                                                    <input
                                                        className="file-upload"
                                                        id="exampleEmail"
                                                        name="website"
                                                        onChange={uploadFile}
                                                        placeholder=""
                                                        accept=".png,.jpeg, .jpg"
                                                        type="file"
                                                        size="1048576"
                                                    />
                                                    <p className='no-wrap mtop-1'>[Note:Supported formats png, jpeg, jpg and Supported size less then 1 MB ]</p>
                                                </div>
                                            </FormGroup>
                                    </Col>
                                </Row>
                                <div className="d-flex mtop-1" >
                                    <Button color="primary" >{action === 'Edit' ? 'Update' : 'Add'}</Button>
                                    <Button color="secondary" className="ml-1" onClick={onClose}> Cancel</Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default AddCategory;